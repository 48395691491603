import React from 'react';
import { Link } from 'react-router-dom';
import styles from './notFound.module.css'

const NotFoundPage = () => {
  return <div className="row no-gutters">
      <div className={`col-lg-6 d-lg-flex ${styles.first_div}`}>
          <div className={`${styles.error_code}`}>
            <p className={`${styles.error_code_p}`}>Error</p>
            <h1 className={`${styles.error_code_heading}`}>404</h1>
          </div>
      </div>
      <div className={`col-lg-6`}>
          <div className={`${styles.second_div_content}`}>
            <h3>Don't be scared</h3>
            <p className={styles.second_div_content_title}>This page is currently missing or doesn’t exist.</p>
            <p className={styles.second_div_content_subtitle}>Try going back to your overview </p>
            <Link to="/overview" className={`btn ${styles.overview_btn}`}>Go back to overview</Link>
          </div>
      </div>
  </div>;
};

export default NotFoundPage;
