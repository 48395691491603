import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js'
import {connect} from 'react-redux'
import styles from "../../pages/Products/products.module.css";
import React,{useState, useEffect} from 'react';
import arrow from "../../assets/icons/arrow_drop_down.svg";
import arrow_up from "../../assets/icons/arrow_drop_up.svg";
import { getProductRevenueChart } from "../../store/actions/products";
import Moment from "moment";



Chart.register(
  ...registerables,
);


const BarChart = ({type, id, fetchRevenueChart, graphData}) => {


  const [isRevenueOpen, setIsRevenueOpen] = useState(false)

  const [title, setTitle] = useState("This Week")

  const [chartDate, setChartDate] = useState('week')


  useEffect(()=>{
    let duration = 'week';
    fetchRevenueChart(type, id, duration)
}, [fetchRevenueChart, type, id])

  const [revenueSort] = useState([
    {
      id: 1,
      name: "This Week",
      tag: "week",
    },
    {
      id: 2,
      name: "This Month",
      tag: "month",
    },
    {
      id: 3,
      name: "This Year",
      tag: "year",
    }
  ]);

  const toggleRevenueList = () => {
      setIsRevenueOpen(!isRevenueOpen)
  }

  const toggleRevenue = (val) => {
      const {tag, name} = val
      console.log(tag)
      setTitle(name)
      setChartDate(tag)
      setIsRevenueOpen(false)
      fetchRevenueChart(type, id, tag)
    }

    const dateFormat = (date) =>{
      var dayShow;
      switch(chartDate){
          case "week":
              // week
              dayShow = 
              Moment(date).format('dddd')
              break;
          case "month":
              // month
              dayShow = 
              Moment(date).format('MMMM Do')
              break;
          case "year":
              // year
              dayShow = 
              Moment(date).format('MMMM Do, YYYY')
              break;
          default:
              dayShow = ""
      }
       return dayShow;
    }


    const data = {
        labels: graphData.map(({delivery_date})=> dateFormat(delivery_date)),
        datasets: [{
            label: 'Revenue',
            data:  graphData.map(({total_cost_price})=>(total_cost_price)),
            backgroundColor: [
              "#2B8C34"
            ],
            borderColor: [
            '#E1E3E1'
            ],
            borderWidth: 0,
            barThickness: 60,
            borderRadius: 5
        }]
    };
    
    const options = {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function(label, index, labels) {
                  return '₦' + label/1000+'k';
              }
            }
          }
        },
        maintainAspectRatio: false,
    }

    return (  
        <>
           <div className={styles.details_revenue_chart_div}>
            <div className={styles.details_revenue_chart_header}>
                <div>
                    <h6>Revenue</h6>
                </div>
                <div className={styles.revenue_dropdown_div}>
                    <div
                        className={styles.revenue_dropdown}
                        onClick={() => toggleRevenueList()}
                        >
                        <h6>{title}</h6>
                        {isRevenueOpen ? (
                            <img src={arrow_up} alt="arrow" />
                        ) : (
                            <img src={arrow} alt="arrow" />
                        )}
                    </div>  

                      {/* dropdown section */}
                    <ul
                    className={
                        isRevenueOpen
                        ? `${styles.revenue_dropdown_section} ${styles.revenue_dropdown_section_active}`
                        : styles.revenue_dropdown_section
                    }
                    >
                    {revenueSort.map((val) => (
                        <li key={val.id} onClick={() => toggleRevenue(val)}>
                            <p className={styles.revenue_dropdown_list}>{val.name}</p>
                        </li>
                    ))}
                    </ul>
                </div>
            </div>
              
              <div className="chart-container" style={{position: 'relative', width: '100%', height:'420px'}}>
                  <Bar data={data} options={options} />
              </div>
                
          </div>
       
        </>
    );
}

const mapStateToProps = (state) =>{
  return{
    graphData: state.product.revenueChart,
  }
}

const mapDispatchToProps =(dispatch) =>{
  return{
    fetchRevenueChart: (type, id, duration) => dispatch(getProductRevenueChart(type, id, duration)),
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(BarChart);