import { combineReducers } from "redux";
import authReducer from "./auth";
import orderReducer from "./order";
import overviewReducer from "./overview";
import productReducer from "./products";
import transactionReducer from "./transactions";


const appReducer = combineReducers({
  auth: authReducer,
  product: productReducer,
  overview: overviewReducer,
  order: orderReducer,
  transaction: transactionReducer
});

const rootReducer = (state, action) => {
  if (action.type === "logout") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
  