import React, {useState, useRef, useEffect} from 'react';
import styles from "./register.module.css";
import { Form, Formik } from "formik";
import { registerValidator } from "../../validationSchema/validator";
import {Link, useNavigate } from 'react-router-dom'
import Sidebar from '../../components/OnboardingSidebar/sidebar';
import { clearSignUpError, OnBoardValues, } from '../../store/actions/auth';
import visible_off from '../../assets/icons/visibility_off.svg'
import visible from '../../assets/icons/visibility.svg'
import {connect} from 'react-redux'
import Logo from "../../assets/logo/GatherLogo.svg";
import Select from 'react-select'
import cogoToast from 'cogo-toast';



const RegisterPage = ({handleFirstProcess, onboardVal, clearErrorStatus}) => {

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  
  const ref = useRef();

  const [selectedType, setSelectedType] = useState(["crop"])

  const [types] = useState(
    [
      {
        id: 1,
        name: 'Crop Products',
        tag: 'crop'
      },
      {
        id: 2,
        name: 'Livestock Products',
        tag: 'livestock'
      },
      {
        id: 3,
        name: 'Mechanization Products',
        tag: 'mechanization'
      },
      {
        id: 4,
        name: 'Advisory Products',
        tag: 'advisory'
      },
      {
        id: 5,
        name: 'Insurance Products',
        tag: 'insurance'
      }
    ]
  )

  const navigate = useNavigate();


  useEffect(()=>{
    clearErrorStatus()
  },[clearErrorStatus])



  const handleTypes = (val) =>{
    let typeArray = [];
    val.map(o => 
       typeArray.push(o.tag)
   );
   console.log(typeArray)
   setSelectedType(typeArray)
  }


   // register func
   const handleSubmit = async (values) => {
    if(selectedType.length > 0){
      const creds = {
        ...values,
        vendorTypes: selectedType
      }
      console.log(creds)
      await handleFirstProcess(creds)
      navigate('/complete-registration')
    }
    else{
      cogoToast.warn("You need to add at least a product you supply!")
    }
 }; 


  return ( 
    <>
    <div className='row no-gutters'>
      <Sidebar/>
      <div className='col-lg-7'>
        <div className={`${styles.login_second_half_div}`}>

          <div className="logo mt-4 d-md-none d-sm-block">
               <img src={Logo} alt="logo" height="50" />
            </div>

          <div  className={`mt-4 mt-md-0 ${styles.register_step_div}`}>
             <div>
              <h4 className={styles.login_second_half_title}>Create an Account</h4>
            </div>
            <div className={styles.register_steppers}>
                <p>
                  <span>1</span>/2
                </p>
            </div>
          </div>
           
            {/* login section */}
             {/* form submission */}
             <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={registerValidator}
              enableReinitialize={true}
              initialValues={{ 
                companyName: onboardVal.hasOwnProperty('companyName') ? onboardVal.companyName : "", 
                email: onboardVal.hasOwnProperty('email') ? onboardVal.email : "",
                firstname: onboardVal.hasOwnProperty('firstname') ? onboardVal.firstname : "", 
                lastname:  onboardVal.hasOwnProperty('lastname') ? onboardVal.lastname : "",
                password: onboardVal.hasOwnProperty('password') ? onboardVal.password : "",
                phoneNumber: onboardVal.hasOwnProperty('phoneNumber') ? onboardVal.phoneNumber : ""
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form ref={ref} onSubmit={handleSubmit}>
                  {/* form section */}
                  <div className={`mt-4 ${styles.form_container}`}>

                      {/* first name */}
                      <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="firstname">First Name</label>
                      <input
                        className={`${styles.input_style}`}
                        type="text"
                        placeholder="Firstname"
                        id="firstname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstname}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.firstname && errors.firstname}
                      </small>
                    </div>

                    <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        className={`${styles.input_style}`}
                        type="text"
                        placeholder="Lastname"
                        id="lastname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastname}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.lastname && errors.lastname}
                      </small>
                    </div>

                      {/* company's name */}
                    <div className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="companyName">Company's Name</label>
                        <input
                           className={`${styles.input_style}`}
                           type="text"
                           placeholder="Enter your Company Name"
                           id="companyName"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           value={values.companyName}
                        />
                        <small style={{ color: "#dc3545" }}>
                        {touched.companyName && errors.companyName}
                        </small>
                    </div>

                    {/* phone number */}
                    <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <div>
                        <input
                          className={`${styles.input_style}`}
                          type="text"
                          placeholder="Enter your Phone Number"
                          id="phoneNumber"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.phoneNumber}
                        />
                      </div>
                      
                      <small style={{ color: "#dc3545" }}>
                        {touched.phoneNumber && errors.phoneNumber}
                      </small>
                    </div>


                    {/* email address */}
                    <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="email">Company's Email Address (Optional)</label>
                      <input
                        className={`${styles.input_style}`}
                        type="email"
                        placeholder="Enter your Company's Email Address"
                        id="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.email && errors.email}
                      </small>
                    </div>

                  

                    <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="name">What products do you supply?</label>
                      <Select 
                        isMulti
                        onChange={handleTypes}
                        classNamePrefix="select_control"
                        className="select_style"
                        defaultValue={types[0]}
                        options={types}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                       />
                    </div>

                      {/* password */}
                      <div className={`mb-3 ${styles.input_container}`} >
                      <label htmlFor="password">Password</label>
                      <div style={{position: 'relative'}}>
                      <input
                        className={`${styles.input_style}`}
                        placeholder="Password"
                        id="password"
                        type={passwordShown ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {passwordShown
                              ? 
                              <img  
                              onClick={togglePasswordVisiblity}
                               className={styles.toggle_style} src={visible} alt='toggle off' />
                              :
                              <img  
                              onClick={togglePasswordVisiblity}
                               className={styles.toggle_style} src={visible_off} alt='toggle on' />
                          }
                      </div>
                    
                      <small style={{ color: "#dc3545" }}>
                        {touched.password && errors.password}
                      </small>
                    </div>

                                  

                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn ${styles.btn_login_submit}`}
                      >
                       Continue
                      </button>
                    </div>

                    <div className="mt-2">
                      <p
                      className={`text-center ${styles.login_link}`}
                      >
                        Already have an account?{" "}
                        <Link to="/">Log In</Link>
                      </p>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

        </div>
      </div>

    </div>
    </>
   );
}

const mapStateToProps = (state) => {
  return {
    onboardVal: state.auth.onboardValues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleFirstProcess: (creds) => dispatch(OnBoardValues(creds)),
    clearErrorStatus: () => dispatch(clearSignUpError()),
  };
};

 
export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);