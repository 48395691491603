import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js'
import styles from "../../pages/Overview/overview.module.css";
import React,{useState, useEffect} from 'react';
import {connect} from 'react-redux'
import arrow from "../../assets/icons/arrow_drop_down.svg";
import arrow_up from "../../assets/icons/arrow_drop_up.svg";
import { getOrderCategory } from "../../store/actions/overview";



Chart.register(
  ...registerables,
);



const DoughnutChart = ({orderData, getOrderType}) => {

  const [isCategoryOpen, setIsCategoryOpen] = useState(false)

  const [title, setTitle] = useState("This Week")

  const [categorySort] = useState([
    {
      id: 1,
      name: "This Week",
      tag: "week",
    },
    {
      id: 2,
      name: "This Month",
      tag: "month",
    },
    {
      id: 3,
      name: "This Year",
      tag: "year",
    }
  ]);

  const toggleCatList = () => {
    setIsCategoryOpen(!isCategoryOpen)
  }
  
  const toggleCategory = (val) => {
    const {tag, name} = val
    console.log(tag)
    setTitle(name)
    getOrderType(tag)
    setIsCategoryOpen(false)
  }

  const handleCapitalize = (str) =>{
    let value = str.toLowerCase()
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  const handleTypeColor = (val) =>{
    let res;
    switch(val){
      case 'crop':
        res = styles.order_category_chart_color_grey
        break;
      case 'livestock':
        res = styles.order_category_chart_color
        break;
      case 'advisory':
        res = styles.order_category_chart_color_yellow
        break;
      case 'insurance':
        res = styles.order_category_chart_color_green
        break;
      case 'mechanization':
        res = styles.order_category_chart_color_black
        break;
      default:
        break;
    }
    return res;
  }

  //  Get order type chart data
  useEffect(() =>{
    const values = 'week'
    getOrderType(values)
  },[getOrderType])

  const data = {
    labels: orderData.map(({type})=> handleCapitalize(type)),
    datasets: [
      {
        // data: [1, 1, 1, 1],
        data: orderData.map(({amount})=>(amount)),
        backgroundColor: [
          "#C4C4C4",
          "#0B6C52",
          "#FFC508",
          "#000F01",
          "#00BA88"
        ],
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        responsive: true,
        animation: {
          animateScale: true,
          color: true,
        },
      },
    },
  };

  return (
    <>
   <div className="col-lg-7">
              <div className={styles.order_category_card}>
                <div className={styles.order_category_heading}>
                  <div>
                    <p className={styles.order_category_title}>
                      Order Category
                    </p>
                  </div>
                  <div className={styles.order_category_dropdown_div}>
                    <div
                      className={styles.order_category_dropdown}
                      onClick={() => toggleCatList()}
                    >
                      <p>{title}</p>
                      {isCategoryOpen ? (
                        <img src={arrow_up} alt="arrow" />
                      ) : (
                        <img src={arrow} alt="arrow" />
                      )}
                    </div>

                    {/* dropdown section */}
                    <ul
                      className={
                        isCategoryOpen
                          ? `${styles.dropdown_section} ${styles.dropdown_section_active}`
                          : styles.dropdown_section
                      }
                    >
                      {categorySort.map((val) => (
                        <li
                          key={val.id}
                          onClick={() => toggleCategory(val)}
                        >
                          <p className={styles.dropdown_list}>{val.name}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* category chart layout */}
          <div className="row mt-md-4 mt-1 mb-4">
          <div className="col-lg-6">

              <Doughnut data={data} options={options} />

          </div>
          <div className="col-lg-6 mt-md-4 mt-4">
            
            <div className="row">

              {
                  orderData.length > 0 ? orderData.map((item, index)=>(
                      <div key={index} className="col-lg-6 mb-4">
                      <div>
                        <div
                          className={handleTypeColor(item.type)}
                        ></div>
                        <div className={styles.order_category_chart_name}>
                          {handleCapitalize(item.type)} Product(s)
                        </div>
                        <div className={styles.order_category_chart_val}>
                          {item.amount} {item.amount > 1 ?  "Orders" : "Order" }  
                        </div>
                      </div>
                  
                        </div>
                      ))
                      :
                  <div></div>
                    
                  }
            
            </div>
          </div>
        </div>
        </div>
       </div>     
    </>
  );
}


const mapStateToProps = (state) =>{
  return{
    orderData: state.overview.orderTypesChart,
  }
}

const mapDispatchToProps =(dispatch) =>{
  return{
    getOrderType: (value) => dispatch(getOrderCategory(value)),
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(DoughnutChart);