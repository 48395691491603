import React, {useEffect, useState} from 'react';
import Sidebar from '../../components/OnboardingSidebar/sidebar';
import styles from './forgotPassword.module.css';
import {useNavigate, Link } from 'react-router-dom'
import { Form, Formik } from "formik";
import { emailVerifyValidator } from "../../validationSchema/validator";
import cogoToast from 'cogo-toast';
import chevron_left from '../../assets/icons/chevron_left.svg'
import { connect } from 'react-redux';
import { clearForgotSuccess, verifyForgotCode } from '../../store/actions/auth';

const ForgotEmailCodePage = ({verifyCode, clearForgot,phone, validcode}) => {

    const navigate = useNavigate();

    const [counter, setCounter] = useState(40);

    const [counterCheck, setCounterCheck] = useState(false);

    useEffect(()=>{
      clearForgot()
    },[clearForgot])

    useEffect(()=>{
      if(counter === 0){
        setCounterCheck(true)
      }
    }, [counter])

    useEffect(()=>{
      const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    },[counter])


    const maskEmail = (phone) => {
      const [name] = phone.split(" ");
      const { length: len } = phone;
      const maskedName = phone.substring(0,9) + "****" + name[len - 1];
      return maskedName;
  };

  
     // Submit func
     const handleSubmit = async (values) => {
      console.log(values)
      const creds ={
        otp: values.code,
        phone_number: phone
      }
      await verifyCode(creds)

   }; 

   useEffect(()=>{
    if(validcode){
      setTimeout(()=>{
        navigate("/resetpassword");
      }, 2000)
    }
   },[navigate, validcode])

   const handleResend = () =>{
    cogoToast.success("Code sent successfully!", {
      position: "bottom-right",
    }); 
   }

    return ( 
        <>
        <div className='row no-gutters'>
            <Sidebar />
            <div className='col-lg-7'>
        <div className={styles.login_second_half_div}>


          <Link to="/forgotpassword" style={{textDecoration: 'none'}}>
              <div className={styles.back_div}>
                  <div className={styles.back_circle}>
                      <img src={chevron_left} alt="checvron" />
                  </div>
                  <div>
                      <span className={styles.back_text}>Back</span>
                </div>
              </div>
          </Link>

          <div className={`mt-4 ${styles.register_step_div}`}>
             <div>
              <h4 className={styles.login_second_half_title}>Hey, we sent you an sms</h4>
            </div>
            <div className={styles.register_steppers}>
                <p>
                  <span>2</span>/3
                </p>
            </div>
          </div>

          <p className={styles.email_verify_title}>Please fill in the 4 digit code we sent to {maskEmail(phone)}</p>
           
            {/* login section */}
             {/* form submission */}
             <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={emailVerifyValidator}
              initialValues={{ code: "" }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* form section */}
                  <div className={`mt-4 ${styles.form_container}`}>
                      {/* Full Name */}
                    <div className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="full_name">Enter the code</label>
                        <input
                           className={`${styles.input_style}`}
                           type="text"
                           placeholder="1234"
                           id="code"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           value={values.code}
                        />
                        <small style={{ color: "#dc3545" }}>
                        {touched.code && errors.code}
                        </small>
                    </div>

                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn ${styles.btn_login_submit}`}
                      >
                       Continue
                      </button>
                    </div>

                    <div className="mt-2">
                      <p
                       className={counterCheck ? `text-center ${styles.resendcode_link_active}` : `text-center ${styles.resendcode_link}`}
                        onClick={handleResend}
                      >
                        Resend Code(0:{counter})
                      </p>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

        </div>
      </div>
        </div>
        </>
     );
}
 


const mapStateToProps = (state) =>{
  return{
    phone: state.auth.phone,
    validcode: state.auth.codeValid
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{ 
      verifyCode: (creds) => dispatch(verifyForgotCode(creds)),
      clearForgot: () => dispatch(clearForgotSuccess()),
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(ForgotEmailCodePage);