import {  GetApi } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


// Get all transactions
export const getTransactions = () => {
    return async (dispatch, getState) => {
      dispatch({ type: "transactionLoader" });
      let token = getState().auth.token
      try {
        const res = await GetApi(`transactions?limit=5`, getToken() || token);
        if (res.status === 200) {
          let result = res.data.data.rows
          let count = res.data.data.count

          dispatch({ type: "Transactions", data: {
            resp:result,
            count: count
            }});
          dispatch({ type: "transactionLoaderStop" });
        }
        if (res.status === 400) {
          dispatch({ type: "Transaction_Error", err: res.data });
          dispatch({ type: "transactionLoaderStop" });
        }
        if(res.status === 500){
          dispatch({ type: "Transaction_Error", err: res.data });
          dispatch({ type: "transactionLoaderStop" });
        }
      } catch (err) {
        console.log(err);
      }
    };
};

// Get all cunts
export const getCount = (tag) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    try {
      const res = await GetApi(`transactions/overview?period=${tag}`, getToken() || token);
      if (res.status === 200) {
    
        dispatch({ type: "TransactionsCount", data: res.data.data });
      }
      if (res.status === 400) {
        dispatch({ type: "TransactionsCount_Error", err: res.data });
      }
      if(res.status === 500){
        dispatch({ type: "TransactionsCount_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};



// Get all transactions by type
export const getTransactionsByType = (type,page) => {
  return async (dispatch, getState) => {
    dispatch({ type: "transactionLoader" });
    let token = getState().auth.token
    try {
        if(type === 'all'){
          const res = await GetApi(`transactions?limit=10&page=${page}`, getToken() || token);
          if (res.status === 200) {
            let result = res.data.data.rows
            let count = res.data.data.count

            dispatch({ type: "Transactions", data: {
              resp:result,
              count: count
              }});
             dispatch({ type: "STATEMENT", data: res.data.data });
            dispatch({ type: "transactionLoaderStop" });
          }
          if (res.status === 400) {
            dispatch({ type: "Transaction_Error", err: res.data });
            dispatch({ type: "transactionLoaderStop" });
          }
          if(res.status === 500){
            dispatch({ type: "Transaction_Error", err: res.data });
            dispatch({ type: "transactionLoaderStop" });
          }

        }else{
        const res = await GetApi(`transactions?limit=10&type=${type}&page=${page}`, getToken() || token);
        if (res.status === 200) {
          let result = res.data.data.rows
          let count = res.data.data.count

          dispatch({ type: "Transactions", data: {
            resp:result,
            count: count
            }});
           dispatch({ type: "STATEMENT", data: res.data.data });
          dispatch({ type: "transactionLoaderStop" });
        }
        if (res.status === 400) {
          dispatch({ type: "Transaction_Error", err: res.data });
          dispatch({ type: "transactionLoaderStop" });
        }
        if(res.status === 500){
          dispatch({ type: "Transaction_Error", err: res.data });
          dispatch({ type: "transactionLoaderStop" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// search transactions based on type
export const searchTransactionByAmount = (type, val) => {
  return async (dispatch, getState) => {
    dispatch({ type: "transactionLoader" });
    let token = getState().auth.token
    try {
      if(type === 'all'){
        const res = await GetApi(`transactions?search=${val}`, getToken() || token);
        if (res.status === 200) {
          let result = res.data.data.rows
          let count = res.data.data.count
        
  
          dispatch({ type: "Transactions", data: {
            resp: result,
            count: count
            } });
          dispatch({ type: "STATEMENT", data: res.data.data });
          dispatch({ type: "transactionLoaderStop" });
        }
        if (res.status === 400) {
          dispatch({ type: "Transaction_Error", err: res.data });
          dispatch({ type: "transactionLoaderStop" });
        }
      }
      else{
        const res = await GetApi(`transactions?type=${type}&search=${val}`, getToken() || token);
        if (res.status === 200) {
          let result = res.data.data.rows
          let count = res.data.data.count
        
  
          dispatch({ type: "Transactions", data: {
            resp: result,
            count: count
            } });
          dispatch({ type: "STATEMENT", data: res.data.data });
          dispatch({ type: "transactionLoaderStop" });
        }
        if (res.status === 400) {
          dispatch({ type: "Transaction_Error", err: res.data });
          dispatch({ type: "transactionLoaderStop" });
        }
      }
    
    } catch (err) {
      console.log(err);
    }
  };
};

















