import React,{useEffect} from 'react';
import styles from "./email.module.css";
import {Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/logo/GatherLogo.svg';
import mail from '../../assets/icons/mail.svg';
import mailError from '../../assets/icons/mail_error.svg'
import { verifyEmail} from '../../store/actions/auth';
import {connect} from 'react-redux'


const EmailVerificationPage = ({verified, verify_status}) => {
  const search = useLocation().search;
    // Verify reset code sent to email if valid!
  useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    const otp = new URLSearchParams(search).get("otp")
    const user = {user_id: parseInt(id), otp}
    console.log(user)
    verified(user)
  }, [search, verified]);

  return ( 
        <>
          <div className={`${styles.verify_email_container}`}>
              <div className={`${styles.header}`}>
                <img src={Logo} alt="Gather Store"/>
              </div>
              <div className={`${styles.verify_modal_container}`}>
                  <div className={`${styles.modal}`}>
                    <div style={{display:"flex", justifyContent:"center"}}>
                      <div className={`${styles.icon_container} ${verify_status ? styles.success : styles.error}`}>
                        <img src={verify_status ? mail : mailError} alt="icon"/>
                      </div>
                    </div>
                    <div className={`${styles.modal_content}`}>
                        <div>
                          <h6 className={`${styles.modal_header}`}>Email Verification</h6>
                          <p className={`${styles.modal_subtitle}`}>{verify_status ? "Your Email address has been verified" : "Verification Failed. This link might been broken."}</p>
                        </div>
                        <div className={`${styles.modal_content_sub_div}`}>
                          {
                            verify_status ? <p><Link to="/" style={{color:"#2B8C34", fontWeight: "500", textDecoration: 'none'}}>Log in {" "}</Link>to continue using Gather store</p> : <span></span>
                          }
                        </div>
                    </div>
                  </div>
              </div>
          </div>
        </>
     );
}

const mapStateToProps = (state) =>{
  return{
      verify_status: state.auth.verified
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{ 
      verified: (creds) => dispatch(verifyEmail(creds)),
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationPage);