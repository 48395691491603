import cogoToast from "cogo-toast";
import {  GetApi, PutApi } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


// Get all orders based on type
export const getOrdersByType = (val, page) => {
    return async (dispatch, getState) => {
      dispatch({ type: "orderStartLoad" });
      let token = getState().auth.token
      try {
        const res = await GetApi(`orders?type=${val}&limit=10&page=${page}`, getToken() || token);
        if (res.status === 200) {
          let result = res.data.data.rows
          let count = res.data.data.count
          let resp;
          // check for type and map arrays for it
          switch(val){
            case "crop":
              resp = result.map(val => (
                { 
                  ...val,
                  costPrice: val.input_product.cost_price ? val.input_product.cost_price: 0,
                }));
              break;
            case "livestock":
              resp = result.map(val => (
                { 
                  ...val,
                  costPrice: val.livestock_input.cost_price ? val.livestock_input.cost_price : 0,
                }));
              break;
            case "advisory":
              resp = result.map(val => (
                { 
                  ...val,
                  costPrice: val.advisory_product.cost_price ? val.advisory_product.cost_price : 0,
                }));
                break;
            case "mechanization":
              resp = result.map(val => (
                { 
                  ...val,
                  costPrice: val.mechanization_product.cost_price ? val.mechanization_product.cost_price: 0,
                }));
                break;
            case "insurance":
              resp = result.map(val => (
                { 
                  ...val,
                  costPrice: val.insurance_product.cost_price ? val.insurance_product.cost_price : 0,
                }));
                break;
            default:
              break;
          }
  
          dispatch({ type: "ORDERS", data: {
            resp:resp,
            count: count
          } });
          dispatch({ type: "ORDERSREPORT", data: res.data.data });
          dispatch({ type: "orderStopLoad" });
        }
        if (res.status === 400) {
          dispatch({ type: "Order_Error", err: res.data });
          dispatch({ type: "orderStopLoad" });
        }
        if(res.status === 500){
          dispatch({ type: "Order_Error", err: res.data });
          dispatch({ type: "orderStopLoad" });
        }
      } catch (err) {
        console.log(err);
      }
    };
};

// search orders based on status
export const searchOrders = (type, val) => {
  return async (dispatch, getState) => {
    dispatch({ type: "orderStartLoad" });
    let token = getState().auth.token
    try {
      const res = await GetApi(`orders?type=${type}&search=${val}`, getToken() || token);
      if (res.status === 200) {
        let result = res.data.data.rows
        let count = res.data.data.count
        let resp;
        // check for type and map arrays for it
        switch(type){
          case "crop":
            resp = result.map(val => (
              { 
                ...val,
                costPrice: val.input_product.cost_price,
              }));
            break;
          case "livestock":
            resp = result.map(val => (
              { 
                ...val,
                costPrice: val.livestock_input.cost_price,
              }));
            break;
          case "advisory":
            resp = result.map(val => (
              { 
                ...val,
                costPrice: val.advisory_product.cost_price,
              }));
              break;
          case "mechanization":
            resp = result.map(val => (
              { 
                ...val,
                costPrice: val.mechanization_product.cost_price,
              }));
              break;
          case "insurance":
            resp = result.map(val => (
              { 
                ...val,
                costPrice: val.insurance_product.cost_price,
              }));
              break;
          default:
            break;
        }

        dispatch({ type: "ORDERS", data: {
          resp:resp,
          count: count
        } });
        dispatch({ type: "ORDERSREPORT", data: res.data.data });
        dispatch({ type: "orderStopLoad" });
      }
      if (res.status === 400) {
        dispatch({ type: "Order_Error", err: res.data });
        dispatch({ type: "orderStopLoad" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// Get an order based on tyoe
export const getOrderById = (type, id) => {
  return async (dispatch, getState) => {
    dispatch({ type: "orderLoader" });
    let token = getState().auth.token
    try {
      const res = await GetApi(`orders/${type}/${id}`, getToken() || token);
      if (res.status === 200) {
        let result = res.data.data
        let resp;
        // check for type and map arrays for it
        switch(type){
          case "crop":
            resp = result.map(val => (
              { 
                ...val,
                productName: val.input_product.name,
                productImages: val.input_product.images,
                costPrice: val.input_product.cost_price,
                productSize: val.input_product.product_size,
                measurement: val.input_product.measurement
              }));
            break;
          case "livestock":
            resp = result.map(val => (
              { 
                ...val,
                productName: val.livestock_input.name,
                productImages: val.livestock_input,
                costPrice: val.livestock_input.cost_price,
                productSize: val.livestock_input.product_size,
                measurement: val.livestock_input.measurement
              }));
            break;
          case "advisory":
            resp = result.map(val => (
              { 
                ...val,
                productName: val.advisory_product.name,
                productImages: val.advisory_product,
                costPrice: val.advisory_product.cost_price,
                productSize: val.advisory_product.product_size,
                measurement: val.advisory_product.measurement
              }));
              break;
          case "mechanization":
            resp = result.map(val => (
              { 
                ...val,
                productName: val.mechanization_product.name,
                productImages: val.mechanization_product.images,
                costPrice: val.mechanization_product.cost_price,
                productSize: val.mechanization_product.product_size,
                measurement: val.mechanization_product.measurement
              }));
              break;
          case "insurance":
            resp = result.map(val => (
              { 
                ...val,
                productName: val.insurance_product.name,
                productImages: val.insurance_product.images,
                costPrice: val.insurance_product.cost_price,
                productSize: val.insurance_product.product_size,
                measurement: val.insurance_product.measurement
              }));
              break;
          default:
            break;
        }

        dispatch({ type: "ORDER", data: resp });
        dispatch({ type: "orderLoaderStop" });
      }
      if (res.status === 400) {
        dispatch({ type: "Order_Error", err: res.data });
        dispatch({ type: "orderLoaderStop" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

//  update order status functionality based on category
export const UpdateOrderStatus = (type,id,val) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    try {
      const res = await PutApi(`orders/${type}/${id}`, { ...val }, getToken() || token, "application/json");
      if (res.status === 200) {
        cogoToast.success('Order status updated successfully!')

        // make a call to get the order details again
        dispatch({ type: "orderLoader" });
        const res = await GetApi(`orders/${type}/${id}`, getToken() || token);
        if (res.status === 200) {
          let result = res.data.data
          let resp;
          // check for type and map arrays for it
          switch(type){
            case "crop":
              resp = result.map(val => (
                { 
                  ...val,
                  productName: val.input_product.name,
                  productImages: val.input_product.images,
                  costPrice: val.input_product.cost_price,
                  productSize: val.input_product.product_size,
                  measurement: val.input_product.measurement
                }));
              break;
            case "livestock":
              resp = result.map(val => (
                { 
                  ...val,
                  productName: val.livestock_input.name,
                  productImages: val.livestock_input.images,
                  costPrice: val.livestock_input.cost_price,
                  productSize: val.livestock_input.product_size,
                  measurement: val.livestock_input.measurement
                }));
              break;
            case "advisory":
              resp = result.map(val => (
                { 
                  ...val,
                  productName: val.advisory_product.name,
                  productImages: val.advisory_product.images,
                  costPrice: val.advisory_product.cost_price,
                  productSize: val.advisory_product.product_size,
                  measurement: val.advisory_product.measurement
                }));
                break;
            case "mechanization":
              resp = result.map(val => (
                { 
                  ...val,
                  productName: val.mechanization_product.name,
                  productImages: val.mechanization_product.images,
                  costPrice: val.mechanization_product.cost_price,
                  productSize: val.mechanization_product.product_size,
                  measurement: val.mechanization_product.measurement
                }));
                break;
            case "insurance":
              resp = result.map(val => (
                { 
                  ...val,
                  productName: val.insurance_product.name,
                  productImages: val.insurance_product.images,
                  costPrice: val.insurance_product.cost_price,
                  productSize: val.insurance_product.product_size,
                  measurement: val.insurance_product.measurement
                }));
                break;
            default:
              break;
          }

          dispatch({ type: "ORDER", data: resp });
          dispatch({ type: "orderLoaderStop" });
        }
        if (res.status === 400) {
          dispatch({ type: "Order_Error", err: res.data });
          dispatch({ type: "orderLoaderStop" });
        }

      }
      if(res.status === 400){
        cogoToast.error('Error while updating status!')
      }
      if(res.status === 500){
        cogoToast.error("Error while updating status")
      }
    } catch (err) {
      console.log(err)
    }
  };
};










