import React, {useState, useEffect} from 'react';
import Sidebar from '../../components/OnboardingSidebar/sidebar';
import styles from './forgotPassword.module.css';
import { resetPasswordValidator } from "../../validationSchema/validator";
import { useNavigate } from 'react-router-dom';
import visible_off from '../../assets/icons/visibility_off.svg'
import visible from '../../assets/icons/visibility.svg'
import { Form, Formik } from "formik";
import {connect} from 'react-redux'
import { ResetPassword } from '../../store/actions/auth';

const ResetPasswordPage = ({reset, resetsuccess, phone, otp}) => {

  
  const navigate = useNavigate();
    
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };

    const toggleConfirmPasswordVisiblity = () => {
     setPasswordConfirmShown(passwordConfirmShown ? false : true);
    };

  
    // reset password func
  const handleSubmit = async (values) => {      
      const creds = {
        password: values.password,
        phone_number: phone,
        otp: otp
      }
      console.log(creds)
     await reset(creds)
  }; 

  useEffect(()=>{ 
    if(resetsuccess){
       setTimeout(()=>{
        navigate('/')
      }, 2000)
    }
  },[resetsuccess, navigate])


    return ( 
        <>
        <div className='row no-gutters'>
            <Sidebar/>
            <div className='col-lg-7'>
        <div className={styles.login_second_half_div}>

          <div className={styles.register_step_div}>
             <div>
              <h4 className={styles.login_second_half_title}>Create Password</h4>
            </div>
            <div className={styles.register_steppers}>
                <p>
                  <span>3</span>/3
                </p>
            </div>
           
          </div>

          <p className={styles.email_verify_title}>Create a strong password to secure your account</p>
           
            {/* login section */}
             {/* form submission */}
             <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={resetPasswordValidator}
              initialValues={{ password: "", confirm_password: "" }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* form section */}
                  <div className={`mt-5 ${styles.form_container}`}>
                      {/* password */}
                    <div className={`mb-3 ${styles.input_container}`} >
                      <label htmlFor="password">New Password</label>
                      <div style={{position: 'relative'}}>
                      <input
                        className={`${styles.input_style}`}
                        placeholder="Enter a new password"
                        id="password"
                        type={passwordShown ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {passwordShown
                              ? 
                              <img  
                              onClick={togglePasswordVisiblity}
                               className={styles.toggle_style} src={visible} alt='toggle off' />
                              :
                              <img  
                              onClick={togglePasswordVisiblity}
                               className={styles.toggle_style} src={visible_off} alt='toggle on' />
                          }
                      </div>
                    
                      <small style={{ color: "#dc3545" }}>
                        {touched.password && errors.password}
                      </small>
                    </div>

                    <div className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="confirm_password">Confirm Password</label>
                        <div style={{position: 'relative'}}>
                            <input
                                className={`${styles.input_style}`}
                                placeholder="Confirm password"
                                id="confirm_password"
                                type={passwordConfirmShown ? "text" : "password"}
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {passwordConfirmShown
                                    ? 
                                    <img  
                                    onClick={toggleConfirmPasswordVisiblity}
                                    className={styles.toggle_style} src={visible} alt='toggle off' />
                                    :
                                    <img  
                                    onClick={toggleConfirmPasswordVisiblity}
                                    className={styles.toggle_style} src={visible_off} alt='toggle on' />
                                }
                        </div>
                        <small style={{ color: "#dc3545" }}>
                        {touched.confirm_password && errors.confirm_password}
                        </small>
                     </div>

                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn ${styles.btn_login_submit}`}
                      >
                       Create Password
                      </button>
                    </div>    
                  </div>
                </Form>
              )}
            </Formik>

        </div>
      </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) =>{
  return{
    resetsuccess: state.auth.resetsuccess,
    phone: state.auth.phone,
    otp: state.auth.otpcode
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{ 
      reset: (creds) => dispatch(ResetPassword(creds)),
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);