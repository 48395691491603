import React, { useEffect } from 'react';
import Sidebar from '../../components/OnboardingSidebar/sidebar';
import styles from './forgotPassword.module.css';
import {Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from "formik";
import {forgotPasswordValidator} from "../../validationSchema/validator"
import {connect} from 'react-redux'
import { forgotPassword } from '../../store/actions/auth';
import Logo from "../../assets/logo/GatherLogo.svg";



const ForgotPassword = ({forgot, forgotsuccess}) => {

    const navigate = useNavigate()

    const handleSubmit = async (values) => {    
        const creds = {
            phone_number: "+234" + values.phoneNumber.substring(1)
        }
        await forgot(creds)
    }

    useEffect(()=>{
        if(forgotsuccess){
            navigate('/verifycode')
        }
    },[forgotsuccess, navigate])

  return <>
    <div className='row no-gutters'>
        <Sidebar/>
        <div className='col-lg-7'>
            <div className={styles.login_second_half_div}>

                <div className="logo mt-4 d-md-none d-sm-block">
                <img src={Logo} alt="logo" height="50" />
                </div>

                
                <div className={`mt-4 mt-md-0 ${styles.register_step_div}`}>
                    <div>
                    <h4 className={styles.login_second_half_title}>Forgotten your password?</h4>
                    </div>
                    <div className={styles.register_steppers}>
                        <p>
                        <span>1</span>/3
                        </p>
                    </div>
                </div>

                <p className={`mt-2 ${styles.email_verify_title}`}>Don’t feel bad, it happens to the best of us. To reset your password enter the phone number you registered with.</p>

                
                <Formik
                    onSubmit={(values, {setSubmitting})=>
                    handleSubmit(values, setSubmitting)
                }
                validationSchema={forgotPasswordValidator}
                initialValues={{phoneNumber:""}}
                >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    })=>(
                        <Form onSubmit={handleSubmit}>
                            {/* form section*/}
                            <div className={`mt-4 ${styles.form_container}`}>
                              <div className={`mb-4 ${styles.input_container}`}>
                                <label htmlFor="phoneNumber">Phone Number</label>
                                    <div>
                                        <input
                                        className={`${styles.input_style}`}
                                        type="text"
                                        placeholder="Enter your Phone Number"
                                        id="phoneNumber"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                        />
                                    </div>
                                    
                                    <small style={{ color: "#dc3545" }}>
                                        {touched.phoneNumber && errors.phoneNumber}
                                    </small>
                                    </div>
                                <div>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn ${styles.btn_login_submit}`}
                                    >
                                        Continue
                                    </button>
                                </div>

                                <div className="mt-2">
                                    <p className={`text-center ${styles.login_link}`}>
                                        Remember Password? 
                                        <Link to="/"> Log In</Link>
                                    </p>
                                </div>
                            </div>
                        </Form>
                    )}

                </Formik>
            </div>
        </div>
    </div>
  </>;
};

const mapStateToProps = (state) =>{
    return{
        forgotsuccess: state.auth.forgotsuccess
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{ 
        forgot: (creds) => dispatch(forgotPassword(creds)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
