import React, { useState, useEffect} from 'react';
import SideBar from "../../components/Sidebar/sidebar";
import styles from './overview.module.css'
import product_icon from "../../assets/icons/products_green_icon.svg";
import order_icon from "../../assets/icons/order_green_icon.svg";
import arrow from "../../assets/icons/arrow_drop_down.svg";
import arrow_up from "../../assets/icons/arrow_drop_up.svg";
import increase_icon from "../../assets/icons/increase_icon.svg";
import decrease_icon from "../../assets/icons/decrease_icon.svg";
import normal_icon from "../../assets/icons/normal_icon.svg";
import warning_icon from "../../assets/icons/warning_icon.svg";
import DoughnutChart from '../../components/Charts/Doughnut';
import {connect} from 'react-redux'
import { getOverviewInfo, getOverviewTopFive, getTotalProducts } from '../../store/actions/overview';
// import dash from "../../assets/icons/Dash.svg";
// import plus from "../../assets/icons/plus.svg";
import cup from "../../assets/icons/cup.svg";
import { useNavigate } from 'react-router-dom';
import restock_icon from "../../assets/icons/restock_default.svg";
import { checkEmailVerification } from '../../store/actions/auth';

const OverviewPage = ({fetchTotalProducts, totalProduct, totalOrder, isVerified, fetchOverview, incompleteOrders, completedOrders, totalRevenue, topProducts, fetchTopFive, restock, checkEmail }) => {

    const [isProductOpen, setIsProductOpen] = useState(false)
    const [title, setTitle] = useState("This Week")
    
    const navigate = useNavigate();

  
   const [productSort] = useState([
     {
       id: 1,
       name: "This Week",
       tag: "week",
     },
     {
       id: 2,
       name: "This Month",
       tag: "month",
     },
     {
       id: 3,
       name: "This Year",
       tag: "year",
     }
   ]);
  

  const toggleProdList = () => {
   setIsProductOpen(!isProductOpen)
  }

  const toggleProduct = (val) => {
    const {tag, name} = val
    setTitle(name)
    setIsProductOpen(false)
    console.log(tag)

    // make a call to get the top 5 products based on the filter selected
    fetchTopFive(tag)
  }

  const numberWithCommas = (x) => { 
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
  }

  useEffect(() =>{
    fetchTotalProducts()
    fetchOverview()
    fetchTopFive('week')
    checkEmail()
  },[fetchTotalProducts, fetchOverview, fetchTopFive, checkEmail])



  const handleRestock = (type,id) =>{
    if(type !== null){
      navigate(`/product/details/${type.toLowerCase()}/${id}`)
    }
  }

  const handleProductRoute = () =>{
    navigate('/products')
  }

  const handleImagePosition = (val) =>{
    let res;
    switch(val){
      case "up":
        res = increase_icon
        break;
      case "down":
        res = decrease_icon
        break;
      case "same":
        res = normal_icon
        break;
      default:
        break;
    }
    return res;
  }

  return (
    <>
      <SideBar>
        </SideBar>
      <div className="usermain">
        <div className="contain">

        <div className={styles.gather_alert_div}>
              <p className="titlehead">Overview</p>
         </div>

        {/* alert */}
          {
            isVerified ? 
            ""
            :
          <div className={`alert mt-3 ${styles.gather_alert}`} >
            <img src={warning_icon} alt="icon" className='mr-2' /> You haven’t verified your email, kindly check your email for verification
          </div>
          }
      

{/* 
        <div className={styles.no_productDiv}>
           <div className={styles.dash_icon}>
           <img src={dash} className='img-fluid' alt="dash" />
           </div>
           <div> 
             <p className={styles.dash_text}>You haven’t added any product yet</p>
           </div>
          <div>
           <Link to="/product/add" className={`btn ${styles.btn_add}`}>
                 <img className='img-fluid' src={plus} alt="icon" />
                 <span>Add Product</span>
           </Link>
           </div>
       </div>
   */}
      
      <div className={`row ${styles.overview_layout_div}`}>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-6">
                  <div className={styles.revenue_card}>
                    <div className={styles.revenue_layout}>
                      <div>
                        <h4 className={styles.revenue_title}>₦{totalRevenue ? numberWithCommas(totalRevenue) :0}</h4>
                        <p className={styles.revenue_subtitle}>Total Revenue</p>
                      </div>
                      <div className={styles.revenue_icon_div}>
                        <h2>₦</h2>
                      </div>
                    </div>
                  </div>
                </div>

                {/* second card */}
                <div className="col-lg-6 mt-md-0 mt-4">
                  <div className={styles.product_card} onClick={() => handleProductRoute()}>
                    <div className={styles.product_layout}>
                      <div>
                        <h4 className={styles.product_title}>{totalProduct}</h4>
                        <p className={styles.product_subtitle}>
                         {totalProduct > 1 ? "Total Products" : "Total Product"}
                        </p>
                      </div>
                      <div className={styles.product_icon_div}>
                        <img src={product_icon} alt="product" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="row">
                {/* third card */}
                <div className="col-lg-12 mt-md-0 mt-4">
                  <div className={styles.order_card}>
                    <div className={styles.order_layout}>
                      <div>
                        <h4 className={styles.order_title}>{totalOrder}</h4>
                        <p className={styles.order_subtitle}>
                        {totalOrder > 1 ? "Total Orders" : "Total Order"}</p>
                      </div>
                      <div>
                        <h6 className={styles.completed_order}>
                          Completed Orders
                        </h6>
                        <p className={styles.completed_val}>{completedOrders ? completedOrders : 0}</p>

                        <h6 className={styles.incomplete_order}>
                          Incompleted Orders
                        </h6>
                        <p className={styles.incomplete_val}>{incompleteOrders ? incompleteOrders : 0}</p>
                      </div>
                      <div className={styles.order_icon_div}>
                        <img src={order_icon} alt="order" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* restock layout */}

          {
            restock && restock.length > 0 ?
            <div className={styles.restock_div}>
            <p className={styles.restock_title}>Products to be Restocked</p>
            <div className="row">
              {restock.length > 0 ? restock.map((item)=>(
                <div key={item.id} className="col-lg-4 mb-4 mb-md-4">
                    <div className={styles.products_restock_div}>
                      <div className={styles.products_restock_img}>
                        <img onClick={() => handleRestock(item.product_type,item.id)} src={item.images !== null && item.images.length > 0 ? item.images[0] : restock_icon} alt="products" />
                      </div>
                      <div className={styles.products_restock_name}>
                        <h6 className="mb-0">{item.name} ({item.product_size}{item.measurement})</h6>
                        <p className="mb-0">{item.units} Unit(s) left</p>
                      </div>
                    </div>
                  </div> 
                ))
                :
                ""
                }
            </div>
          </div>
          :
          ""
          }
        

          {/* Order category and top 5 products section */}
          <div className="row mt-4">
                <DoughnutChart />
            <div className="col-lg-5 mt-md-0 mt-4">
              <div className={styles.top5_category_card}>
                <div className={styles.top5_category_heading}>
                  <div>
                    <p className={styles.top5_category_title}>Top 5 Products</p>
                  </div>

                 
                  <div className={styles.order_category_dropdown_div}>
                    <div
                      className={styles.order_category_dropdown}
                      onClick={() => toggleProdList()}
                    >
                      <p>{title}</p>
                      {isProductOpen ? (
                        <img src={arrow_up} alt="arrow" />
                      ) : (
                        <img src={arrow} alt="arrow" />
                      )}
                    </div>

                    {/* dropdown section */}
                    <ul
                      className={
                        isProductOpen
                          ? `${styles.dropdown_section} ${styles.dropdown_section_active}`
                          : styles.dropdown_section
                      }
                    >
                      {productSort.map((val) => (
                        <li key={val.id} onClick={() => toggleProduct(val)}>
                          <p className={styles.dropdown_list}>{val.name}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  

                </div>

              {/* layout for no top 5 products */}
            {
              topProducts && topProducts.length === 0
              ?
              <div className={styles.no_top5_div}>
              <div className={styles.no_top5_icon}>
                    <img src={cup} alt="top" />
              </div>
              <div>
                <p className={styles.no_top5_text}>Your top products would show as soon as you start making sales</p>
              </div>
            </div>
            :
            <>
                {/* top5 products layout */}

                {topProducts.length > 0 ? topProducts.map((item, index)=>(
                <div key={index} className={styles.top5_products_div}>
                  <div className={styles.top5_products_img}>
                    <img
                     src={item.images.length > 0 ? item.images[0] : restock_icon}
                     alt="products" />
                  </div>
                  <div className={styles.top5_products_rate}>
                    <img src={handleImagePosition(item.position)} alt="increase" />
                    <span>{index+1}</span>
                  </div>
                  <div className={styles.top5_products_name_div}>
                    <h6 className={styles.top5_products_name}>
                      {item.name} ({item.product_size}{item.measurement})
                    </h6>
                    <p className={styles.top5_products_unit}>{item.units_sold} Unit(s) Sold</p>
                  </div>
                </div>
                ))
                :
                ""
                }
            </>

            }   

              </div>
            </div>
          </div>
        
      
    
       
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) =>{
  return{
    totalProduct: state.overview.totalProduct,
    totalOrder: state.overview.totalOrder,
    completedOrders: state.overview.completedOrders,
    incompleteOrders: state.overview.incompleteOrders,
    totalRevenue: state.overview.totalRevenue,
    isVerified: state.auth.isVerified,
    topProducts: state.overview.topProducts,
    restock: state.overview.restockProducts
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
    fetchTotalProducts: () => dispatch(getTotalProducts()),
    fetchOverview: () => dispatch(getOverviewInfo()),
    fetchTopFive: (val) => dispatch(getOverviewTopFive(val)),
    checkEmail: () => dispatch(checkEmailVerification()),   
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);