import React, {useEffect} from 'react';
import './modal.css'
import save_icon from "../../assets/icons/save_icon.svg"
import close_icon from "../../assets/icons/close.svg"
import { CSSTransition } from 'react-transition-group'


const ModalComp = (props) => {

    useEffect(() => {
        if (props.show) {
           document.body.classList.add("modal-open");
        }
        else {
           document.body.classList.remove("modal-open");
        }
       
      }, [props.show]);

    return ( 
        <>
         <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
         <div className='modal' onClick={props.showModal}>
            <div className='modal-content' onClick={(e) => e.stopPropagation()}>
                <div className='modal-close-icon' onClick={props.showModal}>
                    <img
                        src={close_icon}
                        width="14"
                        height="14"
                        className=""
                        alt="Close icon"
                    />
                </div>

                <div className="modal-content-layout">
                     <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div className='save_icon'>
                                <img src={save_icon} alt="save"/>
                            </div>
                    </div>

                    <p className='mt-2 modal_main_text'>{ `Your changes has been saved` || props.modalContent}</p>
                        {props.children}
                </div>
                    
            </div>
          </div>
        </CSSTransition>
        </>
     );
}
 
export default ModalComp;