import React,{useEffect} from 'react';
import './modal.css'
import { CSSTransition } from 'react-transition-group'
import close_icon from "../../assets/icons/close.svg"


const PopComp = (props) => {

    useEffect(() => {
        if (props.show) {
           document.body.classList.add("modal-open");
        }
        else {
           document.body.classList.remove("modal-open");
        }
       
      }, [props.show]);

    return (
        <>
          <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
         <div className='modal' onClick={props.showModal} >
            <div className='modal-content' onClick={(e) => e.stopPropagation()}>
                 <div className='modal-close-icon' onClick={props.showModal}>
                    <img
                        src={close_icon}
                        width="14"
                        height="14"
                        className=""
                        alt="Close icon"
                    />
                </div>
                <div className="modal-content-layout">
                     {props.children}
                </div>

             </div>
            </div>
        </CSSTransition>

        </>
      );
}
 
export default PopComp;