import React, {useEffect, useState, useCallback, useMemo} from 'react';
import Sidebar from '../../components/Sidebar/sidebar';
import search_icon from "../../assets/icons/search_icon.svg";
import download from "../../assets/icons/download.svg";
import styles from './order.module.css'
import CsvDownload from 'react-json-to-csv'
import PaginationComponent from '../../components/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { getOrdersByType, searchOrders } from '../../store/actions/order';
import { connect } from 'react-redux';
import Moment from "react-moment";
import {debounce} from 'lodash'


const OrdersPage = ({fetchOrders, loading, orders, count, searchOrder, vendorTypes}) => {

  const [typeTag, setTypeTag] = useState(vendorTypes[0].tag)

  const navigate = useNavigate();


  const getStatusColor = (val) => {
    let result;
    switch (val) {
      case "DELIVERED":
        result = styles.delivery_status;
        break;
      case "PENDING":
        result = styles.pending_status;
        break;
      case "PROCESSING":
        result = styles.pending_status;
        break;
      case "DISPATCHED":
        result = styles.intransit_status;
        break;
      case "CANCELLED":
        result = styles.cancelled_status;
        break;
      default:
        result = styles.cancelled_status;
        break;
    }
    return result;
  };

  const handleCapitalize = (str) =>{
    let value = str.toLowerCase()
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  const handleType = (val) =>{  
    const {tag, id} = val
    setTypeTag(tag)
    // make call to fetch products based on the type selected and for page 1
    switch(id){
      case 1: 
      fetchOrders(tag, 1)
      break;
      case 2:
        fetchOrders(tag, 1)
        break;
        case 3: 
        fetchOrders(tag, 1)
        break;
        case 4:
          fetchOrders(tag, 1)
          break;
        case 5:
          fetchOrders(tag, 1)
          break;
        default:
          break;
    }
  }


  const handleOrder = (type,id) =>{
    navigate(`/order/${type}/${id}`)
  }

  
  let PageSize = 10;


  const [currentPage, setCurrentPage] = useState(1);

  const getMoreItemsByOffset = (page, type) => {
    setCurrentPage(page)
    console.log(type)
    fetchOrders(type, page)
  }

  // get all orders based on the active vendor type
  useEffect(()=>{
    fetchOrders(vendorTypes[0].tag, 1)
  },[fetchOrders, vendorTypes])


   // handle search
   const handleSearch = useMemo(()=>
   debounce((type,val)=>{
     searchOrder(type,val)
   },750),
  [searchOrder]
 )

 const handleChange = useCallback((e)=>{ 
   let val = e.target.value
   handleSearch(typeTag,val)
},[handleSearch,typeTag])

const numberWithCommas = (x) => { 
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
}
  



  return (
    <>
      <Sidebar>
     
        </Sidebar>
        <div className="usermain">
          <div className="contain">

          <div className={styles.orders_title}>
              <p className="titlehead">Orders</p>
          </div>

            <div className={styles.orders_left_div}>
              <div className={styles.orders_left_search}>
                <div style={{position: 'relative'}}>
                  <img src={search_icon} alt="search" className={styles.orders_search_icon} />
                  <input type="text" onChange={handleChange} placeholder='Search By Status' className={styles.orders_search_input} />
                </div>
              
              </div>
              <div className={styles.orders_right_div}>
                  {
                      orders.length > 0 && orders ?
                      <CsvDownload data={orders} 
                      className={styles.orders_download_div} 
                      filename={`orders.csv`}>
                          <img src={download} alt="download" />
                      </CsvDownload>
                      :
                      ""
                    }
              </div>
              
            </div>

             {/* filter tabs */}
              <div className={styles.orders_filter_div}>
                {vendorTypes.map((val) => (
                    <li
                      key={val.id}
                      onClick={() => handleType(val)}
                    >
                      <p 
                      className={typeTag === val.tag ? `${styles.orders_tab} ${styles.orders_tab_active}` : `${styles.orders_tab}`}
                      >{val.title} <span>{typeTag === val.tag ? count : ''}</span></p>
                    </li>
                  ))}
              
              </div>

              {/* tables layout */}
              <div className={styles.tables_div}>
                <table>
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      {/* <th>Delivery Address</th> */}
                      <th>Quantity</th>
                      <th>Order Date</th>
                      <th>Amount</th>
                      <th>Order Status</th>
                    </tr>
                  </thead>
                  <tbody>

                  {
                      loading ?
                      ( <div className={styles.orders_loader_div}>
                        <p>Loading...</p>
                    </div>)
                    :
                    (
                      orders.length > 0 ? orders.map((item)=>(
                        <tr key={item.id} onClick={() => handleOrder(typeTag,item.id)}>
                        <td>
                          <span className={styles.table_orderID}>{item.id}</span>
                      </td> 
                   {/* <td className={styles.table_address}>
                     <span>  
                     Oyo State
                     </span>
                    </td> */}
                   <td>{item.quantity}</td>
                   <td><Moment format="Do, MMM YYYY">{item.created}</Moment></td>
                   <td>
                   {
                     `₦ ${item.costPrice ? numberWithCommas(item.costPrice) : 0}`
                   }
                   </td>
                   <td>
                   <span className={getStatusColor(item.delivery_status)}>{handleCapitalize(item.delivery_status)}</span>
                     </td>
                 </tr>
                  ))
                  :
                  <div className={styles.orders_loader_div}>
                     <p>There are no records to display</p>
                  </div>
                 )
                }
                  

                  </tbody>
                  </table>
              </div>


               
          {
             loading ?
                ""
                :
                orders.length > 0 && orders ?
              <div className={styles.pagination_div}>
                <PaginationComponent
                      currentPage={currentPage}
                      totalCount={count}
                      pageSize={PageSize}
                      onPageChange={(page) => getMoreItemsByOffset(page, typeTag)}
                    />
               </div>
            :
            ""
            } 

              





          </div>
        </div>
    </>
    );
}
 

const mapStateToProps = (state) =>{
  return{
    orders: state.order.orders,
    reports: state.order.reports,
    count: state.order.count,
    loading: state.order.loading,
    vendorTypes: state.auth.vendorTypes
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
    fetchOrders : (type, page) => dispatch(getOrdersByType(type, page)),
    searchOrder : (type, val) => dispatch(searchOrders(type, val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);