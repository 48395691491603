import cogoToast from "cogo-toast";
import { PostApi, PatchApi, GetApi } from "../request";
import { finagUrl } from "../config";
import axios from "axios";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// login user actions functionality
export const loginUser = (user) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(`auth/signin`, { 
          phoneNumber: user.phoneNumber,
          password: user.password,
       }, "", "application/json");
      if (res.status === 200) {
          console.log(res.data)
        dispatch({ type: "LOGIN_SUCCESS", data: res.data });
        cogoToast.success("Login Successful!", {
          position: "bottom-right",
        });      
      }
       if (res.status === 401) {
         cogoToast.error(`Invalid login credentials`);
       }
    } catch (err) {
      console.log(err);
    }
  };
};

//verify password functionality
export const verifyEmail = (user) =>{
  return async (dispatch, getState) =>{
    try {
      const res = await axios.post(`${finagUrl}verify-email/complete/`, {...user}, "", "application/json");
      console.log(res)
      if (res.status === 200){
        dispatch({ type: 'VERIFY_EMAIL_SUCCESS'})
      }
      if (res.status === 400){
        dispatch({type: 'VERIFY_EMAIL_ERROR'})
       
      }
    } catch (error) {
      console.log(error.response)
      if (error.response.status === 500){
        dispatch({type: 'VERIFY_EMAIL_ERROR'})
      }
    }
  }
}

//verify forgot password code functionality
export const verifyForgotCode = (val) =>{
  return async (dispatch, getState) =>{
    try {
      const res = await axios.post(`${finagUrl}organization/password-reset/otp-confirm/`, {
        ...val
      }, "", "application/json");
      if (res.status === 200){
        dispatch({ type: 'VERIFY_CODE_SUCCESS', data: val.otp})
      }
    } catch (error) {
      console.log(error.response)
      if (error.response.status === 400){
        dispatch({type: 'VERIFY_CODE_ERROR'})
        cogoToast.error(`OTP is either invalid or has expired, please request for an new OTP!`);
      }
      if (error.response.status === 500){
        dispatch({type: 'VERIFY_CODE_ERROR'})
        cogoToast.error(`OTP is either invalid or has expired, please request for an new OTP!`);
      }
    }
  }
}

// forgot password functionality
export const forgotPassword = (user) => {
  return async (dispatch, getState) => {
     try {
       const res = await axios.post(`${finagUrl}organization/password-reset/request/`, { ...user }, "", "application/json");
       if (res.status === 200) {
          cogoToast.success(
            "OTP has been sent to the phone number you registered with!",
            {
              position: "top-center",
            }
          );
          dispatch({type: 'FORGOT_SUCCESS', data: user.phone_number})
       }
        if (res.status === 400) {
          cogoToast.error(
            "Phone number is either invalid or does not exist!"
          );
        }
     } catch (err) {
       if(err.response.status === 400){
        cogoToast.error(
          "Phone number is either invalid or does not exist!"
        ); 
       }
       
     }
  }
}

// clear forgot password status
export const clearForgotSuccess = () =>{
  return dispatch =>{
      dispatch({type: 'clearForgotSuccess'})
  }
}

// reset password functionality
export const ResetPassword = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await axios.patch(
        `${finagUrl}organization/password-reset/complete/`,
        { 
          ...val
         },
        "",
        "application/json"
      );
      if (res.status === 200) {
        // reset a user's password
        dispatch({ type: 'RESET_SUCCESS' });
        cogoToast.success("Password successfully changed, Login to continue", {
          position: "top-right",
        });
      }
      if(res.status === 400){
        // error while reset password
        dispatch({ type: 'RESET_ERROR' });
        cogoToast.error(`OTP is either invalid or has expired, please request for an new OTP!`);
      }
    } catch (err) {
      if(err.response.status === 403 || 404){
        dispatch({ type: 'RESET_ERROR' }); 
        cogoToast.error(`OTP is either invalid or has expired, please request for an new OTP!`);
      }
    }
  };
};


//   Change password functionality
export const ChangePassword = (user) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    
    const values = {
      oldPassword: user.password,
      newPassword: user.newpassword
    }
    try {
      const res = await PatchApi("auth/change-password", { ...values }, getToken() || token, "application/json");
      if (res.status === 200) {
         localStorage.setItem("token", "");
         dispatch({ type: "logout", err: "User Out" });
        cogoToast.success('Password updated successfully! Kindly Login again.')
      }
      if(res.status === 400){
        cogoToast.error('Check that your old password is correct!')
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// logout a user
export const logOut = () => {
  return (dispatch, getState) => {
    localStorage.setItem("token", "");
    dispatch({ type: "logout", err: "User Out" });
  };
};


 // Receive values from onboarding a vendor
 export const OnBoardValues = (val) =>{
  return dispatch =>{
      dispatch({type: 'OnBoardValues', data: val})
  }
}

// sign up user functionality
export const signUp = (user) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(
        "auth/signup",
        {
          firstName: user.firstname,
          lastName: user.lastname,
          password: user.password,
          companyName: user.companyName,
          companyEmail: user.email,
          vendorTypes: user.vendorTypes,
          phoneNumber: user.phoneNumber,
          bvn: user.bvn,
          accountName: user.accountName,
          accountNumber: user.accountNumber,
          bank: user.bank
        },
        "",
        "application/json"
      );
      if (res.status === 200) {
        console.log(res.data)
        localStorage.setItem("token", res.data.token);
        dispatch({ type: "SIGNUP_SUCCESS", data: res.data });
        cogoToast.success("Registration Successful, Welcome to GatherStore");
      }
      if(res.status === 400){
        dispatch({ type: "SIGNUP_FAIL", err: res.data});
        cogoToast.error('Oops! Looks like a user already exist with these credentials.')
      }
      if(res.status === 401){
        cogoToast.error('Oops! Looks like a user already exist with these credentials.')
        dispatch({ type: "SIGNUP_ERROR"})
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// clear sign up error status
export const clearSignUpError = () =>{
  return dispatch =>{
      dispatch({type: 'clearSignUpError'})
  }
}

 // clear sign up success status
 export const clearSignUp = () =>{
  return dispatch =>{
      dispatch({type: 'clearSignUpSuccess'})
  }
}

//update profile functionality
export const updatePersonalProfile = (user) => {
  return async (dispatch, getState) => {
  let id = getState().auth.profile.id
  let token = getState().auth.token
    try {
      const res = await PatchApi(
        `auth/update-user/${id}`,
        {
          firstName: user.firstName,
          lastName: user.lastName,
        },
        getToken() || token,
        "application/json"
      );
      if (res.status === 200) {
        dispatch({ type: "ProfileUpdate_SUCCESS", data: res.data });
        cogoToast.success('Profile updated successfully!')
      }
      if(res.status === 400){
        dispatch({ type: "ProfileUpdate_FAIL", err: res.data});
        cogoToast.error('Error while updating profile.')
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// update company information
export const updateCompanyInfo = (user) => {
  return async (dispatch, getState) => {
  let id = getState().auth.organizationId
  let token = getState().auth.token
    try {
      const res = await PatchApi(
        `auth/update-org/${id}`,
        {
          name: user.name,
          description: user.description,
          address: user.address,
          vendorTypes: user.vendorTypes
        },
        getToken() || token,
        "application/json"
      );
      if (res.status === 200) {
        dispatch({ type: "CompanyUpdate_SUCCESS", data: res.data.data });
        cogoToast.success('Company Information updated successfully!')
      }
      if(res.status === 400){
        dispatch({ type: "CompanyUpdate_FAIL", err: res.data});
        cogoToast.error('Error while updating company information.')
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// update bank details
export const updateBankInfo = (user) => {
  return async (dispatch, getState) => {
  let id = getState().auth.organizationId
  let token = getState().auth.token
    try {
      const res = await PatchApi(
        `auth/update-org/${id}`,
        {
          bank: user.bank,
          accountName: user.accountName,
          accountNumber: user.accountNumber,
        },
        getToken() || token,
        "application/json"
      );
      if (res.status === 200) {
        dispatch({ type: "BankUpdate_SUCCESS", data: res.data.data });
        cogoToast.success('Bank Information updated successfully!')
      }
      if(res.status === 400){
        dispatch({ type: "BankUpdate_FAIL", err: res.data});
        cogoToast.error('Error while updating bank information.')
      }
    } catch (err) {
      console.log(err)
    }
  };
};



// email verification functionality
export const checkEmailVerification = (user) => {
  return async (dispatch, getState) => {
    let email = getState().auth.profile.email
    let token = getState().auth.token
      try {
        const res = await PostApi(
          `auth/emailverified`,
          {
           email: email
          },
          getToken() || token,
          "application/json"
        );
        if (res.status === 200) {
          dispatch({ type: "EmailVerify_Success", data: res.data.data });
        }
        if(res.status === 400){
          dispatch({ type: "EmailVerify_Fail", err: res.data});
        }
      } catch (err) {
        console.log(err)
      }
    };
}


// Get all banks
export const getBanks = () => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    try {
      const res = await GetApi(`banks/`, getToken() || token);
      if (res.status === 200) {
        console.log(res.data)
        dispatch({ type: "Banks", data: res.data.data });
      }
      if (res.status === 400) {
        dispatch({ type: "Banks", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};