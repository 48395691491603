export const states = [
  {
      "id": "1",
      "name": "Abia",
      "display_name": "Abia",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.658Z",
      "updated": "2021-07-22T10:35:17.658Z"
  },
  {
      "id": "2",
      "name": "Adamawa",
      "display_name": "Adamawa",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.790Z",
      "updated": "2021-07-22T10:35:17.790Z"
  },
  {
      "id": "3",
      "name": "Akwa Ibom",
      "display_name": "Akwa Ibom",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.793Z",
      "updated": "2021-07-22T10:35:17.793Z"
  },
  {
      "id": "4",
      "name": "Anambra",
      "display_name": "Anambra",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.796Z",
      "updated": "2021-07-22T10:35:17.796Z"
  },
  {
      "id": "5",
      "name": "Bauchi",
      "display_name": "Bauchi",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.799Z",
      "updated": "2021-07-22T10:35:17.799Z"
  },
  {
      "id": "6",
      "name": "Bayelsa",
      "display_name": "Bayelsa",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.803Z",
      "updated": "2021-07-22T10:35:17.803Z"
  },
  {
      "id": "7",
      "name": "Benue",
      "display_name": "Benue",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.807Z",
      "updated": "2021-07-22T10:35:17.807Z"
  },
  {
      "id": "8",
      "name": "Borno",
      "display_name": "Borno",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.809Z",
      "updated": "2021-07-22T10:35:17.809Z"
  },
  {
      "id": "9",
      "name": "Cross River",
      "display_name": "Cross River",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.812Z",
      "updated": "2021-07-22T10:35:17.812Z"
  },
  {
      "id": "10",
      "name": "Delta",
      "display_name": "Delta",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.815Z",
      "updated": "2021-07-22T10:35:17.815Z"
  },
  {
      "id": "11",
      "name": "Ebonyi",
      "display_name": "Ebonyi",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.817Z",
      "updated": "2021-07-22T10:35:17.817Z"
  },
  {
      "id": "12",
      "name": "Edo",
      "display_name": "Edo",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.820Z",
      "updated": "2021-07-22T10:35:17.820Z"
  },
  {
      "id": "13",
      "name": "Ekiti",
      "display_name": "Ekiti",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.823Z",
      "updated": "2021-07-22T10:35:17.823Z"
  },
  {
      "id": "14",
      "name": "Enugu",
      "display_name": "Enugu",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.826Z",
      "updated": "2021-07-22T10:35:17.826Z"
  },
  {
      "id": "15",
      "name": "F.C.T.",
      "display_name": "F.C.T.",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.829Z",
      "updated": "2021-07-22T10:35:17.829Z"
  },
  {
      "id": "16",
      "name": "Gombe",
      "display_name": "Gombe",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.832Z",
      "updated": "2021-07-22T10:35:17.832Z"
  },
  {
      "id": "17",
      "name": "Imo",
      "display_name": "Imo",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.835Z",
      "updated": "2021-07-22T10:35:17.835Z"
  },
  {
      "id": "18",
      "name": "Jigawa",
      "display_name": "Jigawa",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.838Z",
      "updated": "2021-07-22T10:35:17.838Z"
  },
  {
      "id": "19",
      "name": "Kaduna",
      "display_name": "Kaduna",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.840Z",
      "updated": "2021-07-22T10:35:17.840Z"
  },
  {
      "id": "20",
      "name": "Kano",
      "display_name": "Kano",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.843Z",
      "updated": "2021-07-22T10:35:17.843Z"
  },
  {
      "id": "21",
      "name": "Katsina",
      "display_name": "Katsina",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.846Z",
      "updated": "2021-07-22T10:35:17.846Z"
  },
  {
      "id": "22",
      "name": "Kebbi",
      "display_name": "Kebbi",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.849Z",
      "updated": "2021-07-22T10:35:17.849Z"
  },
  {
      "id": "23",
      "name": "Kogi",
      "display_name": "Kogi",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.852Z",
      "updated": "2021-07-22T10:35:17.852Z"
  },
  {
      "id": "24",
      "name": "Kwara",
      "display_name": "Kwara",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.854Z",
      "updated": "2021-07-22T10:35:17.854Z"
  },
  {
      "id": "25",
      "name": "Lagos",
      "display_name": "Lagos",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.857Z",
      "updated": "2021-07-22T10:35:17.857Z"
  },
  {
      "id": "26",
      "name": "Nasarawa",
      "display_name": "Nasarawa",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.860Z",
      "updated": "2021-07-22T10:35:17.860Z"
  },
  {
      "id": "27",
      "name": "Niger",
      "display_name": "Niger",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.863Z",
      "updated": "2021-07-22T10:35:17.863Z"
  },
  {
      "id": "28",
      "name": "Ogun",
      "display_name": "Ogun",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.866Z",
      "updated": "2021-07-22T10:35:17.866Z"
  },
  {
      "id": "29",
      "name": "Ondo",
      "display_name": "Ondo",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.869Z",
      "updated": "2021-07-22T10:35:17.869Z"
  },
  {
      "id": "30",
      "name": "Osun",
      "display_name": "Osun",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.872Z",
      "updated": "2021-07-22T10:35:17.872Z"
  },
  {
      "id": "31",
      "name": "Oyo",
      "display_name": "Oyo",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.874Z",
      "updated": "2021-07-22T10:35:17.875Z"
  },
  {
      "id": "32",
      "name": "Plateau",
      "display_name": "Plateau",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.877Z",
      "updated": "2021-07-22T10:35:17.878Z"
  },
  {
      "id": "33",
      "name": "Rivers",
      "display_name": "Rivers",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.880Z",
      "updated": "2021-07-22T10:35:17.880Z"
  },
  {
      "id": "34",
      "name": "Sokoto",
      "display_name": "Sokoto",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.883Z",
      "updated": "2021-07-22T10:35:17.883Z"
  },
  {
      "id": "35",
      "name": "Taraba",
      "display_name": "Taraba",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.885Z",
      "updated": "2021-07-22T10:35:17.885Z"
  },
  {
      "id": "36",
      "name": "Yobe",
      "display_name": "Yobe",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.888Z",
      "updated": "2021-07-22T10:35:17.888Z"
  },
  {
      "id": "37",
      "name": "Zamfara",
      "display_name": "Zamfara",
      "country": "Nigeria",
      "created": "2021-07-22T10:35:17.891Z",
      "updated": "2021-07-22T10:35:17.891Z"
  }
]