import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/Sidebar/sidebar';
import styles from "./profile.module.css";
// import profileImg from "../../assets/images/profile_img.png";
import usericon from "../../assets/icons/user_icon.svg";
import user_active from "../../assets/icons/Profile_icon.svg";
import members from "../../assets/icons/members.svg";
import members_active from "../../assets/icons/members_active.svg";
import bank from "../../assets/icons/bank.svg";
import bank_active from "../../assets/icons/bank_active.svg";
import egg from "../../assets/icons/egg.svg";
import shield_active from "../../assets/icons/lock_active.svg";
import shield from "../../assets/icons/lock.svg";
import PopComp from '../../components/Modal/popups';
import info from "../../assets/icons/info_outline.svg"
import save_icon from "../../assets/icons/save_icon.svg"
import store from "../../assets/icons/store_mall_directory.svg"
import {connect} from 'react-redux';
import { ChangePassword, getBanks, updateBankInfo, updateCompanyInfo, updatePersonalProfile } from '../../store/actions/auth';
import Moment from "react-moment";
import { ChangePasswordValidator } from '../../validationSchema/validator';
import { Form, Formik } from "formik";
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import cogoToast from 'cogo-toast';


const ProfilePage = ({firstname, lastname, 
  organizationDescription, organizationAddress,
  vendorTypes,
  supplierType,
  companyEmail,updatePersonal,updateBank,
  accountName, accountNumber, banks,selectedBank,selectedBankId,phoneNumber,
   updateCompany,dateJoined,organizationName,changePassword, fetchBanks}) => {

  const [initialTab, setTab] = useState(1);
  const [show, setShow] = useState(false)
  const [showSave, setShowSave] = useState(false)
  const [isPersonalDisabled, setPersonalDisabled] = useState(false)
  const [isCompanyDisabled, setCompanyDisabled] = useState(false)
  const [selectedType, setSelectedType] = useState(supplierType)
  const [isBankDisabled, setBankDisabled] = useState(false)
  const [pickedBank, setPickedBank] = useState(selectedBankId)


  const [types] = useState(
    [
      {
        id: 1,
        title: 'Crop Products',
        tag: 'crop'
      },
      {
        id: 2,
        title: 'Livestock Products',
        tag: 'livestock'
      },
      {
        id: 3,
        title: 'Mechanization Products',
        tag: 'mechanization'
      },
      {
        id: 4,
        title: 'Advisory Products',
        tag: 'advisory'
      },
      {
        id: 5,
        title: 'Insurance Products',
        tag: 'insurance'
      }
    ]
  )

  useEffect(()=>{
    fetchBanks()
  },[fetchBanks])


  const handleSupplier = (val) =>{
    let supplyArray = [];
    val.map(o => 
      supplyArray.push(o.tag)
   );
   console.log(supplyArray)
   setSelectedType(supplyArray)
  }


  const handleBank = (val) =>{
    setPickedBank(val.id)
  }



  const navigate = useNavigate()

  const showModal = () =>{
    setShow(!show)
  }

  const showSaveModal = () =>{
    setShowSave(!showSave)
  }

  const handleClose = () =>{
    setShow(false)
  }

  const handleSaveClose = () =>{
    setShowSave(false)
  }

  const handleDiscardModal = () =>{
    setShow(true)
  }

  const handleSaveModal = () =>{
    setShowSave(true)
  }

  const handleDiscard = () =>{
    setShow(false)
  }


  const [tabData] = useState([
    { id: 1, name: "tab-1", text: "Company" },
    { id: 2, name: "tab-2", text: "Personal" },
  ]);

  const [initialCompanyTab, setCompanyTab] = useState(1);
  const [companyData] = useState([
    { id: 1, name: "tab-1", text: "Account Information", imageUrl: user_active, imageActive: usericon },
    { id: 2, name: "tab-2", text: "Members", imageUrl: members, imageActive: members_active },
    { id: 3, name: "tab-3", text: "Bank Details", imageUrl: bank, imageActive: bank_active },
  ]);

  const handleToggle = (id) => {
    setTab(id)
  }

  // tab layout
  const tabLayout = tabData.map((item) => (
    <li key={item.id}
      onClick={() => handleToggle(item.id)}
      className={initialTab === item.id ? `${styles.profile_nav_link_active}` : `${styles.profile_nav_link}`}>
      <p>{item.text}</p>
    </li>
  ));

  const handleTabToggle = (id) => {
    setCompanyTab(id)
  }

  // company tab layout
  const companyTabLayout = companyData.map((item) => (
    <p key={item.id} onClick={() => handleTabToggle(item.id)}
      className={initialCompanyTab === item.id ? `${styles.company_list_item} ${styles.company_list_item_active}` : `${styles.company_list_item}`}
    >
      <div className={styles.company_list_icon}>
        <img src={initialCompanyTab === item.id ? item.imageActive : item.imageUrl} className='img-fluid' alt='account info' />
      </div>
      <div
        className={initialCompanyTab === item.id ? `${styles.company_list_title} ${styles.company_list_title_active}` : `${styles.company_list_title}`}
      >
        <span>{item.text}</span>
      </div>
    </p>
  ));

  const [initialPersonalTab, setPersonalTab] = useState(1);
  const [PersonalData] = useState([
    { id: 1, name: "tab-1", text: "Account Information", imageUrl: user_active, imageActive: usericon },
    { id: 2, name: "tab-2", text: "Change Password", imageUrl: shield, imageActive: shield_active  },
  ]);

  
  // personal tab layout
  const personalTabLayout = PersonalData.map((item) => (
    <p key={item.id} onClick={() => handlePersonalToggle(item.id)}
      className={initialPersonalTab === item.id ? `${styles.company_list_item} ${styles.company_list_item_active}` : `${styles.company_list_item}`}
    >
      <div className={styles.company_list_icon}>
        <img src={initialPersonalTab === item.id ? item.imageActive : item.imageUrl} className='img-fluid' alt='account info' />
      </div>
      <div
        className={initialPersonalTab === item.id ? `${styles.company_list_title} ${styles.company_list_title_active}` : `${styles.company_list_title}`}
      >
        <span>{item.text}</span>
      </div>
    </p>
  ));

  const handlePersonalToggle = (id) => {
    setPersonalTab(id)
  }

  const [first, setFirst] = useState(firstname)
  const [last, setLast] = useState(lastname)
  const [email, setEmail] = useState(companyEmail)
  const [phone_number] = useState(phoneNumber)
  let [accountNb, setAccountNumber] = useState(accountNumber)
  let [accountNm, setAccountName] = useState(accountName)
  const [companyName, setCompanyName] = useState(organizationName === null ? "": organizationName)
  const [companyDesc, setCompanyDesc] = useState(organizationDescription === null ? "": organizationDescription)
  const [companyLocation, setCompanyLocation] = useState(organizationAddress === null ? "" : organizationAddress)

  const updatePersonalProfile = () =>{
    setPersonalDisabled(true)
    const creds = {
      firstName: first,
      lastName: last
    }
    updatePersonal(creds)

    setTimeout(()=>{
      setPersonalDisabled(false)
    }, 3000)
  }


  const handleSave = () =>{
    setShowSave(false)

    if(initialCompanyTab === 1){
      if(selectedType.length > 0){
        setCompanyDisabled(true)
        const creds = {
          name : companyName,
          description: companyDesc,
          address: companyLocation,
          vendorTypes: selectedType,
        }
        updateCompany(creds)

        setTimeout(()=>{  
          setCompanyDisabled(false)
        }, 3000)
      }
      else{
        cogoToast.warn("You need to be at least a supplier of a product!")
      }
    }
    else{
      if(accountNm === ""){
        cogoToast.info("Please fill the required fields")
      }
      else if(accountNb === ""){
        cogoToast.info("Please fill the required fields")
      }
      else{
        setBankDisabled(true)
        const res = {
          accountName: accountNm,
          accountNumber: accountNb,
          bank: pickedBank
        }
        updateBank(res)
        console.log(res)
  
        setTimeout(()=>{
          setBankDisabled(false)
        },3000)
      }
    
    }

  }


  const handleSubmit = async (values) =>{
    await changePassword(values)

    setTimeout(()=>{
      navigate("/")
    }, 3000)
  }
 


  return (
    <>
      <Sidebar>
  
        </Sidebar>

      <PopComp show={show} showModal={showModal}>
           <div style={{display: 'flex', justifyContent: 'center'}}>
              <div className='logout_icon_div'>
                  <img src={info} alt="discard"/>
              </div>
            </div>

          <div className='text-center'>
              <h6 className='pop_title'>Discard Changes</h6>
              <p className='pop_subtitle'>Are you sure you want to discard your changes without saving?</p>
          </div>

          <div className='logout-cta'>
            <button className='btn cancel-btn'  onClick={() => handleClose()}>Cancel</button>
            <button className='btn btn-action' onClick={handleDiscard}>Discard</button>
          </div>
        </PopComp>

        <PopComp show={showSave} showModal={showSaveModal}>
           <div style={{display: 'flex', justifyContent: 'center'}}>
              <div className='save_icon_div'>
                  <img src={save_icon} alt="save"/>
              </div>
            </div>

          <div className='text-center'>
              <h6 className='pop_title'>Save Changes</h6>
              <p className='pop_subtitle'>Are you sure you want to save your changes</p>
          </div>

          <div className='logout-cta'>
            <button className='btn cancel-btn'  onClick={() => handleSaveClose()}>No</button>
            <button className='btn btn_yes' onClick={() => handleSave()}>Yes</button>
          </div>
        </PopComp>


        



      <div className="usermain">
        <div className="contain">

            <div className={styles.profile_title}>
                <p className="titlehead">Profile</p>
            </div>

          <div className={styles.profile_tab_div} >
            <ul className={styles.profile_tab_nav}>
              {tabLayout}
            </ul>
          </div>

          {
            initialTab === 1 ?

              (<div className='row mt-4'>
                <div className='col-md-4 col-lg-3'>
                  <div className={styles.company_firsthalf_div}>
                    <div className={styles.company_img_div}>

                        <div className={styles.default_company_img_div}>
                           <img src={store} className={styles.default_img} alt="user"/>
                        </div>

                        {/* <img src={profileImg} className='img-fluid' alt="profile" /> */}

                      <div>
                        <p className={styles.company_name}>{companyName}</p>
                      </div>
                      <div>
                        <p className={styles.company_date}>Created <Moment format="Do, MMM YYYY">{dateJoined}</Moment></p>
                      </div>
                    </div>

                    <div className='mt-4'>
                      {companyTabLayout}
                    </div>

                  </div>

                </div>

                <div className='col-md-8 col-lg-9 mt-md-0 mt-4'>

                  {
                    initialCompanyTab === 1 &&
                    <div className={styles.company_secondhalf_div}>
                    <div className={styles.company_info_div}>
                      <div>
                        <h6 className={styles.company_info_title}>Account Information</h6>
                        <p className={styles.company_info_subtitle}>This information here would be displayed publicly for your customers to see</p>
                      </div>
                      {/* <div>
                        <button className={`btn ${styles.company_edit_btn}`}>Edit Info</button>
                      </div> */}
                    </div>

                    {/* account info details form */}
                    <div className={`mt-4 ${styles.company_form_container}`}>
                      <div className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="name">Company Name</label>
                        <input
                          className={`${styles.input_style}`}
                          type="text"
                          placeholder="Afrimash"
                          id="name"
                          onChange={e => setCompanyName(e.target.value)}
                          value={companyName}
                        />
                      </div>

                      <div className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="email">Company's Email Address</label>
                        <input
                          className={`${styles.input_style}`}
                          type="text"
                          placeholder="example@email.com"
                          id="email"
                          onChange={e => setEmail(e.target.value)}
                          value={email}
                          
                        />
                      </div>


                      <div className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="phone">Company's Phone Number</label>
                        <input
                          className={`${styles.input_style}`}
                          type="text"
                          placeholder="0800-000-0000"
                          id="phone"
                          value={phone_number}
                          disabled
                        />
                      </div>


                      <div className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="description">Company Description</label>
                        <textarea
                          className={`${styles.textarea_style}`}
                          type="text"
                          rows="6"
                          placeholder="Enter your company description here"
                          id="description"
                          onChange={e => setCompanyDesc(e.target.value)}
                          value={companyDesc}
                          maxLength="500"
                        />
                      </div>

                      <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="name">What products do you supply?</label>
                      <Select 
                        isMulti
                        onChange={handleSupplier}
                        classNamePrefix="select_control"
                        className="select_style"
                        defaultValue={vendorTypes}
                        options={types}
                        getOptionLabel={option => option.title}
                        getOptionValue={option => option.id}
                       />
                    </div>


                      <div className={` ${styles.input_container}`}>
                        <label htmlFor="address">Company Address</label>
                        <input
                          className={`${styles.input_style}`}
                          type="text"
                          placeholder="Enter your company address here"
                          id="address"
                          onChange={e => setCompanyLocation(e.target.value)}
                          value={companyLocation}
                        />
                      </div>

                    </div>
                    {/* btn to save */}
                    <div className={styles.company_cta_div}>
                      <div>
                        <button onClick={handleDiscardModal} className={`btn ${styles.company_cancel_btn}`}>Cancel</button>
                      </div>
                      <div>
                        <button
                         disabled={isCompanyDisabled}
                        onClick={handleSaveModal}
                         className={`btn ${styles.company_save_btn}`}>Save</button>
                      </div>
                    </div>
                  </div>
                  }

                  
              {
                    initialCompanyTab === 2 &&
                    <div className={styles.comingsoon_div}>
                      <div className={styles.company_info_div}>
                      <div>
                        <h6 className={`mb-3 ${styles.company_info_title}`}>Members(0)</h6>
                      </div>
                    </div>

                    <div className='text-center mb-5' style={{marginTop: '60px'}}>
                          <div className={styles.coming_soon_div}>
                            <img src={egg} alt='egg' className='img-fluid' />
                            </div>
                          <p className={styles.coming_soon_title}>Coming Soon</p>
                    </div>
                  </div>
                  }

                  {
                    initialCompanyTab === 3 &&
                    <div className={styles.company_secondhalf_div}>
                      <div className={styles.company_info_div}>
                        <div>
                          <h6 className={`${styles.company_info_title}`}>Bank Details</h6>
                          <p className={styles.company_info_subtitle}>This information will not be displayed publicly.</p>

                        </div>
                      
                      </div>

                      <div className={`mt-4 ${styles.company_form_container}`}>

                      <div className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="name">Bank</label>
                        <Select 
                          onChange={handleBank}
                          classNamePrefix="select_control"
                          className="select_style"
                          defaultValue={selectedBank}
                          options={banks}
                          getOptionLabel={option => option.display_name}
                          getOptionValue={option => option.id}
                        />
                      </div>



                        <div className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="accountNb">Account Number</label>
                          <input
                            className={`${styles.input_style}`}
                            type="text"
                            placeholder="0100000000"
                            id="accountNb"
                            onChange={e => setAccountNumber(e.target.value)}
                            value={accountNb}
                          />
                        </div>

                        <div className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="accountNm">Account Name</label>
                          <input
                            className={`${styles.input_style}`}
                            type="text"
                            placeholder="John Doe"
                            id="accountNm"
                            onChange={e => setAccountName(e.target.value)}
                            value={accountNm}      
                        />
                      </div>                      
                    </div>

                     {/* btn to save */}
                     <div className={styles.company_cta_div}>
                      <div>
                        <button onClick={handleDiscardModal} className={`btn ${styles.company_cancel_btn}`}>Cancel</button>
                      </div>
                      <div>
                        <button
                         disabled={isBankDisabled}
                        onClick={handleSaveModal}
                         className={`btn ${styles.company_save_btn}`}>Save</button>
                      </div>
                    </div>


                   
                  </div>
                  }

                  
                

                </div>

              </div>
              )
              :
              (
                <div className='row mt-4'>
                  <div className='col-md-4 col-lg-3'>
                    <div className={styles.company_firsthalf_div}>
                      <div className={styles.company_img_div}>

                      <div className={styles.default_company_img_div}>
                           <img src={user_active} className={styles.default_img} alt="user"/>
                      </div>
                      
                        {/* <img src={profileImg} className='img-fluid' alt="profile" /> */}

                        <div>
                          <p className={styles.company_name}>{first}</p>
                        </div>
                        <div>
                          <p className={styles.company_date}>Created <Moment format="Do, MMM YYYY">{dateJoined}</Moment></p>
                        </div>
                      </div>

                      <div className='mt-4'>
                        {personalTabLayout}
                      </div>

                    </div>

                  </div>
                  <div className='col-md-8 col-lg-9 mt-md-0 mt-4'>

                    {initialPersonalTab === 1 && 
                     <div className={styles.company_secondhalf_div}>
                        <div className={styles.company_info_div}>
                          <div>
                            <h6 className={styles.company_info_title}>Account Information</h6>
                            <p className={styles.company_info_subtitle}>This information here would be displayed publicly for your costumers to see</p>
                          </div>
                          
                        </div>

                     {/* account info details form */}
                     <div className={`mt-4 ${styles.company_form_container}`}>
                       <div className={`mb-3 ${styles.input_container}`}>
                         <label htmlFor="firstname">First Name</label>
                         <input
                           className={`${styles.input_style}`}
                           type="text"
                           placeholder="Adeleke"
                           id="firstname"
                           onChange={e => setFirst(e.target.value)}
                           value={first}
                         />
                       </div>

                       <div className={`mb-3 ${styles.input_container}`}>
                         <label htmlFor="lastname">Last Name</label>
                         <input
                           className={`${styles.input_style}`}
                           type="text"
                           placeholder="Johnson"
                           id="lastname"
                           onChange={e => setLast(e.target.value)}
                           value={last}
                         />
                       </div>

                     </div>
                     {/* btn to save */}
                     <div className={styles.company_cta_div}>
                       <div>
                         <button onClick={handleDiscardModal} className={`btn ${styles.company_cancel_btn}`}>Cancel</button>
                       </div>
                       <div>
                         <button 
                         disabled={isPersonalDisabled}
                         onClick={updatePersonalProfile} className={`btn ${styles.company_save_btn}`}>Save</button>
                       </div>
                     </div>
                   </div>
                    }

                    {
                      initialPersonalTab === 2 &&
                      <Formik
                      onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                      }
                      validationSchema={ChangePasswordValidator}
                      initialValues={{
                        newpassword: "",
                        password: "",
                        confirm_password: "",
                      }}
                    >
                      {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                      <div className={styles.company_secondhalf_div}>
                        <div className={styles.company_info_div}>
                          <div>
                            <h6 className={styles.company_info_title}>Change Password</h6>
                            <p className={styles.company_info_subtitle}>Kindly change your password here</p>
                          </div>   
                        </div>
 
                      {/* account info details form */}
                      <div className={`mt-4 ${styles.company_form_container}`}>
                        <div className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="password">Old Password</label>
                          <input
                            className={`${styles.input_style}`}
                            type="password"
                            placeholder="********"
                            id="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                          />
                           <small style={{ color: "#dc3545" }}>
                            {touched.password && errors.password}
                          </small>
                        </div>
 
                        <div className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="newpassword">New Password</label>
                          <input
                            className={`${styles.input_style}`}
                            type="password"
                            placeholder="*********"
                            id="newpassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.newpassword}
                          />
                            <small style={{ color: "#dc3545" }}>
                            {touched.newpassword && errors.newpassword}
                          </small>
                        </div>

                        <div className={` ${styles.input_container}`}>
                          <label htmlFor="confirm_password">Confirm Password</label>
                          <input
                            className={`${styles.input_style}`}
                            type="password"
                            placeholder="*********"
                            id="confirm_password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.confirm_password}
                          />
                           <small style={{ color: "#dc3545" }}>
                            {touched.confirm_password &&
                              errors.confirm_password}
                          </small>
                        </div>
 
                      </div>
                      {/* btn to save */}
                      <div className={styles.company_cta_div}>
                        <div>
                          <button
                          type='submit'
                          disabled={isSubmitting}
                           className={`btn ${styles.company_save_btn}`}>Save</button>
                        </div>
                      </div>
                    </div>
                    </Form>
                  )}
                </Formik>
                    }
                   

                  </div>
                </div>
               
              )
          }

        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) =>{
  return{
    firstname: state.auth.profile.first_name,
    lastname: state.auth.profile.last_name,
    companyEmail: state.auth.profile.email,
    phoneNumber: state.auth.profile.username,
    dateJoined: state.auth.profile.date_joined,
    organizationName: state.auth.organizationName,
    organizationAddress: state.auth.organizationAddress,
    organizationDescription: state.auth.organizationDescription,
    vendorTypes: state.auth.vendorTypes,
    supplierType: state.auth.supplierType,
    banks: state.auth.banks,
    accountNumber: state.auth.account_number,
    accountName: state.auth.account_name,
    selectedBankId: state.auth.selectedBankId,
    selectedBank: state.auth.selectedBank
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
    updatePersonal : (creds) => dispatch(updatePersonalProfile(creds)),
    updateCompany : (creds) => dispatch(updateCompanyInfo(creds)),
    updateBank : (creds) => dispatch(updateBankInfo(creds)),
    changePassword : (creds) => dispatch(ChangePassword(creds)),
    fetchBanks : () => dispatch(getBanks()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);