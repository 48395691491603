import React, { useEffect } from 'react';
import Sidebar from '../../components/Sidebar/sidebar';
import back_arrow from "../../assets/icons/back_arrow.svg";
import {Link, useParams, useNavigate} from 'react-router-dom'
import styles from "./products.module.css";
import product_icon from "../../assets/icons/products_green_icon.svg";
import order_icon from "../../assets/icons/order_green_icon.svg";
// import product_item from "../../assets/images/maize.png";
import BarChart from '../../components/Charts/Barchart';
// import more_icon from "../../assets/icons/more_icon.svg";
import input_icon from "../../assets/icons/input_icon.svg"; 
import mechanization_icon from "../../assets/icons/mechanization_icon.svg";
import insurance_icon from "../../assets/icons/insurance_icon.svg";
import advisory_icon from "../../assets/icons/advisory_icon.svg";
import {connect} from 'react-redux'
import { getProductById, getProductOrders } from '../../store/actions/products';
import Moment from "react-moment";

const ProductDetailsPage = ({fetchProduct, product, fetchProductOrder, totalProductOrder, productOrder, completed_order, incompleted_order, revenue, loading}) => {

    const navigate = useNavigate()


    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const values = useParams();

    useEffect(()=>{
        let {type, id} = values
        fetchProduct(type, id)
        fetchProductOrder(type, id)
    }, [values, fetchProduct, fetchProductOrder])

    const handleImageShow = (val) =>{
        if(val === 'crop'){
            return input_icon
          }
          if(val === 'livestock'){
            return input_icon
          }
          if(val === 'mechanization'){
            return mechanization_icon
          }
          if(val === 'advisory'){
            return advisory_icon
          }
          if(val === 'insurance'){
            return insurance_icon
          }
    }

    const handleImagePreview = (image,val) =>{
        if(image.length === 0){
            if(val === 'crop'){
                return input_icon
              }
              if(val === 'livestock'){
                return input_icon
              }
              if(val === 'mechanization'){
                return mechanization_icon
              }
              if(val === 'advisory'){
                return advisory_icon
              }
              if(val === 'insurance'){
                return insurance_icon
              }    
        }
        else{
            return image[0]
        }
    }

    const handleEdit = () =>{
        navigate(`/product/${values.type}/${values.id}`)
    }

    const handleOrder = (type,id) =>{
        navigate(`/order/${type}/${id}`)
    }

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "DELIVERED":
            result = styles.delivery_status;
            break;
          case "PENDING":
            result = styles.pending_status;
            break;
        case "PROCESSING":
            result = styles.pending_status;
            break;
        case "DISPATCHED":
            result = styles.intransit_status;
            break;
          case "CANCELLED":
            result = styles.cancelled_status;
            break;
          default:
            result = styles.cancelled_status;
            break;
        }
        return result;
      };

      const handleCapitalize = (str) =>{
        let value = str.toLowerCase()
        return value.charAt(0).toUpperCase() + value.slice(1);
      }

      const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
      }
    

    return ( 
        <>
        <Sidebar>
        </Sidebar>
        <div className="usermain">
            <div className="contain">


        <div className="back_arrow_div">
              <Link to="/products" className="back_arrow_icon">
                    <img src={back_arrow} alt="back" />
              </Link>
              <div>
                <span>Back</span>
              </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
               <p className="titlehead">Product Details</p>

               <div> 
                 <button onClick={handleEdit} className='btn btn_edit'>Edit Product</button>
               </div>
         </div>


            
         <div className={styles.details_card}>

                    <div className={styles.details_item_div}>

                         {
                           product.length && product[0].images !== null  ? <img src={handleImagePreview(product[0].images, values.type)} alt='product' className={styles.product_img} />  
                            :
                            <div className={styles.default_icon_div}>
                                <img src={handleImageShow(values.type)} className={styles.product_default_icon} alt="default" />
                            </div>
                          }                 
                       <div>
                           <h6 className={styles.details_item_name}>{product && product.length ? product[0].name : "Product Name"}</h6>
                           <p className={styles.details_item_desc}>{product.length && product[0].description ? product[0].description : "No description provided yet!"}</p>
                       </div>
                    </div>

                    <div className={styles.details_item_info_div}>
                        <p className={styles.details_item_info_title}>Product Type</p>
                        <h6 className={styles.details_item_info_val}>{capitalizeFirstLetter(values.type)}</h6>
                    </div>
                    <div className={styles.details_item_info_div}>
                        <p className={styles.details_item_info_title}>Size</p>
                        <h6 className={styles.details_item_info_val}>{product.length && product[0].product_size ? product[0].product_size : "0"}{product.length && product[0].measurement ? product[0].measurement : ""}</h6>
                    </div>
                    <div className={styles.details_item_info_div}>
                        <p className={styles.details_item_info_title}>Cost Price</p>
                        <h6 className={styles.details_item_info_val}>₦{product.length && product[0].cost_price ? numberWithCommas(product[0].cost_price) : "0"}</h6>
                    </div>
                    <div className={styles.details_item_info_div}>
                        <p className={styles.details_item_info_title}>Discount Price</p>
                        <h6 className={styles.details_item_info_val}>₦{product.length && product[0].discount_price ? numberWithCommas(product[0].discount_price) : "0"}</h6>
                    </div>
                    <div className={styles.details_item_info_div}>
                        <p className={styles.details_item_info_title}>Visibility</p>
                        <h6 className={`mt-3 ${styles.details_item_info_val}`}>
                                {
                              product.length && product[0].is_visible ?
                                <span className={styles.table_item_live_badge}>Live</span>
                                :
                                <span className={styles.table_item_inactive_badge}>Inactive</span>
                            }
                        </h6>
                    </div>

                </div>

                <div className={`row ${styles.details_layout_div}`}>
                    <div className="col-lg-7">
                    <div className="row">
                        <div className="col-lg-6">
                        <div className={styles.details_revenue_card}>
                            <div className={styles.details_revenue_layout}>
                            <div>
                                <h4 className={styles.details_revenue_title}>{revenue ? numberWithCommas(revenue) : 0}</h4>
                                <p className={styles.details_revenue_subtitle}>Total Revenue</p>
                            </div>
                            <div className={styles.details_revenue_icon_div}>
                                <h2>₦</h2>
                            </div>
                            </div>
                        </div>
                        </div>

                        {/* second card */}
                        <div className="col-lg-6 mt-md-0 mt-4">
                        <div className={styles.details_product_card}>
                            <div className={styles.details_product_layout}>
                            <div>
                                <h4 className={styles.details_product_title}>{product.length && product[0].units ? numberWithCommas(product[0].units) : "0"}</h4>
                                <p className={styles.details_product_subtitle}>
                                Units in Stock
                                </p>
                            </div>
                            <div className={styles.details_product_icon_div}>
                                <img src={product_icon} alt="product" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="col-lg-5">
                    <div className="row">
                        {/* third card */}
                        <div className="col-lg-12 mt-md-0 mt-4">
                        <div className={styles.details_order_card}>
                            <div className={styles.details_order_layout}>
                            <div>
                                <h4 className={styles.details_order_title}>{totalProductOrder ? totalProductOrder : 0}</h4>
                                <p className={styles.details_order_subtitle}>Total Orders</p>
                            </div>
                            <div>
                                <h6 className={styles.details_completed_order}>
                                Completed Orders
                                </h6>
                                <p className={styles.details_completed_val}>{completed_order ? completed_order : 0}</p>

                                <h6 className={styles.details_incomplete_order}>
                                Incompleted Orders
                                </h6>
                                <p className={styles.details_incomplete_val}>{incompleted_order ? incompleted_order : 0}</p>
                            </div>
                            <div className={styles.details_order_icon_div}>
                                <img src={order_icon} alt="order" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                {/* revenue chart */}
                <BarChart type={values.type} id={values.id}/>

                {/* order */}
                <div className={`${styles.details_order_title}`}>
                    <h6>Orders</h6>
                </div>

                {/* orders table layout */}
                <div className={`mb-4 ${styles.details_orders_tables_div}`}>
                 <table>
                    <thead>
                        <tr>
                        <th>Order ID</th>
                        <th>Delivery Address</th>
                        <th>No Of Items</th>
                        <th>Order Date</th>
                        <th>Amount</th>
                        <th>Order Status</th>
                        </tr>
                    </thead>
                    <tbody className={styles.details_orders_tbody}>

                       {
                        loading ?
                        ( <div className={styles.products_loader_div}>
                            <p>Loading...</p>
                        </div>)
                        :
                        (
                        productOrder.length > 0 ? productOrder.map((item)=>(
                    <tr key={item.id} onClick={() => handleOrder(values.type,item.id)}>
                         <td>
                                <div className={styles.details_orders_table_name}>{item.id}</div>
                            </td>
                          
                          <td>
                        <span>  
                        Oyo State
                        </span>
                        </td>
                            <td>1</td>
                            <td><Moment format="Do, MMM YYYY">{item.created}</Moment></td>
                            <td>
                            {
                                item.total_cost_price
                            }
                            </td>
                            <td>
                            <span className={getStatusColor(item.delivery_status)}>{handleCapitalize(item.delivery_status)}</span>
                                </td>
                            </tr>
                            ))
                            :
                       <div className={styles.products_loader_div}>
                            <p>There are no records to display</p>
                        </div>
                    )
                    }
                       
                    </tbody>
                </table>
                </div>

                {
                loading ?
                    ""
                    :
                    productOrder.length > 0 && productOrder ?
                    <Link to="/orders" className={styles.details_orders_see_more}>
                        See More
                    </Link>
                    :
                    ""
            }

                

            </div>
        </div>
        </>
     );
}



const mapStateToProps = (state) =>{
    return{
      product: state.product.product,
      productOrder: state.product.productOrder,
      totalProductOrder: state.product.totalProductOrder,
      completed_order: state.product.completed_order,
      incompleted_order: state.product.incompleted_order,
      revenue: state.product.revenue,
      loading: state.product.orderloader
    }
  }
  
  const mapDispatchToProps = (dispatch) =>{
    return{
      fetchProduct : (type, id) => dispatch(getProductById(type, id)),
      fetchProductOrder : (type, id) => dispatch(getProductOrders(type, id)),
    }
  }

 
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage);