// import cogoToast from "cogo-toast";
import { GetApi } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


// Get total products
export const getTotalProducts = () => {
    return async (dispatch, getState) => {
       let token = getState().auth.token
      try {
        const res = await GetApi(`products`, getToken() || token);
        if (res.status === 200) {
          dispatch({ type: "TotalProducts", data: res.data.totalProducts });
        }
        if (res.status === 400) {
          dispatch({ type: "Products_Error", err: res.data });
        }
      } catch (err) {
        console.log(err);
      }
    };
};

// Get total Orders
export const getTotalOrders = () => {
  return async (dispatch, getState) => {
     let token = getState().auth.token
    try {
      const res = await GetApi(`orders`, getToken() || token);
      if (res.status === 200) {
        dispatch({ type: "TotalOrders", data: res.data.totalOrders });
      }
      if (res.status === 400) {
        dispatch({ type: "Orders_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// get overview details/info
export const getOverviewInfo = () => {
  return async (dispatch, getState) => {
     let token = getState().auth.token
    try {
      const res = await GetApi(`overview`, getToken() || token);
      if (res.status === 200) {
        dispatch({ type: "Overview", data: res.data.data });
      }
      if (res.status === 400) {
        dispatch({ type: "Overview_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// get overview top5 products
export const getOverviewTopFive = (val) => {
  return async (dispatch, getState) => {
     let token = getState().auth.token
    try {
      const res = await GetApi(`overview/gettopfive?period=${val}`, getToken() || token);
      if (res.status === 200) {
        dispatch({ type: "topFive", data: res.data.rankedTopProducts });
      }
      if (res.status === 400) {
        dispatch({ type: "topFive_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// get order types by date filter
export const getOrderCategory = (val) => {
  return async (dispatch, getState) => {
     let token = getState().auth.token
    try {
      const res = await GetApi(`overview/getorderchart?period=${val}`, getToken() || token);
      if (res.status === 200) {
        dispatch({ type: "OrderTypesFilter", data: res.data.pieChart });
      }
      if (res.status === 400) {
        dispatch({ type: "OrderTypes_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};