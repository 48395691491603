import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// private routes
import UserRoute from "./components/PrivateRoutes/userRoute";

import OverviewPage from "./pages/Overview/overview";
import ProductsPage from "./pages/Products/products";
import AddProductPage from "./pages/Products/addProduct";
import EditProductPage from "./pages/Products/editProduct";
import ProductDetailsPage from "./pages/Products/productDetails";
import OrdersPage from "./pages/Orders/orders";
import ProfilePage from "./pages/Profile/profile";
import LoginPage from "./pages/Login/login";
import RegisterPage from "./pages/Register/register";
import ForgotPassword from "./pages/ForgotPassword/forgotPassword";
import ResetPasswordPage from "./pages/ForgotPassword/ResetPassword";
import NotFoundPage from "./pages/NotFound/NotFound";
import EmailVerificationPage from "./pages/EmailVerification/emailVerification";
import OrderDetailsPage from "./pages/Orders/orderDetails";
import TransactionsPage from "./pages/Transactions/transactions";
import AllTransactionsPage from "./pages/Transactions/AllTransactions";
import CompleteRegistrationPage from "./pages/CompleteRegistration/Complete";
import ForgotEmailCodePage from "./pages/ForgotPassword/forgotEmailCodeConfirm";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route path="/overview" element={
          <UserRoute>
            <OverviewPage/>
          </UserRoute>
        } />
        <Route path="/products" element={
          <UserRoute>
            <ProductsPage />
          </UserRoute>
        } />
        <Route path="/product/add" element={
          <UserRoute>
            <AddProductPage />
          </UserRoute>
        } />
        <Route path="/product/:type/:id" element={
          <UserRoute>
            <EditProductPage />
          </UserRoute>
        } />
        <Route path="/product/details/:type/:id" element={
          <UserRoute>
            <ProductDetailsPage />
          </UserRoute>
        } />
        <Route path="/orders" element={
          <UserRoute>
             <OrdersPage />
          </UserRoute>
          } />
        <Route path="/order/:type/:id" element={
           <UserRoute>
              <OrderDetailsPage />
           </UserRoute>
        } />
        <Route path="/transactions" 
            element={
              <UserRoute>
                <TransactionsPage />
             </UserRoute>
            }
             />
        <Route path="/transactions/more" element={
               <UserRoute>
                <AllTransactionsPage/>
                </UserRoute>
                } />
        <Route path="/profile" element={
          <UserRoute>
            <ProfilePage />
          </UserRoute>
        } />
        <Route path="/register" element={<RegisterPage/>}/>
        <Route path="/complete-registration" element={<CompleteRegistrationPage/>} /> 
        <Route path="/verify-email" element={<EmailVerificationPage/>}/>
        <Route path="/forgotpassword" element={<ForgotPassword/>}/>
        <Route path="/verifycode" element={<ForgotEmailCodePage/>}/>
        <Route path="/resetpassword" element={<ResetPasswordPage/>}/>
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    </Router>
  );
}

export default App;
