import * as Yup from "yup";

export const loginValidator = Yup.object({
  phoneNumber:  Yup.string()
    .min(11, "Phone number cannot be less than 11 digits")
    .max(11, "Exceeded characters for phone number")
    .required("Phone Number is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  password: Yup.string()
    .required("Password is required"),
});

export const forgotPasswordValidator = Yup.object({
  phoneNumber:  Yup.string()
  .min(11, "Phone number cannot be less than 11 digits")
  .max(11, "Exceeded characters for phone number")
  .required("Phone Number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
});

export const ChangePasswordValidator = Yup.object({
  password: Yup.string().required("Password is required"),
  newpassword: Yup.string()
  .min(6, "Password cannot be less than 6 characters")
  .required("Enter a new password"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
});

export const registerValidator = Yup.object({
    companyName: Yup.string().required("Company's name is required"),
    phoneNumber:  Yup.string()
    .min(11, "Phone number cannot be less than 11 digits")
    .max(11, "Exceeded characters for phone number")
    .required("Phone Number is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
    email: Yup.string()
      .email("Enter a valid email"),
    firstname: Yup.string().required("Firstname is required"),
    lastname: Yup.string().required("Lastname is required"),
    password: Yup.string()
    .min(6, "Password cannot be less than 6 characters")
    .required("Password is required") 
});

export const completeRegValidator = Yup.object({
  BVN:  Yup.string()
  .min(11, "BVN number cannot be less than 11 digits")
  .max(11, "Exceeded characters for BVN number")
  .required("BVN number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid BVN number"),
  accountNumber: Yup.string()
    .min(10, 'Account number cannot be less than 10 digits')
    .max(10, 'Exceeded characters for Account Number')
    .required("Account Number is required").matches(
    /^-?[0-9]+(.[0-9]{1-7})?$/,
    "Enter a valid Account Number"),
  accountName: Yup.string().required("Enter your account name"),
  // pin: Yup.string()
  //   .min(4, "Pin cannot be less than 4 characters")
  //   .max(4, "Pin cannot be more than 4 characters")
  //   .required("Pin is required")
  //   .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  // confirm_pin: Yup.string()
  //   .required("Pin must match")
  //   .oneOf([Yup.ref("pin"), null], "Pin must match"),
});



export const emailVerifyValidator = Yup.object({
  code: Yup.string().min(4, "Code cannot be less than 4 digits")
  .max(4, "Exceeded characters for code")
  .required("Code is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid code"),
});


export const createPasswordValidator = Yup.object({
  password: Yup.string()
  .min(6, "Password cannot be less than 6 characters")
  .required("Password is required")
});

export const resetPasswordValidator = Yup.object({
  password: Yup.string()
  .min(6, "Password cannot be less than 6 characters")
  .required("Password is required"),
  confirm_password: Yup.string()
  .required("Passwords must match")
  .oneOf([Yup.ref("password"), null], "Passwords must match")
});
export const addProductValidator = Yup.object({
  name: Yup.string().required("Product name is required"),
  description: Yup.string().required("Product description is required"),
  costPrice: Yup.string().required("Cost Price is required")
  .matches(/^(?![0]$)\d+(\.\d+)*$/, "Enter a valid price"),
  discountPrice: Yup.string()
  .matches(/^\d+(\.\d+)*$/, "Enter a valid price"),
  units: Yup.string().matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid unit"),
  productSize: Yup.string().required("Product Size is required").matches(/^\d+(\.\d+)*$/, "Enter a valid size"),
  minHec: Yup.string().min(1, "Hectares cannot be less than 1").matches(/^[1-9][0-9]*$/, "Enter a valid value"),
});

  