import cogoToast from "cogo-toast";
import { DeleteApi, GetApi, PostApi, PutApi } from "../request";
import Moment from "moment";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


// Get all products based on type
export const getProductsByType = (val, page) => {
    return async (dispatch, getState) => {
      dispatch({ type: "startLoad" });
      let token = getState().auth.token
      try {
        const res = await GetApi(`products?type=${val}&limit=10&page=${page}`, getToken() || token);
        if (res.status === 200) {
          let result = res.data.data.rows
          let count = res.data.data.count
          let resp;
          // check for type and map arrays for it
          switch(val){
            case "crop":
              resp = result.map(val => (
                { 
                  ...val,
                  categoryName: val.product_category.name,
                }));
              break;
            case "livestock":
              resp = result.map(val => (
                { 
                  ...val,
                  categoryName: val.livestock_input_category.name,
                }));
              break;
            case "advisory":
              resp = result.map(val => (
                { 
                  ...val,
                  categoryName: val.product_category.name,
                }));
                break;
            case "mechanization":
              resp = result.map(val => (
                { 
                  ...val,
                  categoryName: val.mechanization_service.name,
                }));
                break;
            case "insurance":
              resp = result.map(val => (
                { 
                  ...val,
                  categoryName: val.product_category.name,
                }));
                break;
            default:
              break;
          }

          dispatch({ type: "PRODUCTS", data: {
            resp:resp,
            count: count
            } });

          dispatch({ type: "PRODUCTREPORT", data: res.data.data });
          dispatch({ type: "stopLoad" });
        }
        if (res.status === 400) {
          dispatch({ type: "Product_Error", err: res.data });
          dispatch({ type: "stopLoad" });
        }
      } catch (err) {
        console.log(err);
      }
    };
};

// search products based on category and name
export const searchProductByName = (category, val) => {
  return async (dispatch, getState) => {
    dispatch({ type: "startLoad" });
    let token = getState().auth.token
    try {
      const res = await GetApi(`products?type=${category}&search=${val}`, getToken() || token);
      if (res.status === 200) {
        let result = res.data.data.rows
        let count = res.data.data.count
        let resp;
        // check for type and map arrays for it
        switch(category){
          case "crop":
            resp = result.map(val => (
              { 
                ...val,
                categoryName: val.product_category.name,
              }));
            break;
          case "livestock":
            resp = result.map(val => (
              { 
                ...val,
                categoryName: val.livestock_input_category.name,
              }));
            break;
          case "advisory":
            resp = result.map(val => (
              { 
                ...val,
                categoryName: val.product_category.name,
              }));
              break;
          case "mechanization":
            resp = result.map(val => (
              { 
                ...val,
                categoryName: val.mechanization_service.name,
              }));
              break;
          case "insurance":
            resp = result.map(val => (
              { 
                ...val,
                categoryName: val.product_category.name,
              }));
              break;
          default:
            resp = []
            break;
        }

        dispatch({ type: "PRODUCTS", data: {
          resp:resp,
          count: count
          } });

        dispatch({ type: "PRODUCTREPORT", data: res.data.data });
        dispatch({ type: "stopLoad" });
      }
      if (res.status === 400) {
        dispatch({ type: "Product_Error", err: res.data });
        dispatch({ type: "stopLoad" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// Get a type based product by id
export const getProductById = (type, id) => {
  return async (dispatch, getState) => {
    dispatch({ type: "productLoader" });
    let token = getState().auth.token
    try {
      const res = await GetApi(`products/${type}/${id}`, getToken() || token);
      if (res.status === 200) {
        dispatch({ type: "PRODUCT", data: res.data.data });
        dispatch({ type: "productLoaderStop" });
      }
      if (res.status === 400) {
        dispatch({ type: "Product_Error", err: res.data });
        dispatch({ type: "productLoaderStop" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// get orders on a product type
export const getProductOrders = (type, id) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    dispatch({ type: "orderStartLoad" });
    try {
      const res = await GetApi(`product_orders/${type}/${id}?page=1&limit=10`, getToken() || token);
      if (res.status === 200) {
        console.log(res.data);
        dispatch({ type: "PRODUCTORDER", data: res.data.data });
        dispatch({ type: "orderStopLoad" });
      }
      if (res.status === 400) {
        dispatch({ type: "Product_Error", err: res.data });
        dispatch({ type: "orderStopLoad" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// get chart for revenue of a particular product
export const getProductRevenueChart = (type, id, duration) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    dispatch({ type: "chartLoad" });

    console.log(duration)
    let from_date;
    let to_date;

    switch(duration){
      case 'week':
        from_date = Moment().startOf('week').format('YYYY-MM-DDTHH:mm:ss');
        to_date = Moment().endOf('week').format('YYYY-MM-DDTHH:mm:ss');
       break;
      case 'month':
        from_date = Moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss');
        to_date = Moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss');
        break;
      case 'year':
        from_date = Moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss');
        to_date = Moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss');
        break;
      default:
        break;
    }

    try {
      const res = await GetApi(`products/${type}/${id}/revenue?from=${from_date}&to=${to_date}`, getToken() || token);
      if (res.status === 200) {
        console.log(res.data);
        dispatch({ type: "RevenueChart", data: res.data.revenue });
        dispatch({ type: "chartStop" });
      }
      if (res.status === 400) {
        dispatch({ type: "Chart_Error", err: res.data });
        dispatch({ type: "chartStop" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// delete a category based product
export const DeleteProduct = (category, id) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    try {
      const res = await DeleteApi(`products/${category}/${id}`, getToken() || token);
      if (res.status === 200) {
        cogoToast.success("Product deleted successfully!", {
          position: "bottom-right",
        });
      }
      if (res.status === 400) {
        cogoToast.error("Error while deleting product")
        dispatch({ type: "Product_Error", err: res.data });
      }
      if (res.status === 500) {
        cogoToast.error("Error while deleting product")
        dispatch({ type: "Product_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};



// Get all categories of a product type
export const getCategories = (category) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    try {
      const res = await GetApi(`categories?category=${category}`, getToken() || token);
      if (res.status === 200) {
        dispatch({ type: "Category", data: res.data.data });
      }
      if (res.status === 400) {
        dispatch({ type: "Category_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// get all sub categories based on a category
export const getSubCategories = (category,id) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    try {
      const res = await GetApi(`categories/subcategories/${category}/${id}`, getToken() || token);
      if (res.status === 200) {
        dispatch({ type: "Subcategory", data: res.data.data });
      }
      if (res.status === 400) {
        dispatch({ type: "Subcategory_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// get a product measurement based on category
export const getProductMeasurement = (category) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    try {
      const res = await GetApi(`products/measurement?type=${category}`, getToken() || token);
      if (res.status === 200) {
        dispatch({ type: "Measurement", data: res.data.data });
      }
      if (res.status === 400) {
        dispatch({ type: "Measurement_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


// Get all sub states
export const getStates = () => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    try {
      const res = await GetApi(`states/`, getToken() || token);
      if (res.status === 200) {
        dispatch({ type: "States", data: res.data.data });
      }
      if (res.status === 400) {
        dispatch({ type: "States_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

//   Add products functionality based on category
export const AddProduct = (type,val) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    var values;
    // check for type selected and decide what api to call
    switch(type){
      case "crop":
        values = {
          name: val.name,
          display_price: (0.1 * parseFloat(val.costPrice)) + (parseFloat(val.costPrice)),
          description: val.description,
          cost_price: parseFloat(val.costPrice),
          product_category_id: val.CategoryId,
          images: val.preview,
          units: parseFloat(val.units),
          discount_price: parseFloat(val.discountPrice),
          product_size: parseFloat(val.productSize),
          active_states: val.selectedStates,
          is_visible: val.checked,
          measurement: val.sizeName,
          product_type: type
        }
      break;
      case "livestock":
        values = {
          name: val.name,
          display_price: (0.1 * parseFloat(val.costPrice)) + (parseFloat(val.costPrice)),
          description: val.description,
          cost_price: parseFloat(val.costPrice),
          product_category_id: val.CategoryId,
          images: val.preview,
          units: parseFloat(val.units),
          discount_price: parseFloat(val.discountPrice),
          product_size: parseFloat(val.productSize),
          active_states: val.selectedStates,
          is_visible: val.checked,
          measurement: val.sizeName,
          product_type: type
        }
      break;
    case "advisory":
        values = {
          name: val.name,
          display_price: (0.1 * parseFloat(val.costPrice)) + (parseFloat(val.costPrice)),
          description: val.description,
          cost_price: parseFloat(val.costPrice),
          product_category_id: val.CategoryId,
          images: val.preview,
          units: parseFloat(val.units),
          discount_price: parseFloat(val.discountPrice),
          product_size: parseFloat(val.productSize),
          active_states: val.selectedStates,
          is_visible: val.checked,
          measurement: val.sizeName,
          product_type: type
        }
      break;
    case "insurance":
        values = {
          name: val.name,
          display_price: (0.1 * parseFloat(val.costPrice)) + (parseFloat(val.costPrice)),
          description: val.description,
          cost_price: parseFloat(val.costPrice),
          product_category_id: val.CategoryId,
          images: val.preview,
          units: parseFloat(val.units),
          discount_price: parseFloat(val.discountPrice),
          product_size: parseFloat(val.productSize),
          active_states: val.selectedStates,
          is_visible: val.checked,
          measurement: val.sizeName,
          product_type: type
        }
      break;
    case "mechanization":
      values = {
        name: val.name,
        display_price: (0.1 * parseFloat(val.costPrice)) + (parseFloat(val.costPrice)),
        description: val.description,
        cost_price: parseFloat(val.costPrice),
        minimum_hectares: val.minHec === "0" ? 1 : parseFloat(val.minHec),
        mechanization_service_id: val.CategoryId,
        images: val.preview,
        units: 0,
        discount_price: parseFloat(val.discountPrice),
        product_size: parseFloat(val.productSize),
        land_size: parseFloat(val.productSize),
        active_states: val.selectedStates,
        is_visible: val.checked,
        measurement: val.sizeName,
        product_type: type
      }
      break;
      default:
        break;
    }

    try {
      const res = await PostApi(`products?type=${type}`, { ...values }, getToken() || token, "application/json");
      if (res.status === 200) {
        cogoToast.success('Product added successfully!')
        dispatch({ type: "ADD_PRODUCT_SUCCESS" });
      }
      if(res.status === 400){
        cogoToast.error('Error while adding product !')
      }
      if(res.status === 500){
        cogoToast.error("Error while adding product")
      }
    } catch (err) {
      console.log(err)
    }
  };
};



 // clear add products status
 export const clearAddedProductsStatus = () =>{
  return dispatch =>{
      dispatch({type: 'clearAddedProducts'})
  }
}

//  update profucts functionality based on category
export const UpdateProduct = (type,id,val) => {
  return async (dispatch, getState) => {
    let token = getState().auth.token
    var values;
    // check for category selected and decide what api to call
    switch(type){
      case "crop":
        values = {
          name: val.name,
          display_price: (0.1 * parseFloat(val.costPrice)) + (parseFloat(val.costPrice)),
          description: val.description,
          cost_price: parseFloat(val.costPrice),
          product_category_id: val.CategoryId,
          images: val.preview,
          units: parseFloat(val.units),
          discount_price: parseFloat(val.discountPrice),
          product_size: parseFloat(val.productSize),
          active_states: val.selectedStates,
          is_visible: val.checked,
          measurement: val.sizeName,
          product_type: type
        }
      break; 
      case "livestock":
        values = {
          name: val.name,
          display_price: (0.1 * parseFloat(val.costPrice)) + (parseFloat(val.costPrice)),
          description: val.description,
          cost_price: parseFloat(val.costPrice),
          product_category_id: val.CategoryId,
          images: val.preview,
          units: parseFloat(val.units),
          discount_price: parseFloat(val.discountPrice),
          product_size: parseFloat(val.productSize),
          active_states: val.selectedStates,
          is_visible: val.checked,
          measurement: val.sizeName,
          product_type: type
        }
      break;
    case "advisory":
        values = {
          name: val.name,
          display_price: (0.1 * parseFloat(val.costPrice)) + (parseFloat(val.costPrice)),
          description: val.description,
          cost_price: parseFloat(val.costPrice),
          product_category_id: val.CategoryId,
          images: val.preview,
          units: parseFloat(val.units),
          discount_price: parseFloat(val.discountPrice),
          product_size: parseFloat(val.productSize),
          active_states: val.selectedStates,
          is_visible: val.checked,
          measurement: val.sizeName,
          product_type: type
        }
      break;
    case "insurance":
        values = {
          name: val.name,
          display_price: (0.1 * parseFloat(val.costPrice)) + (parseFloat(val.costPrice)),
          description: val.description,
          cost_price: parseFloat(val.costPrice),
          product_category_id: val.CategoryId,
          images: val.preview,
          units: parseFloat(val.units),
          discount_price: parseFloat(val.discountPrice),
          product_size: parseFloat(val.productSize),
          active_states: val.selectedStates,
          is_visible: val.checked,
          measurement: val.sizeName,
          product_type: type
        }
      break;
    case "mechanization":
      values = {
        name: val.name,
        display_price: (0.1 * parseFloat(val.costPrice)) + (parseFloat(val.costPrice)),
        description: val.description,
        cost_price: parseFloat(val.costPrice),
        minimum_hectares: val.minHec === "0" ? 1 : parseFloat(val.minHec),
        mechanization_service_id: val.CategoryId,
        images: val.preview,
        units: 0,
        discount_price: parseFloat(val.discountPrice),
        product_size: parseFloat(val.productSize),
        land_size: parseFloat(val.productSize),
        active_states: val.selectedStates,
        is_visible: val.checked,
        measurement: val.sizeName,
        product_type: type
      }
      break;
      default:
        break;
    }

    try {
      const res = await PutApi(`products/${type}/${id}`, { ...values }, getToken() || token, "application/json");
      if (res.status === 200) {
        cogoToast.success('Product updated successfully!')
      }
      if(res.status === 400){
        cogoToast.error('Error while updating product!')
      }
      if(res.status === 500){
        cogoToast.error("Error while updating product")
      }
    } catch (err) {
      console.log(err)
    }
  };
};




