import React,{useState, useEffect, useCallback, useMemo} from 'react';
import Sidebar from '../../components/Sidebar/sidebar';
import styles from "./products.module.css";
import download from "../../assets/icons/download.svg";
import search_icon from "../../assets/icons/search_icon.svg";
import more_icon from "../../assets/icons/more_icon.svg";
import add from "../../assets/icons/add.svg";
import { Link, useNavigate } from 'react-router-dom';
import PaginationComponent from '../../components/Pagination/Pagination';
import edit_icon from "../../assets/icons/edit_icon.svg";
import delete_icon from "../../assets/icons/delete_icon.svg";
import delete_i from "../../assets/icons/delete.svg";
import eye_icon from "../../assets/icons/eye_icon.svg";
import CsvDownload from 'react-json-to-csv'
import {connect} from 'react-redux'
import { DeleteProduct, getProductsByType, searchProductByName } from '../../store/actions/products';
import Moment from "react-moment";
import PopComp from '../../components/Modal/popups';
import input_icon from "../../assets/icons/input_icon.svg";
import mechanization_icon from "../../assets/icons/mechanization_icon.svg";
import insurance_icon from "../../assets/icons/insurance_icon.svg";
import advisory_icon from "../../assets/icons/advisory_icon.svg";
import warning_icon from "../../assets/icons/warning_icon.svg";
import {debounce} from 'lodash'
import { checkEmailVerification } from '../../store/actions/auth';

const ProductsPage = ({fetchProducts, searchProducts, deleteProduct, count, products, loading, isVerified, vendorTypes, reports, checkEmail}) => {

  const [isMoreOpen, setMoreOpen] = useState(null)
  const [typetag, setTypetag] = useState(vendorTypes[0].tag)
  const [deleteDisabled, setDeleteDisabled] = useState(false)
  const [productId, setProductId] = useState(null)

  const [show, setShow] = useState(false)

  const showModal = () =>{
    setShow(!show)
  }

  const handleClose = () =>{
    setShow(false)
  }

  const [categoryName] = useState(vendorTypes[0].tag)

  const navigate = useNavigate();


  let PageSize = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const getMoreItemsByOffset = (page, typetag) => {
    setCurrentPage(page)
    fetchProducts(typetag, page);
  }

   // make call to fetch all products by type active
   useEffect(() => {
    fetchProducts(categoryName, 1);
    checkEmail()
  }, [fetchProducts, categoryName, checkEmail]);

  const handleType = (val) =>{  
    const {id, tag} = val
    setTypetag(tag)
    // make call to fetch products based on the type selected and for page 1
    switch(id){
      case 1: 
      fetchProducts(tag, 1)
      break;
      case 2:
        fetchProducts(tag, 1)
        break;
        case 3: 
        fetchProducts(tag, 1)
        break;
        case 4:
          fetchProducts(tag, 1)
          break;
        case 5:
          fetchProducts(tag, 1)
          break;
        default:
          break;
    }
  } 

  const handleTableClick = (id) =>{
    navigate(`/product/details/${typetag}/${id}`)
  }

  const toggleMoreIcon = (id) =>{
    // setMoreOpen(!isMoreOpen)
    setMoreOpen(id === isMoreOpen ? null : id) 
  }

  const closeMoreIcon = useCallback(() => {
    setMoreOpen(null);
  }, []);
 
  useEffect(() => {
    if (isMoreOpen) {
      document.body.addEventListener("click", closeMoreIcon);

      return () => document.body.removeEventListener("click", closeMoreIcon);
    }
  }, [closeMoreIcon, isMoreOpen]);

  const handleEdit = (id) =>{
    navigate(`/product/${typetag}/${id}`)
  }

  const handleDeleteModal = (id) =>{
    setProductId(id)
    setShow(true)
    setMoreOpen(null)
  }

  const handleDelete = async () =>{
    setDeleteDisabled(true)
    await deleteProduct(typetag, productId)
    setTimeout(()=>{
     setCurrentPage(1)
     setDeleteDisabled(false)
     setShow(false)
     fetchProducts(typetag, 1)
    },3000)
  }

  const handleProductDetails = (id) =>{
    navigate(`/product/details/${typetag}/${id}`)
  }

  const handleImageShow = (val) =>{
      if(val === 'crop'){
        return input_icon
      }
      if(val === 'livestock'){
        return input_icon
      }
      if(val === 'mechanization'){
        return mechanization_icon
      }
      if(val === 'advisory'){
        return advisory_icon
      }
      if(val === 'insurance'){
        return insurance_icon
    } 
  }

  // handle search
  const handleSearch = useMemo(()=>
    debounce((cat,val)=>{
      searchProducts(cat,val)
    },750),
   [searchProducts]
  )

  const handleChange = useCallback((e)=>{ 
    let val = e.target.value
    handleSearch(typetag,val)
},[handleSearch,typetag])


const numberWithCommas = (x) => { 
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
}


  return ( 
    <>
      <Sidebar>
        
        </Sidebar>

      <PopComp show={show} showModal={showModal}>
           <div style={{display: 'flex', justifyContent: 'center'}}>
              <div className='logout_icon_div'>
                  <img src={delete_i} alt="save"/>
              </div>
            </div>

          <div className='text-center'>
              <h6 className='pop_title'>Delete Product</h6>
              <p className='pop_subtitle'>Are you sure you want to delete the product? Once deleted your customers won’t be able to see the product.</p>
          </div>

          <div className='logout-cta'>
            <button className='btn cancel-btn'  onClick={() => handleClose()}>Cancel</button>
            <button disabled={deleteDisabled} className='btn btn-action' onClick={handleDelete}>Delete</button>
          </div>
        </PopComp>

      <div className="usermain">
        <div className="contain">

        <div className={styles.gather_alert_div}>
              <p className="titlehead">Products</p>
         </div>

        {
            isVerified ? 
            ""
            :
          <div className={`alert mt-3 ${styles.gather_alert}`} >
            <img src={warning_icon} alt="icon" className='mr-2' /> You haven’t verified your email, kindly check your email for verification
          </div>
          }
      

          <div className={styles.products_left_div}>
            <div className={styles.product_left_search}>
              <div style={{position: 'relative'}}>
                <img src={search_icon} alt="search" className={styles.products_search_icon} />
                <input type="text" onChange={handleChange} placeholder='Search by name' className={styles.products_search_input} />
              </div>
            
            </div>
              <div className={styles.products_right_div}>
              {
                   products.length > 0 ?
                  <CsvDownload data={reports} 
                   className={styles.products_download_div} 
                   filename={`${typetag}.csv`}>
                      <img src={download} alt="download" />
                   </CsvDownload>
                   :
                   ""
                }
                <div>
                  <Link to="/product/add" className={`${styles.products_add_btn} btn`}>
                    <img src={add} alt="add" />
                    <span>Add Product</span>
                  </Link>
                </div>  
              </div>
          </div>

          {/* filter tabs */}
          <div className={styles.products_filter_div}>
            {vendorTypes.map((val) => (
                <li
                  key={val.id}
                  onClick={() => handleType(val)}
                >
                  <p 
                   className={typetag === val.tag ? `${styles.category_tab} ${styles.category_tab_active}` : `${styles.category_tab}`}
                  >{val.title} <span>{typetag === val.tag ? count : ''}</span></p>
                </li>
              ))}
           
          </div>

          {/* tables layout */}
          <div className={styles.tables_div}>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Date Added</th>
                  <th>Price</th>
                  <th>Size</th>
                  <th>Visibility</th>
                  <th>Units</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>

                {
                  loading ?
                  ( <div className={styles.products_loader_div}>
                    <p>Loading...</p>
                 </div>)
                 :
                 (
                  products.length > 0 ? products.map((item)=>(
                    <tr key={item.id}>
                       <td>
                        <div className={styles.tables_item_div}>
                          {
                           item.images !== null && item.images.length > 0 ? <img src={item.images[0]} onClick={() => handleTableClick(item.id)}  alt='items' className={styles.table_img} />
                            :
                            <div className={styles.table_prod_img_div}>
                              <img src={handleImageShow(typetag)} alt='items'  onClick={() => handleTableClick(item.id)}  className={styles.table_prod_img} />
                          </div>
                          }  
                          <div>                          
                             <span>{item.name}</span>
                             {/* <p className={`mt-1 ${styles.table_prod_status}`}>Live</p> */}
                          </div>
                        </div>
                      </td> 
                      <td>{item.product_category ? item.product_category.name : item.categoryName}</td>
                      <td><Moment format="Do, MMM YYYY">{item.created}</Moment></td>
                      <td>₦ {item.cost_price ? numberWithCommas(item.cost_price) : 0}</td>
                      <td>{typetag !== "mechanization" ? item.product_size : item.land_size} {item.measurement}</td>
                      <td>
                      {
                         item.is_visible ?
                        <span className={styles.table_item_live_badge}>Live</span>
                        :
                        <span className={styles.table_item_inactive_badge}>Inactive</span>
                      }
                      </td>
                      <td>{
                      item.units === 0 ?
                      <span className={styles.table_item_unit_badge}>Out of Stock</span>
                      :
                      item.units
                      }</td>
                      <td className={styles.action_div}>
                          <img className={styles.more_icon} src={more_icon} alt="more"  onClick={() => toggleMoreIcon(item.id)} />
                          <ul
                          className={
                            isMoreOpen === item.id
                              ? `${styles.action_div_dropdown_section} ${styles.action_div_dropdown_section_active}`
                              : styles.action_div_dropdown_section
                          }
                            >
                               <li className={styles.action_div_dropdown_list} onClick={() => handleProductDetails(item.id)}>
                              <span>
                                <img src={eye_icon} alt="edit" className={`img-fluid ${styles.edit_icon}`} />
                                
                              </span>
                              <span>View Product</span>
                            </li>
                            <li className={styles.action_div_dropdown_list} onClick={() => handleEdit(item.id)}>
                              <span>
                                <img src={edit_icon} alt="edit" className={`img-fluid ${styles.edit_icon}`} />
                              </span>
                              <span>Edit Product</span>
                            </li>
                            <li className={styles.action_div_dropdown_list} onClick={() => handleDeleteModal(item.id)}>
                              <span>
                                <img src={delete_icon} alt="delete"  className={`img-fluid ${styles.delete_icon}`} />
                              </span>
                              <span>Remove</span>
                            </li>
                          </ul>
                      </td>
                    </tr>
                  ))
                  :
                  <div className={styles.products_loader_div}>
                     <p>There are no records to display</p>
                  </div>
                 )
                }           

              </tbody>

             
            </table>
          </div>

          
          {
             loading ?
                ""
                :
              products.length > 0 && products ?
                <div className={styles.pagination_div}>
                     <PaginationComponent
                    currentPage={currentPage}
                    totalCount={count}
                    pageSize={PageSize}
                    onPageChange={(page) => getMoreItemsByOffset(page, typetag)}
                  />
            </div>
            :
            ""
            } 
        </div>
      </div>
    </>
   );
}
 
const mapStateToProps = (state) =>{
  return{
    products: state.product.products,
    reports: state.product.reports,
    count: state.product.count,
    loading: state.product.loading,
    isVerified: state.auth.isVerified,
    vendorTypes: state.auth.vendorTypes
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
    fetchProducts : (category, page) => dispatch(getProductsByType(category, page)),
    searchProducts : (category, val) => dispatch(searchProductByName(category, val)),
    deleteProduct : (category, id) => dispatch(DeleteProduct(category, id)),
    checkEmail: () => dispatch(checkEmailVerification()), 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);