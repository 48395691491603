import React, {useState, useEffect, useCallback, useMemo} from 'react';
import Sidebar from '../../components/Sidebar/sidebar';
import styles from './transaction.module.css'
import search_icon from "../../assets/icons/search_icon.svg";
import download from "../../assets/icons/download-white.svg";
import PaginationComponent from '../../components/Pagination/Pagination';
import { connect } from 'react-redux';
import { getTransactions, getTransactionsByType, searchTransactionByAmount } from '../../store/actions/transactions';
import Moment from "react-moment";
import {debounce} from 'lodash'
import CsvDownload from 'react-json-to-csv'

const AllTransactionsPage = ({ transactions, loading, count, fetchTransactionsByType, reports, searchTransaction}) => {

      // make call to fetch all transactions
      useEffect(() => {
        fetchTransactionsByType('all', 1);
    }, [fetchTransactionsByType]);

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "debit":
            result = styles.debit_status;
            break;
          case "credit":
            result = styles.credit_status;
            break;
          default:
            result = styles.debit_status;
            break;
        }
        return result;
      };

      const [typetag, setTypetag] = useState("all")

      const [types] = useState([
          {
            id: 1,
            tag: 'all',
            title: 'All'
          },
          {
              id: 2,
              tag: 'debit',
              title: 'Debit'
          },
          {
              id: 3, 
              tag:  'credit',
              title: 'Credit'
          }
      ])

    const handleType = (val) =>{  
        const {id,tag} = val
        setTypetag(tag)
          // make call to fetch products based on the type selected and for page 1
        switch(id){
         case 1: 
             fetchTransactionsByType(tag, 1)
            break;
        case 2:
            fetchTransactionsByType(tag, 1)
            break;
         case 3: 
          fetchTransactionsByType(tag, 1)
            break;
         default:
                break;
        }
    }

    let PageSize = 10;


    const [currentPage, setCurrentPage] = useState(1);
  
    const getMoreItemsByOffset = (page) => {
      setCurrentPage(page)
      fetchTransactionsByType(typetag, page);
    }

    const handleCapitalize = (str) =>{
        let value = str.toLowerCase()
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

      // handle search
    const handleSearch = useMemo(()=>
        debounce((cat,val)=>{
            searchTransaction(cat,val)
        },750),
        [searchTransaction]
     )

    const handleChange = useCallback((e)=>{ 
        let val = e.target.value
        handleSearch(typetag,val)
    },[handleSearch,typetag])




    return ( 
        <>
          <Sidebar></Sidebar>
         <div className="usermain">
            <div className="contain">

                <div className={styles.title}>
                    <p className="titlehead">Transaction History</p>
                </div>

                <div className={styles.left_div}>
                    <div className={styles.left_search}>
                    <div style={{position: 'relative'}}>
                        <img src={search_icon} alt="search" className={styles.search_icon} />
                        <input type="text" onChange={handleChange} placeholder='Search by amount' className={styles.search_input} />
                    </div>
                    
                    </div>
                    <div className='mt-lg-0 mt-4'>
                      {
                        transactions.length > 0 ?
                        <CsvDownload data={reports} 
                          className={`${styles.download_btn} btn`} 
                            filename={`${typetag}.csv`}>
                                <img src={download} alt="download" />
                                <span>Download Statement</span>
                            </CsvDownload>
                            :
                            ""
                        }
                       
                    </div>
                </div>


                {/* filter tabs */}
                <div className={styles.transactions_filter_div}>
                    {types.map((val) => (
                        <li
                        key={val.id}
                        onClick={() => handleType(val)}
                        >
                        <p 
                        className={typetag === val.tag ? `${styles.category_tab} ${styles.category_tab_active}` : `${styles.category_tab}`}
                        >{val.title} <span>{typetag === val.tag ? count : ''}</span></p>
                        </li>
                    ))}
                
                </div>


                {/* transaction layout */}

                <div className={`mb-4 ${styles.history_tables_div}`}>
                 <table>
                    <thead>
                        <tr>
                        <th>Transaction ID</th>
                        <th>Recipient</th>
                        <th>Account Number</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Amount</th>
                        </tr>
                    </thead>     
                    <tbody>
                        {
                            loading ?
                            ( <div className={styles.loader_div}>
                                <p>Loading...</p>
                            </div>)
                            :
                            (
                            transactions.length > 0 ? transactions.map((item)=>(
                        <tr key={item.id}>
                            <td className={styles.historyId}>{item.id}</td>
                                <td>{item.app_vendorwallettransfer.recipient_account_name}</td>
                                <td>
                                    {item.app_vendorwallettransfer.recipient_account_number}
                                </td>
                                <td>
                                <span className={getStatusColor(item.transaction_type)}>{handleCapitalize(item.transaction_type)}</span>
                                </td>
                                <td><Moment format="Do, MMM YYYY">{item.transaction_date}</Moment></td>
                                <td>₦{item.transaction_amount}</td>
                            
                        </tr>
                    ))
                    :
                    <div className={styles.loader_div}>
                        <p>There are no records to display</p>
                    </div>
                    )
                    }
                    </tbody>
                 </table>
                </div>

                   {
                    loading ?
                        ""
                        :
                    transactions.length > 0 && transactions ?
                        <div className={styles.pagination_div}>
                            <PaginationComponent
                            currentPage={currentPage}
                            totalCount={count}
                            pageSize={PageSize}
                            onPageChange={(page) => getMoreItemsByOffset(page, typetag)}
                        />
                    </div>
                    :
                    ""
                    } 

            </div>
        </div>
        </>
     );
}
 

const mapStateToProps = (state) =>{
    return{
      transactions: state.transaction.transactions,
      count: state.transaction.count,
      loading: state.transaction.loading,
      reports: state.transaction.reports,
    }
  }
  
  const mapDispatchToProps = (dispatch) =>{
    return{
      fetchTransactions : () => dispatch(getTransactions()),
      fetchTransactionsByType : (type, page) => dispatch(getTransactionsByType(type, page)),
      searchTransaction : (type, val) => dispatch(searchTransactionByAmount(type, val)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AllTransactionsPage);