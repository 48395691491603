import React,{useEffect, useState} from 'react';
import Sidebar from '../../components/Sidebar/sidebar';
import styles from './transaction.module.css'
import arrow from "../../assets/icons/arrow_drop_down.svg";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCount, getTransactions } from '../../store/actions/transactions';
import Moment from "react-moment";
import { getOverviewInfo } from '../../store/actions/overview';
import arrow_up from "../../assets/icons/arrow_drop_up.svg";

const TransactionsPage = ({fetchTransactions, transactions, loading, fetchOverview, totalRevenue, fetchCount, transactionCount}) => {

    // make call to fetch all transactions
    useEffect(() => {
        fetchTransactions();
        fetchOverview();
        fetchCount('week')
    }, [fetchTransactions,fetchOverview, fetchCount]);

    const [isDropOpen, setIsDropOpen] = useState(false)
    const [title, setTitle] = useState("This Week")

    const [filterSort] = useState([
        {
          id: 1,
          name: "This Week",
          tag: "week",
        },
        {
          id: 2,
          name: "This Month",
          tag: "month",
        },
        {
          id: 3,
          name: "This Year",
          tag: "year",
        }
      ]);

      const toggleDropList = () => {
        setIsDropOpen(!isDropOpen)
       }
     
       const toggleCount = (val) => {
         const {tag, name} = val
         setTitle(name)
         setIsDropOpen(false)
         //  make api call based on the filter selected
          fetchCount(tag)
       }
     


    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "debit":
            result = styles.debit_status;
            break;
          case "credit":
            result = styles.credit_status;
            break;
          default:
            result = styles.debit_status;
            break;
        }
        return result;
      };

      const handleCapitalize = (str) =>{
        let value = str.toLowerCase()
        return value.charAt(0).toUpperCase() + value.slice(1);
      }

      const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
      }


    return (
        <>
        <Sidebar></Sidebar>
         <div className="usermain">
            <div className="contain">

                <div className={styles.title}>
                    <p className="titlehead">Finance</p>
                </div>

                <div className='row mt-4'>
                    <div className='col-lg-5 mb-lg-0 mb-4'>

                        <div className={styles.box_green}>

                            <div className={styles.wallet_div}>
                                <div>
                                    <h4 className={styles.wallet_amount}>₦{totalRevenue ? numberWithCommas(totalRevenue) :0}</h4>
                                    <p className={styles.wallet_title}>Total Revenue</p>
                                </div>
                                <div className={styles.revenue_icon_div}>
                                    <h2>₦</h2>
                                </div>
                                {/* <div className={styles.withdraw_div}>
                                    <div className={styles.withdraw_layout}>
                                        <img src={card_send} alt="card" />
                                        <p>Withdraw</p>
                                    </div>
                                </div> */}
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-7'>

                        <div className={styles.box_grey}>
                            <div className={styles.box_grey_layout}>
                                <div className={styles.breakdown_div}>
                                    <div className={styles.dropdown_div}>
                                        <div 
                                        onClick={() => toggleDropList()}
                                        className={styles.filter_div}>
                                            <h6>{title}</h6>
                                           {isDropOpen ? (
                                                    <img src={arrow_up} alt="arrow" />
                                            ) : (
                                                <img src={arrow} alt="arrow" />
                                            )}
                                            
                                        </div>

                                        {/* dropdown section */}
                                        <ul
                                        className={
                                            isDropOpen
                                            ? `${styles.dropdown_section} ${styles.dropdown_section_active}`
                                            : styles.dropdown_section
                                        }
                                        >
                                        {filterSort.map((val) => (
                                            <li key={val.id} onClick={() => toggleCount(val)}>
                                            <p className={styles.dropdown_list}>{val.name}</p>
                                            </li>
                                        ))}
                                        </ul>
                                    </div>

                                     
                                </div>

                                <div className={styles.breakdown_div}>
                                        <p className={styles.breakdown_title}>Transactions</p>
                                        <h5 className={styles.breakdown_value}>{transactionCount ? transactionCount.count : 0}</h5> 
                                </div>

                                <div className={styles.breakdown_div}>
                                    <p className={styles.breakdown_title}>Revenue</p>
                                    <h5 className={styles.breakdown_value}>₦{transactionCount ? transactionCount.revenue: 0}</h5>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                 {/* title */}
                 <div className={`mt-5 ${styles.history_title}`}>
                    <h6>Transaction History</h6>
                </div>

                <div className={`mb-4 ${styles.history_tables_div}`}>
                 <table>
                    <thead>
                        <tr>
                        <th>Transaction ID</th>
                        <th>Recipient</th>
                        <th>Account Number</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Amount</th>
                        </tr>
                    </thead>     
                    <tbody>
                        {
                        loading ?
                        ( <div className={styles.loader_div}>
                            <p>Loading...</p>
                        </div>)
                        :
                        (
                        transactions.length > 0 ? transactions.map((item)=>(
                      <tr key={item.id}>
                        <td className={styles.historyId}>{item.id}</td>
                            <td>{item.app_vendorwallettransfer.recipient_account_name}</td>
                            <td>
                                {item.app_vendorwallettransfer.recipient_account_number}
                            </td>
                            <td>
                            <span className={getStatusColor(item.transaction_type)}>{handleCapitalize(item.transaction_type)}</span>
                            </td>
                            <td><Moment format="Do, MMM YYYY">{item.transaction_date}</Moment></td>
                            <td>₦{item.transaction_amount}</td>
                        
                    </tr>
                  ))
                  :
                  <div className={styles.loader_div}>
                     <p>There are no records to display</p>
                  </div>
                 )
                }  
                     
                    </tbody>
                 </table>
                </div>

                  {
                    loading ?
                        ""
                        :
                        transactions.length > 0 && transactions ?
                         <Link to="/transactions/more" className={styles.see_more}>
                                See More
                         </Link>
                        :
                    ""
                    } 

                

            </div>
           </div>
        </>
     );
}
 

 
const mapStateToProps = (state) =>{
    return{
      transactions: state.transaction.transactions,
      count: state.transaction.count,
      loading: state.transaction.loading,
      totalRevenue: state.overview.totalRevenue,
      transactionCount: state.transaction.transactionCount
    }
  }
  
  const mapDispatchToProps = (dispatch) =>{
    return{
      fetchTransactions : () => dispatch(getTransactions()),
      fetchOverview: () => dispatch(getOverviewInfo()),
      fetchCount: (tag) => dispatch(getCount(tag)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage)