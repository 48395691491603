const initState = {
    transactions: [],
    count: 0,
    loading: false,
    reports: [],
    transactionCount: {}
  };
  
  const transactionReducer = (state = initState, action) => {
    switch (action.type) {
    case "transactionLoader":
        return{
            ...state,
            loading: true
        }
    case "transactionLoaderStop":
        return{
            ...state,
            loading: false
        }
      case "Transactions":
        return {
          ...state,
          transactions: action.data.resp,
         count: action.data.count
        }
      case "STATEMENT":
        let reports = action.data.rows.map(report => ({
          transactionId: report.id,
          amount: report.transaction_amount,
          type: report.transaction_type,
          transactionDate: report.transaction_date,
          reference: report.transaction_reference,
          recipientAccountName: report.app_vendorwallettransfer.recipient_account_name,
          recipientAccountNumber: report.app_vendorwallettransfer.recipient_account_number,
      }));
        return{
          ...state,
          reports: reports
        }
      case "TransactionsCount":
        return{
          ...state,
          transactionCount: action.data
        }
      default:
        return state;
    }
  };
  
  export default transactionReducer;
  