const initState = {
    orders: [],
    reports: [],
    count: 0,
    orderloader: false,
    order: [],
    loading: false
  };
  
  const orderReducer = (state = initState, action) => {
    switch (action.type) {
    case "orderStartLoad":
        return{
            ...state,
            loading: true
        }
    case "orderStopLoad":
        return{
            ...state,
            loading: false
        }
      case "ORDERS":
        return {
          ...state,
         orders: action.data.resp,
         count: action.data.count
        };
    case "ORDERSREPORT":
      let reports = action.data.rows.map(report => ({
        deliveryStatus: report.delivery_status,
        amount: report.total_cost_price
    }));
      return{
        ...state,
        reports: reports
      }
    case "Order_Error":
      return{
        ...state,
        count: 0,
        orders: []
      }
    case "orderLoader":
      return{
        ...state,
        orderloader: true
      }
    case "orderLoaderStop":
      return{
        ...state,
        orderloader: false
      }
    case "ORDER":
      return{
        ...state, 
        order: action.data
      }
      default:
        return state;
    }
  };
  
  export default orderReducer;
  