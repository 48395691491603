import React,{ useEffect, useRef, useState} from 'react';
import styles from "./complete.module.css";
import { Form, Formik } from "formik";
import { completeRegValidator } from "../../validationSchema/validator";
import {useNavigate } from 'react-router-dom';
import Sidebar from '../../components/OnboardingSidebar/sidebar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearSignUp, getBanks, signUp } from '../../store/actions/auth';
import chevron_left from '../../assets/icons/chevron_left.svg'
import Select from 'react-select'
import cogoToast from 'cogo-toast';


const CompleteRegistrationPage = ({signup_success, clearRegistration, registerUser, onboardVal, signuperror, fetchBanks, banks}) => {


    const ref = useRef()

    const navigate = useNavigate();

    useEffect(()=>{
      fetchBanks()
    },[fetchBanks])

    const [selectedBank, setSelectedBank] = useState("")


    const handleBank = (val) =>{
      setSelectedBank(val.id)
    }
  
    // register func
  const handleSubmit = async (values) => {
     console.log(values)
     if(selectedBank === ""){
      cogoToast.warn('Please at least a bank!')
     }
     else{
      const creds = {
        ...onboardVal,
        bvn: values.BVN,
        phoneNumber: "+234" + onboardVal.phoneNumber.substring(1),
        accountName: values.accountName,
        accountNumber: values.accountNumber,
        bank: selectedBank
      }
      console.log(creds)
      await registerUser(creds)
     }
    
  }; 


  useEffect(()=>{
    if(signup_success){
      setTimeout(()=>{
        ref.current.reset();
        navigate("/overview");
        clearRegistration();
      },3000)
    }
   },[signup_success, navigate, clearRegistration])

   useEffect(()=>{
     if(signuperror){
       navigate('/register')
     }
   },[signuperror, navigate])

    return ( 
        <>
        <div className='row no-gutters'>
      <Sidebar/>

      <div className='col-lg-7'>
        <div className={styles.login_second_half_div}>

        <Link to="/register" style={{textDecoration: 'none'}}>
            <div className={styles.back_div}>
                <div className={styles.back_circle}>
                    <img src={chevron_left} alt="checvron" />
                </div>
                <div>
                    <span className={styles.back_text}>Back</span>
              </div>
            </div>
        </Link>
          
          <div className={styles.register_step_div}>
             <div>
              <h4 className={styles.login_second_half_title}>Set up your wallet</h4>
            </div>
            <div className={styles.register_steppers}>
                <p>
                  <span>2</span>/2
                </p>
            </div>
          </div>

      
           
            {/* login section */}
             {/* form submission */}
             <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={completeRegValidator}
              initialValues={{ BVN: "",accountNumber: "", accountName: "" }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form ref={ref}  onSubmit={handleSubmit}>
                  {/* form section */}
                  <div className={`mt-4 ${styles.form_container}`}>

                   {/* phone number */}
                   {/* <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <div style={{position: 'relative'}}>
                        <span className={styles.prefix_num}>+234</span>
                        <input
                          className={`${styles.input_style} ${styles.prefix_input}`}
                          type="text"
                          placeholder="Enter your phone number"
                          id="phoneNumber"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.phoneNumber}
                        />
                      </div>
                      
                      <small style={{ color: "#dc3545" }}>
                        {touched.phoneNumber && errors.phoneNumber}
                      </small>
                    </div> */}

                     {/* BVN */}
                     <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="BVN">Bank Verification Number</label>
                      <input
                        className={`${styles.input_style}`}
                        type="text"
                        placeholder="Enter a valid BVN"
                        id="BVN"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.BVN}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.BVN && errors.BVN}
                      </small>
                    </div>

                    {/* bank name */}
                    <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="bank">Bank</label>
                      <Select 
                        onChange={handleBank}
                        classNamePrefix="select_control"
                        className="select_style"
                       
                        options={banks}
                        getOptionLabel={option => option.display_name}
                         getOptionValue={option => option.id}
                       />
                    </div>



                    {/* account number */}
                    <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="accountNumber">Account Number</label>
                      <input
                        className={`${styles.input_style}`}
                        type="text"
                        placeholder="Enter your account number"
                        id="accountNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.accountNumber}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.accountNumber && errors.accountNumber}
                      </small>
                    </div>

                    {/* Account name */}
                    <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="accountName">Account Name</label>
                      <input
                        className={`${styles.input_style}`}
                        type="text"
                        placeholder="Enter an account name"
                        id="accountName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.accountName}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.accountName && errors.accountName}
                      </small>
                    </div>
                    
                    {/* WALLET pin */}
                    {/* <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="pin">Wallet Pin (4 digits)</label>
                      <input
                        className={`${styles.input_style}`}
                        type="password"
                        placeholder="Enter your wallet Pin"
                        id="pin"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.pin}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.pin && errors.pin}
                      </small>
                    </div> */}

                    {/* <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="confirm_pin">Confirm Wallet Pin (4 digits)</label>
                      <input
                        className={`${styles.input_style}`}
                        type="password"
                        placeholder="Confirm your wallet Pin"
                        id="confirm_pin"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirm_pin}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.confirm_pin && errors.confirm_pin}
                      </small>
                    </div>                   */}

                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn ${styles.btn_login_submit}`}
                      >
                       Create Account
                      </button>
                    </div>

                    <div className="mt-2">
                      <p
                      className={`text-center ${styles.login_link}`}
                      >
                        Already have an account?{" "}
                        <Link to="/">Log In</Link>
                      </p>
                    </div>

                  
                  </div>
                </Form>
              )}
            </Formik>

        </div>
      </div>

    </div>
        </>
     );
}



const mapStateToProps = (state) => {
  return {
    signup_success: state.auth.signupsuccess,
    onboardVal: state.auth.onboardValues,
    signuperror: state.auth.signuperror,
    banks: state.auth.banks
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (creds) => dispatch(signUp(creds)),
    clearRegistration: () => dispatch(clearSignUp()),
    fetchBanks : () => dispatch(getBanks()),
  };
};

 
export default connect(mapStateToProps, mapDispatchToProps)(CompleteRegistrationPage);