import React,{useState, useEffect} from 'react';
import styles from "./login.module.css";
import { Form, Formik } from "formik";
import { loginValidator } from "../../validationSchema/validator";
import {Link, useNavigate , useLocation } from 'react-router-dom'
import visible_off from '../../assets/icons/visibility_off.svg'
import visible from '../../assets/icons/visibility.svg'
import Sidebar from '../../components/OnboardingSidebar/sidebar';
import {connect} from 'react-redux'
import {loginUser} from '../../store/actions/auth'
import Logo from "../../assets/logo/GatherLogo.svg";

const LoginPage = ({isAuthenticated, login}) => {

  const [passwordShown, setPasswordShown] = useState(false);

  const { state } = useLocation()

  const navigate = useNavigate();

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

   // login func
   const handleSubmit = async (values) => {
    const creds = {
      ...values,
      phoneNumber: "+234" + values.phoneNumber.substring(1),
    }
    console.log(creds)
    await login(creds)
 
 };
 
 useEffect(() => {
  if (isAuthenticated) {
    navigate(state?.path || "/overview")
    // navigate("/overview");
  }
}, [isAuthenticated, navigate, state ]);


  return ( 
    <>
    <div className='row no-gutters'>
      <Sidebar/>
      <div className='col-lg-7'>
        <div className={styles.login_second_half_div}>

            <div className="logo mt-4 d-md-none d-sm-block">
              <img src={Logo} alt="logo" height="50" />
            </div>

            <div className='mt-4 mt-md-0'>
              <h4 className={styles.login_second_half_title}>Login</h4>
            </div>
            {/* login section */}
             {/* form submission */}
             <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={loginValidator}
              initialValues={{ phoneNumber: "", password: "" }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* form section */}
                  <div className={`mt-4 ${styles.form_container}`}>

                    {/* Phone number  */}
                    <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <input
                        className={`${styles.input_style}`}
                        type="text"
                        placeholder="Enter your Phone Number"
                        id="phoneNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phoneNumber}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.phoneNumber && errors.phoneNumber} 
                      </small>
                    </div>

                    {/* password */}
                    <div className={`mb-3 ${styles.input_container}`} >
                      <label htmlFor="password">Password</label>
                      <div style={{position: 'relative'}}>
                      <input
                        className={`${styles.input_style}`}
                        placeholder="Password"
                        id="password"
                        type={passwordShown ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {passwordShown
                              ? 
                              <img  
                              onClick={togglePasswordVisiblity}
                               className={styles.toggle_style} src={visible} alt='toggle off' />
                              :
                              <img  
                              onClick={togglePasswordVisiblity}
                               className={styles.toggle_style} src={visible_off} alt='toggle on' />
                          }
                      </div>
                    
                      <small style={{ color: "#dc3545" }}>
                        {touched.password && errors.password}
                      </small>
                    </div>

                    <Link
                      to="/forgotpassword"
                      className="text-right"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={styles.forgot_password_text}>Forgot Password?</p>
                    </Link>

                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn ${styles.btn_login_submit}`}
                      >
                       LogIn
                      </button>
                    </div>

                    <div className="mt-2">
                      <p
                      className={`text-center ${styles.login_link}`}
                      >
                        Don't have an account?{" "}
                        <Link to="/register">Create an account</Link>
                      </p>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

        </div>
      </div>

    </div>
    </>
   );
}



const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (creds) => dispatch(loginUser(creds)),
  };
};


 
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);