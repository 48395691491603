import React, { useState, useEffect, useCallback } from 'react';
import { Link,useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/GatherLogo.svg";
import overview_icon from "../../assets/icons/overview_icon.svg";
import overview_default_icon from "../../assets/icons/overview_default.svg";
import product_icon from "../../assets/icons/products_icon.svg";
import product_green_icon from "../../assets/icons/products_green_icon.svg";
import order_icon from "../../assets/icons/orders_icon.svg";
import order_green_icon from "../../assets/icons/order_green_icon.svg";
import chart_active from '../../assets/icons/Chart_Active.svg'
import chart from '../../assets/icons/Chart.svg'
import profile_icon from "../../assets/icons/Profile_icon.svg";
import user_icon from "../../assets/icons/user_icon.svg";
import logout_icon from "../../assets/icons/logout.svg";
import {connect} from 'react-redux'
import PopComp from '../Modal/popups';
import logout_colored from "../../assets/icons/logout_colored.svg"

import  './sidebar.css'
import { logOut } from '../../store/actions/auth';



const Sidebar = (props) => {
  const [sideShow, setSideShow] = useState(false);
  const [show, setShow] = useState(false)

  const { Logout, firstname } = props;

  const navigate = useNavigate();

  // check for which path you are on
  const isActive = useLocation().pathname;

  const showModal = () =>{
    setShow(!show)
  }
  
  const handleClose = () =>{
    setShow(false)
  }

  const handleLogoutModal = () =>{
    setShow(true)
  }

  const handleLogout = () =>{
    Logout();
    document.body.classList.remove("modal-open");
    navigate('/')
  }

    const ToggleSidebar = () => {
      setSideShow(sideShow ? false : true);
  };

   const closeSideBar = useCallback(() => {
     setSideShow(sideShow ? false : true);
   }, [sideShow]);
  
   useEffect(() => {
     if (sideShow) {
       document.body.addEventListener("click", closeSideBar);

       return () => document.body.removeEventListener("click", closeSideBar);
     }
   }, [closeSideBar, sideShow]);
  

  return (
    <>
      <PopComp show={show} showModal={showModal}>
           <div style={{display: 'flex', justifyContent: 'center'}}>
              <div className='logout_icon_div'>
                  <img src={logout_colored} alt="save"/>
              </div>
            </div>

          <div className='text-center'>
              <h6 className='pop_title'>Logout</h6>
              <p className='pop_subtitle'>Are you sure you want to log out of your account?</p>
          </div>

          <div className='logout-cta'>
            <button className='btn cancel-btn'  onClick={() => handleClose()}>Cancel</button>
            <button className='btn btn-action' onClick={handleLogout}>Log Out</button>
          </div>
       </PopComp>
      <div className={sideShow ? "userside show" : "userside"}>
        {/* logo */}
        <div className="logo text-center mt-4">
          <img src={Logo} alt="logo" height="50" />
        </div>
        {/* nav list */}
        <div className="v-list">
          <div>
            {/* first */}
            <Link
              to="/overview"
              className={
                isActive.includes("/overview")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/overview")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/overview") ? (
                  <img src={overview_icon} alt="overview" />
                ) : (
                  <img src={overview_default_icon} alt="overview" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/overview") ? "active" : ""}
                >
                  Overview
                </span>
              </div>
            </Link>

            <Link
              to="/products"
              className={
                isActive.includes("/product")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/product")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/product") ? (
                  <img src={product_green_icon} alt="products" />
                ) : (
                  <img src={product_icon} alt="products" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/product") ? "active" : ""}
                >
                  Products
                </span>
              </div>
            </Link>

            <Link
              to="/orders"
              className={
                isActive.includes("/order")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/order")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/order") ? (
                  <img src={order_green_icon} alt="orders" />
                ) : (
                  <img src={order_icon} alt="orders" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/order") ? "active" : ""}
                >
                  Orders
                </span>
              </div>
            </Link>

            <Link
              to="/transactions"
              className={
                isActive.includes("/transaction")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
             
              <div
                className={
                  isActive.includes("/transaction")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/transaction") ? (
                  <img src={chart_active} alt="transaction" />
                ) : (
                  <img src={chart} alt="transaction" />
                )}
              </div>
              
              <div className="v-list-title">
                <span
                  className={isActive.includes("/transaction") ? "active" : ""}
                >
                  Finance
                </span>
              </div>
            </Link> 

            <Link
              to="/profile"
              className={
                isActive.includes("/profile")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/profile")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/profile") ? (
                  <img src={user_icon} alt="profile" />
                ) : (
                  <img src={profile_icon} alt="profile" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/profile") ? "active" : ""}
                >
                  Profile
                </span>
              </div>
            </Link>
          </div>

          <div className="sidebar-footer">
            <div className="sidebar-footer-layout" onClick={handleLogoutModal}>     
                <div className='logout_img'>
                    <img src={logout_icon} alt="logout" />
                </div>
                <div className='logout_title'>
                    <span>Log Out</span>
                </div>
             
            </div>
          </div>
        </div>

       
      </div>

      {/* navbar */}
      <nav>
        <div className="sidehead">
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* icon for hamburger */}
            <div
              onClick={ToggleSidebar}
              className="d-md-none d-sm-block"
              style={{ marginRight: "12px", cursor: "pointer" }}
            >
              <i className="mdi mdi-menu" style={{ fontSize: 24, color:"#2b8c34" }}></i>
            </div>

            {/* back arrow */}
            {/* <div>
              {props.children}
              
            </div>   */}

           
            <div style={{ flexGrow: 1 }}></div>
                <div style={{display: 'flex'}}>
                   <div className='profile-div'>
                    <img
                      src={user_icon}
                      alt="user"
                    />
                    </div>
                    <div className="sidebar-footer-name">
                    <p>{firstname && firstname.hasOwnProperty("first_name") ? firstname.first_name: "Nill"}</p>
                    <p>Admin</p>
                  </div>
                </div>
          </div>


        </div>
      </nav>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    firstname: state.auth.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Logout: () => dispatch(logOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);