const initState = {
    products: [],
    reports: [],
    loading: false,
    product: [],
    states: [],
    category: [],
    subcategory: [],
    measurement: [],
    count: 0,
    productloader: false,
    product_success: false,
    productOrder: [],
    totalProductOrder: 0,
    incompleted_order: 0,
    completed_order: 0,
    revenue: 0,
    orderloader: false,
    revenueChart: []
  };
  
  const productReducer = (state = initState, action) => {
    switch (action.type) {
    case "startLoad":
        return{
            ...state,
            loading: true
        }
    case "stopLoad":
        return{
            ...state,
            loading: false
        }
      case "PRODUCTS":
        return {
          ...state,
          count: action.data.count,
         products: action.data.resp
        };
      case "PRODUCTREPORT":
        let reports = action.data.rows.map(report => ({
          name: report.name,
          costPrice: report.cost_price,
          description: report.description,
          dateAdded: report.created,
          units: report.units
      }));
        return{
          ...state,
          reports: reports
        }
      case "ADD_PRODUCT_SUCCESS":
        return {
          ...state,
          product_success: true
        }
      case "clearAddedProducts":
        return{
          ...state,
          product_success: false
        }
      case "Category":
        return{
          ...state,
          category: action.data
        }
      case "Subcategory":
        return{
          ...state,
          subcategory: action.data
        }
      case "Measurement":
        return{
          ...state,
          measurement: action.data
        }
      case "States":
        return{
          ...state,
          states: action.data
        }
      case "productLoader":
        return{
          ...state,
          productloader: true
        }
      case "productLoaderStop":
        return{
          ...state,
          productloader: false
        }
      case "PRODUCT":
        return{
          ...state, 
          product: action.data
        }
      case "PRODUCTORDER":
        return{
          ...state,
          productOrder: action.data.rows,
          totalProductOrder: action.data.count,
          completed_order: action.data.completed_order,
          incompleted_order: action.data.incompleted_order,
          revenue: action.data.revenue
        }
        case "orderLoader":
          return{
            ...state,
            orderloader: true
          }
        case "orderLoaderStop":
          return{
            ...state,
            orderloader: false
          }
      case "RevenueChart":
        return{
          ...state,
          revenueChart: action.data
        }
      default:
        return state;
    }
  };
  
  export default productReducer;
  