const initState = {
    isAuthenticated: false,
    token: "",
    profile: {},
    vendorTypes: [],
    supplierType: [],
    organizationId: null,
    onboardValues: {},
    signupsuccess: false,
    signuperror: false,
    isVerified: false,
    verified: false,
    codeValid: false,
    otpcode: "",
    organizationName: "",
    organizationAddress: "",
    organizationDescription: "",
    account_number: "",
    account_name: "",
    bank: "",
    resetsuccess: false,
    forgotsuccess: false,
    phone: "",
    types:  [
      {
        id: 1,
        title: 'Crop Products',
        tag: 'crop'
      },
      {
        id: 2,
        title: 'Livestock Products',
        tag: 'livestock'
      },
      {
        id: 3,
        title: 'Mechanization Products',
        tag: 'mechanization'
      },
      {
        id: 4,
        title: 'Advisory Products',
        tag: 'advisory'
      },
      {
        id: 5,
        title: 'Insurance Products',
        tag: 'insurance'
      }
    ],
    banks: [],
    selectedBank: []
  };
  
  const authReducer = (state = initState, action) => {
    switch (action.type) {
      case "LOGIN_SUCCESS":
        console.log(action.data)
        localStorage.setItem("token", action.data.token);
        var filteredVendor = state.types.filter(tag => action.data.vendorTypes.includes(tag.tag))
        return {
          ...state,
          isAuthenticated: true,
          token: action.data.token,
          profile: action.data.user,
          organizationId: action.data.organizationId,
          organizationName: action.data.organizationName,
          isVerified: action.data.isVerified,
          organizationAddress: action.data.organizationAddress,
          organizationDescription: action.data.organizationDescription,
          vendorTypes: filteredVendor,
          supplierType: action.data.vendorTypes,
          account_name: action.data.account_name,
          account_number: action.data.account_number,
          bank: action.data.bank,
          onboardValues: {}
        };
      case 'OnBoardValues':
        return{
          ...state,
          onboardValues:  action.data
        }
      case "SIGNUP_SUCCESS":
        localStorage.setItem("token", action.data.token);
        var filtrdVendor = state.types.filter(tag => action.data.user.vendor_types.includes(tag.tag))
        return{
          ...state,
          signupsuccess: true,
          isAuthenticated: true,
          token: action.data.token,
          profile: action.data.user,
          organizationId: action.data.user.organization_id,
          organizationName: action.data.user.org_name,
          isVerified: action.data.user.is_verified,
          organizationAddress: action.data.user.org_address,
          organizationDescription: action.data.user.org_description,
          vendorTypes: filtrdVendor,
          supplierType: action.data.user.vendor_types,
          account_name: action.data.user.account_name,
          account_number: action.data.user.account_number,
          bank: action.data.user.bank,
          onboardValues: {}
        }
      case "SIGNUP_ERROR":
        return{
          ...state,
          signuperror: true
        }
      case "VERIFY_EMAIL_SUCCESS":
        return{
          ...state,
          verified: true
        }
      case "VERIFY_EMAIL_ERROR":
        return{
            ...state,
            verified: false
        }
      case "VERIFY_CODE_SUCCESS":
        return{
          ...state,
          codeValid: true,
          otpcode: action.data
        }
      case "VERIFY_CODE_ERROR":
        return{
          ...state,
          codeValid: false
        }
      case "clearSignUpSuccess":
        return{
          ...state, 
          signupsuccess: false
        }
      case "clearSignUpError":
        return{
          ...state,
          signuperror: false
        }
      case "CompanyUpdate_SUCCESS":
        var filtredVendor = state.types.filter(tag => action.data.vendor_types.includes(tag.tag))
        return{
          ...state,
          organizationDescription: action.data.description,
          organizationAddress: action.data.address,
          organizationName: action.data.name,
          vendorTypes: filtredVendor,
          supplierType: action.data.vendor_types
        }
      case "RESET_SUCCESS":
        return{
          ...state,
          resetsuccess: true
        }
      case "RESET_ERROR":
        return{
          ...state,
          resetsuccess: false
        }
      case "FORGOT_SUCCESS":
        return{
          ...state,
          forgotsuccess: true,
          phone: action.data
        }
      case "clearForgotSuccess":
        return{
          ...state,
          forgotsuccess: false
        }
      case "EmailVerify_Success":
        return{
          ...state,
          isVerified: true
        }
      case "Banks":
        var sB = state.bank === null ? [] : action.data.filter(tag => state.bank.includes(tag.display_name))
        return{
          ...state,
          banks: action.data,
          selectedBank: sB,
          selectedBankId: sB.length > 0 ? sB[0].id : ""
        }
      case "logout":
        return {
          ...state,
          isAuthenticated: false,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  