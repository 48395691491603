import React,{useEffect, useState} from 'react';
import Sidebar from '../../components/Sidebar/sidebar';
import back_arrow from "../../assets/icons/back_arrow.svg";
import {Link, useParams} from 'react-router-dom'
import styles from './order.module.css'
import { connect } from 'react-redux';
import { getOrderById, UpdateOrderStatus } from '../../store/actions/order';
import input_icon from "../../assets/icons/input_icon.svg"; 
import mechanization_icon from "../../assets/icons/mechanization_icon.svg";
import insurance_icon from "../../assets/icons/insurance_icon.svg";
import advisory_icon from "../../assets/icons/advisory_icon.svg";
import Moment from "react-moment";
import PopComp from '../../components/Modal/popups';
import reject_icon from "../../assets/icons/box-remove.svg";
import accept_icon from "../../assets/icons/box-accept.svg";
import dispatch_icon from "../../assets/icons/truck-time.svg";
import confirm_icon from "../../assets/icons/box-tick.svg";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const OrderDetailsPage = ({fetchOrder, order, loader, updateStatus}) => {

    const values = useParams();

    // fetch a particular order details
    useEffect(()=>{
        let {type, id} = values
        fetchOrder(type, id)
    }, [values, fetchOrder])



  const [show, setShow] = useState(false)
  const showModal = () =>{
    setShow(!show)
  }

  const handleClose = () =>{
    setShow(false)
  }

  const handleRejectModal = () =>{
    setShow(true)
  }

  const [showAccept, setShowAccept] = useState(false)

  const showAcceptModal = () =>{
      setShowAccept(!showAccept)
  }

  const handleCloseAccept = () =>{
    setShowAccept(false)
  }

  const [showDispatch, setShowDispatch] = useState(false)

  const showDispatchModal = () =>{
      setShowDispatch(!showDispatch)
  }

  const handleCloseDispatch = () =>{
    setShowDispatch(false)
  }

  const [showConfirm, setShowConfirm] = useState(false)

  const showConfirmModal = () =>{
      setShowConfirm(!showConfirm)
  }

  const handleCloseConfirm = () =>{
    setShowConfirm(false)
  }

  const handleAcceptModal = () =>{
    //  check for status and know which modal to be active
    let status = order[0].delivery_status
        switch(status){
            case "PENDING":
                setShowAccept(true) 
                break;
            case "PROCESSING":
                setShowDispatch(true)
                break;
            case "DISPATCHED":
                setShowConfirm(true)
                break;
            default:
                break;
        }
  }




const handleImageShow = (val) =>{
    if(val === 'crop'){
        return input_icon
        }
        if(val === 'livestock'){
        return input_icon
        }
        if(val === 'mechanization'){
        return mechanization_icon
        }
        if(val === 'advisory'){
        return advisory_icon
        }
        if(val === 'insurance'){
        return insurance_icon
        }
    }

    const [btnText, setBtnText] = useState("") 

    // check for current delivery status
    useEffect(()=>{
    if(order.length){
        let status = order[0].delivery_status
        switch(status){
            case "PENDING":
                setBtnText("Accept")
                break;
            case "PROCESSING":
                setBtnText("Dispatch Order")
                break;
            case "DISPATCHED":
                setBtnText("Confirm Delivery")
                break;
            case "DELIVERED":
                setBtnText("")
                break;
            default:
                setBtnText("Accept")
                break;

        }
    }
},[order])

const [isRejectDisabled, setIsRejectDisabled]  = useState(false)
const [isAcceptDisabled, setIsAcceptDisabled] = useState(false)

//  call to actions button functionality
const handleCancel = () =>{
    let type = values.type
    let id = values.id
    setIsRejectDisabled(true)
    let creds = {
        delivery_status : "CANCELLED"
    }

    setShow(false)

    setTimeout(()=>{
        setIsRejectDisabled(false)
    }, 3000)
    updateStatus(type, id, creds)
}

const handleAcceptOrder = () =>{
    let type = values.type
    let id = values.id
    setIsAcceptDisabled(true)

    // accept an order functionality
    let creds = {
        delivery_status : "PROCESSING"
    }
    console.log(creds)
    updateStatus(type, id, creds)

    setShowAccept(false)

    setTimeout(()=>{
        setIsAcceptDisabled(false)
    }, 3000)

}

const handleDispatchOrder = () =>{

      let type = values.type
    let id = values.id
    setIsAcceptDisabled(true)

    // accept an order functionality
    let creds = {
        delivery_status : "DISPATCHED"
    }
    console.log(creds)
    updateStatus(type, id, creds)

    setShowDispatch(false)

    setTimeout(()=>{
        setIsAcceptDisabled(false)
    }, 3000)

}

const handleConfirmOrder = () =>{
      let type = values.type
    let id = values.id
    setIsAcceptDisabled(true)

    // accept an order functionality
    let creds = {
        delivery_status : "DELIVERED",
        delivery_date: new Date()
    }
    console.log(creds)
    updateStatus(type, id, creds)

    setShowConfirm(false)

    setTimeout(()=>{
        setIsAcceptDisabled(false)
    }, 3000)

}

const handleCTAButtons = (status) =>{
    switch(status){
        case 'PENDING':
        return <div className={styles.details_cta_div}>
            <div>
            <button disabled={isAcceptDisabled} onClick={() => handleAcceptModal()} className={`btn ${styles.btn_approve}`}>{btnText}</button>
            </div>
            <div>
                <button disabled={isRejectDisabled} onClick={() => handleRejectModal()} className={`btn ${styles.btn_decline}`}>Reject</button>
            </div>
        </div>
        case 'PROCESSING':
        return  <div className={styles.details_cta_div}>
            <div>
            <button disabled={isAcceptDisabled} onClick={() => handleAcceptModal()} className={`btn ${styles.btn_approve}`}>{btnText}</button>
            </div>
            <div>
                <button disabled={isRejectDisabled} onClick={() => handleRejectModal()} className={`btn ${styles.btn_decline}`}>Reject</button>
            </div>
        </div>
        case 'DISPATCHED':
            return  <div className={styles.details_cta_div}>
            <div>
            <button disabled={isAcceptDisabled} onClick={() => handleAcceptModal()} className={`btn ${styles.btn_approve}`}>{btnText}</button>
            </div>
            <div>
                <button disabled={isRejectDisabled} onClick={() => handleRejectModal()} className={`btn ${styles.btn_decline}`}>Reject</button>
            </div>
        </div>
        case 'DELIVERED':
            return <></>
        case 'CANCELLED':
            return <></>
        default:
            break;
    }
}

const handleCapitalize = (str) =>{
    let value;
    if(str === null){
        value = ''
    }
    else{
      value = str.toLowerCase()
    }
  
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  const numberWithCommas = (x) => { 
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
  }

    return (
        <>
            <Sidebar/>

            {/* reject modal */}
            <PopComp show={show} showModal={showModal}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className='logout_icon_div'>
                        <img src={reject_icon} alt="reject"/>
                    </div>
                    </div>

                <div className='text-center'>
                    <h6 className='pop_title'>Cancel Order</h6>
                    <p className='pop_subtitle'>Are you sure you want to cancel the order?</p>
                </div>

                <div className='logout-cta'>
                    <button className='btn cancel-btn'  onClick={() => handleClose()}>No</button>
                    <button className='btn btn-action' onClick={handleCancel}>Yes</button>
                </div>
            </PopComp>

            {/* approve modals  */}
            <PopComp show={showAccept} showModal={showAcceptModal}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className='save_icon_div'>
                        <img src={accept_icon} alt="reject"/>
                    </div>
                    </div>

                <div className='text-center'>
                    <h6 className='pop_title'>Accept Order</h6>
                    <p className='pop_subtitle'>Are you sure you want to accept and process this order?</p>
                </div>

                <div className='logout-cta'>
                    <button className='btn cancel-btn'  onClick={() => handleCloseAccept()}>No</button>
                    <button className='btn btn_yes' onClick={() => handleAcceptOrder()}>Yes</button>
                </div>
            </PopComp>

            {/*dispatch modal  */}
            <PopComp show={showDispatch} showModal={showDispatchModal}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className='save_icon_div'>
                        <img src={dispatch_icon} alt="reject"/>
                    </div>
                    </div>

                <div className='text-center'>
                    <h6 className='pop_title'>Dispatch Order</h6>
                    <p className='pop_subtitle'>Are you sure you want to dispatch the order?</p>
                </div>

                <div className='logout-cta'>
                    <button className='btn cancel-btn'  onClick={() => handleCloseDispatch()}>No</button>
                    <button className='btn btn_yes' onClick={() => handleDispatchOrder()}>Yes</button>
                </div>
            </PopComp>

        {/* confirm delivery modal */}
            <PopComp show={showConfirm} showModal={showConfirmModal}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className='save_icon_div'>
                        <img src={confirm_icon} alt="reject"/>
                    </div>
                    </div>

                <div className='text-center'>
                    <h6 className='pop_title'>Confirm Delivery</h6>
                    <p className='pop_subtitle'>Are you sure the order has been delivered?</p>
                </div>

                <div className='logout-cta'>
                    <button className='btn cancel-btn'  onClick={() => handleCloseConfirm()}>No</button>
                    <button className='btn btn_yes' onClick={() => handleConfirmOrder()}>Yes</button>
                </div>
            </PopComp>
  
            <div className="usermain">
                <div className="contain">

                <div className="back_arrow_div">
                        <Link to="/orders" className="back_arrow_icon">
                            <img src={back_arrow} alt="back" />
                        </Link>
                        <div>
                        <span>Back</span>
                        </div>
             </div>

             {
                 loader 
                 ?
                 <>
                 <div className='mt-5'>
                    <Skeleton count={3} />
                </div>
                <div className='row'>
                    <div className='col-lg-4'>

                    <div className='mt-3 mt-lg-2'>
                        <Skeleton count={3} />
                    </div>
                      
                    </div>
                    <div className='col-lg-8'>
                        <div className='mt-3 mt-lg-2'>
                            <Skeleton count={3} />
                        </div>

                    </div>

                </div>
                </>
                 
                 :
                 <>
                   <div className='mt-4'>
                    <p className="titlehead">Order Details</p>
                </div>

                <div className={`mt-4 ${styles.details_card}`}>
                    <div className={`mt-1 ${styles.details_status_div}`}>
                        <p className={styles.details_id}>Order ID: <span>{order.length ? order[0].id : ""}</span></p>

                            {
                               order.length && order[0].delivery_status === "DELIVERED" &&
                                <p className={`mb-0 ${styles.details_delivery_sect}`}>
                                      Delivery Date: { <Moment format="Do, MMM YYYY">{order[0].delivery_date}</Moment> }
                                    </p>
                            }

                                {           
                               order.length && order[0].delivery_status === "CANCELLED" &&
                                <p className={`mb-0 mt-2 mt-lg-0 ${styles.details_cancelled_sect}`}>
                                      Order Cancelled: { <Moment format="Do, MMM YYYY">{order[0].updated}</Moment> }
                                    </p>
                            }
                        
                    
                        <p className='mb-0'></p>
                    </div>
                    <div className={`mt-3 ${styles.details_flex}`}>

                    
                        <div  className={styles.details_productImg}>

                        {
                            order.length && order[0].productImages.length > 0 ? <img src={order[0].productImages[0]} alt='product' className='img-fluid' />  
                            :
                          <img src={handleImageShow(values.type)} className='img-fluid' alt="default" />
                          } 
                         
                        </div>

                        <div className={styles.details_section}>   

                            <h6 className={`mt-3 mt-lg-0 ${styles.details_productName}`}>
                            {order.length ? order[0].productName : ""} ({order.length ? order[0].productSize : ""}{order.length ? order[0].measurement: ""})</h6>

                            <div className={styles.details_info_sect}>
                                <div className={styles.details_info_div}>
                                    <p>Amount</p>
                                    <h6>₦{order && order.length ? numberWithCommas(order[0].costPrice) : 0}</h6>
                                </div>
                                <div className={styles.details_info_div}>
                                    <p>Order Date</p>
                                    <h6>{order.length ? 
                                    <Moment format="Do, MMM YYYY">{order[0].created}</Moment>: ""}</h6>
                                </div>
                                <div className={styles.details_info_div}>
                                    <p>Quantity</p>
                                    <h6>{order.length ? order[0].quantity : 0}</h6>
                                </div>
                                {/* <div className={styles.details_info_div}>
                                    <p>Delivery LGA/State</p>
                                    <h6>Akinyele, Oyo State</h6>
                                </div>  */}
                                <div className={styles.details_info_div}>
                                    <p>Payment Status</p>
                                    <h6>{order.length && order[0].payment_status ? "Paid" : "Not Paid"}</h6>
                                </div> 
                            </div>

                            {/* <div className={styles.details_location_sect}>
                                <div className={styles.details_location_div}>
                                    <p>Delivery Address</p>
                                    <h6>17, Oluwole Salako street off kajola street</h6>
                                </div>
                            </div> */}

                            {/* timeline and actions section */}
                            <div className={styles.details_timeline_div}>
                                <div className={styles.details_timeline}>
                                    <ul class="steps">
                                        {
                                            order.length > 0 ? order[0].order_status && order[0].order_status.map((val, index)=>(
                                              
                                              <>
                                              
                                                {
                                                order[0].order_status.length > 1 &&
                                                 <li key={index} 
                                                 className={
                                                    val !== "CANCELLED" ? 'active' : 'inactive'  
                                                 }
                                                >
                                                    <div class="img"></div>
                                                    <div class="caption">{handleCapitalize(val)}</div>
                                                </li>
                                                }
                                               </>
                                              
                                            ))
                                            :
                                            ""
                                        }
                                    </ul>

                                </div>
                                                    
                               {
                                   handleCTAButtons(order.length && order[0].delivery_status)             
                               }

                               
                            </div>

                            {/* <ul className='steps mt-5'>
                            <li className='active'>
                                        <div class="img"></div>
                                        <div class="caption">Pending</div>
                                        </li>
                                        <li className='active'>
                                        <div class="img"></div>
                                        <div class="caption">Processing</div>
                                        </li>
                                        <li className='inactive'>
                                        <div class="img"></div>
                                        <div class="caption">Dispatched</div>
                                        </li>
                                        <li className='inactive'>
                                        <div class="img"></div>
                                        <div class="caption">Cancelled</div>
                                        </li>
                            </ul> */}

                        </div>

                    </div>
                </div>
                 </>
             }
              

            </div>
          </div>
        </>
      );
}


const mapStateToProps = (state) =>{
    return{
      order: state.order.order,
      loader: state.order.orderloader
    }
  }
  
  const mapDispatchToProps = (dispatch) =>{
    return{
      fetchOrder : (type, id) => dispatch(getOrderById(type, id)),
      updateStatus : (type, id, val) => dispatch(UpdateOrderStatus(type, id, val)),
    }
  }
 
export default connect(mapStateToProps,mapDispatchToProps)(OrderDetailsPage);