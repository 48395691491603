const initState = {
    totalProduct: 0,
    totalOrder: 0,
    totalRevenue: 0,
    incompleteOrders: 0,
    completedOrders: 0,
    restockProducts: [],
    topProducts: [],
    orderTypesChart: []
  };
  
  const overviewReducer = (state = initState, action) => {
    switch (action.type) {
        case "TotalProducts":
            return{
                ...state,
                totalProduct: action.data
            }
        case "TotalOrders":
          return{
            ...state,
            totalOrder: action.data
          }
        case "Overview":
          let insuranceProd = action.data.restockProducts?.insuranceProducts || [];
          let advisoryProd = action.data.restockProducts?.advisoryProducts|| [];
          let livestockProd = action.data.restockProducts?.livestockProducts||[];
          let cropProd = action.data.restockProducts?.inputProducts||[];
          let mechanizationProd = action.data.restockProducts?.mechanizationProducts||[];
          let resp = [...insuranceProd, ...advisoryProd, ...livestockProd, ...cropProd, ...mechanizationProd]
          return{
            ...state,
            totalOrder: action.data.accumulatedOrders.totalOrders,
            incompleteOrders: action.data.accumulatedOrders.incompleteOrders,
            completedOrders: action.data.accumulatedOrders.completedOrders,
            totalRevenue: action.data.totalRevenue,
            restockProducts: resp,
          }
      case "topFive":
          return{
            ...state,
            topProducts: action.data
          }
      case "OrderTypesFilter":
        return{
          ...state,
          orderTypesChart: action.data
        }

      default:
        return state;
    }
  };
  
  export default overviewReducer;
  