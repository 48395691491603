import React from 'react';
import styles from './sidebar.module.css'
import firstImg from '../../assets/images/onboardImg1.png'
import secondImg from '../../assets/images/onboardImg2.png'
import thirdImg from '../../assets/images/onboardImg3.png'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import  './slider.css'

const sidebar = () => {

  const settings = {
    dots: true,
    arrows: false,
    cssEase: "linear",
    infinite: true,
    speed: 500,
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <div className="ft-slick__dots--custom">
      </div>
    )
  };

  return <>
        <div className='col-lg-5 d-none d-lg-block'>
        <div className={styles.login_first_half_div}>

        <Slider {...settings}>

             {/* first div */}
             <div className={styles.login_title_div}>
              <div>
                <h3 className={styles.login_title}>Make Better Business Decisions</h3>
                <p className={styles.login_subtitle}>Gather Store helps you improve your business efficiency by giving real-time updates on the performance of your products.</p>

               
              </div>

                <div className={styles.login_first_half_image}>
                  <img src={firstImg} alt='slides' className='img-fluid' />
                </div>
            </div>

             {/* second div */}
             <div className={styles.login_title_div}>
              <div>
                <h3 className={styles.login_title}>Own Your Digital Inventory</h3>
                <p className={styles.login_subtitle}>You can now record, view, and manage all your products, orders, and sales quickly and easily.</p>

                
              </div>

                <div className={styles.login_first_half_image}>
                  <img src={secondImg} alt='slides' className='img-fluid' />
                </div>
            </div>

              {/* third div */}
              <div className={styles.login_title_div}>
              <div>
                <h3 className={styles.login_title}>Improve Your Sales</h3>
                <p className={styles.login_subtitle}>Grow your business faster with Gather Store. You can now hit and exceed your sales target by managing orders and analyzing performance on Gather Store.</p>

                {/* <div className={styles.login_rect_div}>
                  <div className={styles.login_rect_first}></div>
                  <div className={styles.login_rect_second}></div>
                  <div className={styles.login_rect_third}></div>
                </div> */}
              </div>

                <div className={styles.login_first_half_image}>
                  <img src={thirdImg} alt='slides' className='img-fluid' />
                </div>
            </div>
        </Slider>

           

            
        </div>
      </div>
  </>;
};

export default sidebar;
