import React, {useRef, useState, useEffect, useCallback} from 'react';
import Sidebar from '../../components/Sidebar/sidebar';
import styles from "./products.module.css";
import delete_icon from '../../assets/icons/delete_icon.svg'
import back_arrow from "../../assets/icons/back_arrow.svg";
import arrow_down from "../../assets/icons/arrow_drop_down.svg";
import arrow_up from "../../assets/icons/arrow_drop_up.svg";
import {Link, useParams} from 'react-router-dom'
import Modal from '../../components/Modal/modal'
import {connect} from 'react-redux'
import { getCategories, getProductById, getProductMeasurement, getSubCategories, UpdateProduct } from '../../store/actions/products';
import { Form, Formik } from "formik";
import { addProductValidator } from "../../validationSchema/validator";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import cogoToast from 'cogo-toast';
import Select from 'react-select'
import Switch from "react-switch";
import {useNavigate} from 'react-router-dom'
import {states} from './states'
import PopComp from '../../components/Modal/popups';
import save_icon from "../../assets/icons/save_icon.svg"

const EditProductPage = ({fetchProduct, product,category, isVerified, fetchCategories,fetchSubCategories,updateProd, productLoader, fetchMeasurement, measurement, vendorTypes}) => {

  const values = useParams();

  const navigate = useNavigate()

  const [show, setShow] = useState(false)
  const [showSave, setShowSave] = useState(false)
  const fileInputRef = useRef();
  const [isTypeOpen, setIsTypeOpen] = useState(false)
  const [isCategoryOpen, setIsCategoryOpen] = useState(false)
  const [categoryTitle, setCategory] = useState("")
  const [typeId, setTypeId] = useState(null)
  const [CategoryId, setCategoryId] = useState(null)
  const [typeError, setTypeError] = useState(false)
  const [CategoryError, setCategoryError] = useState(false)
  const [selectedStates, setSelectedStates] = useState([])
  const [defaultStates, setDefaultStates] = useState([])
  const [progress, setProgress] = useState(0);
  const [typeTitle, setType] = useState("")
  const [typeTag, setTypeTag] = useState("")
  const [preview, setPreview] = useState([]);
  const [isSizeOpen, setIsSizeOpen] = useState(false)
  const [sizeName, setSizeName] = useState(null)

  const [defaultImg, setDefaultimg] = useState([])
  const [checked, setChecked] = useState(false);

  const toggleVisible = (val) =>{
    setChecked(val)
  }

  
  useEffect(()=>{
      let {type, id} = values
      fetchProduct(type, id)
      fetchCategories(type)
      fetchMeasurement(type)
      setTypeTag(type)
  }, [values, fetchProduct, fetchCategories,fetchMeasurement])  


  const toggleSizeList = () =>{
    setIsSizeOpen(!isSizeOpen)
  }

  const handleSize = (val) =>{
    setSizeName(val)
    setIsSizeOpen(false)
  }


  const filterCategory = useCallback((tag) => {
    let res = vendorTypes.find((val) => val.tag === (tag)).title
    let id = vendorTypes.find((val) => val.tag === (tag)).id
    setType(res)
    setTypeId(id)
  }, [vendorTypes]);


  const filterActiveStates = useCallback((val)=>{
    var id_filter = val;
    var filteredStates = states.filter(tag => id_filter.includes(tag.id))
    setDefaultStates(filteredStates)
    setSelectedStates(val)    
  },[])


 useEffect(()=>{
    const resArr = [];
    for(let i =0; i< 4-preview.length; i++){
      resArr.push(i)
      setDefaultimg(resArr)
    }
  },[preview])

  useEffect(()=>{
    setPreview(product[0]?.images|| [])
  },[product])



  useEffect(()=>{
   if(product.length){
    filterCategory(values.type)
    filterActiveStates(product[0].active_states)
    setChecked(product[0].is_visible)
    setSizeName(product[0].measurement)    

    switch(values.type){
      case "mechanization":
      setCategory(product[0].hasOwnProperty('mechanization_service') ? product[0].mechanization_service.name : '')
      setCategoryId(product[0].hasOwnProperty('mechanization_service') ? product[0].mechanization_service.id : '')
        break;
      case "insurance":
      setCategory(product[0].hasOwnProperty('product_category') ? product[0].product_category.name : '')
      setCategoryId(product[0].hasOwnProperty('product_category') ? product[0].product_category.id : '')
        break;
      case "advisory":
        setCategory(product[0].hasOwnProperty('product_category') ? product[0].product_category.name : '')
        setCategoryId(product[0].hasOwnProperty('product_category') ? product[0].product_category.id : '')
        break;
      case "crop":
        setCategory(product[0].hasOwnProperty('product_category') ? product[0].product_category.name : '')
        setCategoryId(product[0].hasOwnProperty('product_category') ? product[0].product_category.id : '')
        break;
      case "livestock":
        setCategory(product[0].hasOwnProperty('livestock_input_category') ? product[0].livestock_input_category.name : '')
        setCategoryId(product[0].hasOwnProperty('livestock_input_category') ? product[0].livestock_input_category.id : '')
        break;
      default:
        break;
    }

   }
}, [product, filterCategory, values, filterActiveStates]) 


  const toggleTypeList = () =>{
    setIsTypeOpen(!isTypeOpen)
  }

  const handleType = (val) =>{
    const {title,tag, id} = val
    setType(title)
    setTypeId(id)
    setTypeTag(tag)
    setTypeError(false)
    setIsTypeOpen(false)
    setCategory("")
    setSizeName(null)
    setCategoryId(null)

    
    // check for the category selected and determine which size to return

    // make api call to fetch the categories, measurements based on the type selected
      fetchCategories(tag)
      fetchMeasurement(tag)

  }

  const toggleCatList = () =>{
    setIsCategoryOpen(!isCategoryOpen)
  }

  const handleCategory = (val) =>{
    const {name, id} = val
    setCategory(name)
    setCategoryError(false)
    setCategoryId(parseInt(id))
    setIsCategoryOpen(false)

     // make api call to fetch subcategories
     fetchSubCategories(typeTag, id)
  }



  const DropDown = props => {
    const options = props.multi
    ? [{ name: "Select All", id: "all" }, ...props.options]
    : props.options;
    return (
      <div>
        <Select
          classNamePrefix="select_control"
          closeMenuOnSelect={false}
          className="select_style"
          defaultValue={props.value}
          options={options}
          isMulti={props.multi}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          onChange={selected => {
            props.multi &&
            selected.length &&
            selected.find(option => option.id === "all")
              ? props.handleDropdown(options.slice(1))
              : !props.multi
                ? props.handleDropdown((selected && selected.value) || null)
                : props.handleDropdown(selected);
          }}
          
        />
      </div>
    );
  };
  

  
  
  const handleStates = (val) =>{
    console.log(val)
    setDefaultStates(val)
    let stateArray = [];
    val.map(o => 
       stateArray.push(o.id)
   );
   setSelectedStates(stateArray)
  }

  
  const fileobj= [];


  const fileInputClicked = () => {
      fileInputRef.current.click();
  }

  const changedHandler = () => {
    let files = fileInputRef.current.files;
    fileobj.push(files);
    // let reader;

    //upload image to cloudinary and get a url
    uploadFile(files[0]) 
}

  const removeImage = (index) =>{
    console.log(index)
    const result = [...preview];
  
    // removing the element using splice
    result.splice(index, 1);
  
    // updating the list
    setPreview(result);
  
    let res  = [...defaultImg, index]
    setDefaultimg(res)
  
  }

  const dragOver = (e) => {
    e.preventDefault();
  }

  const dragEnter = (e) => {
      e.preventDefault();
  }

  const dragLeave = (e) => {
      e.preventDefault();
  }

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];
    console.log(files)
    
  }

  const showModal = () =>{
    setShow(!show)
  }

  const showSaveModal = () =>{
    setShowSave(!showSave)
  }

  const handleSaveClose = () =>{
    setShowSave(false)
  }

 
   // upload to cloudinary api
   const uploadFile = (file) =>{
    const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener("progress", (e) => {
      setProgress(Math.round((e.loaded * 100.0) / e.total));
    });

    xhr.onreadystatechange = (e) => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);

        // setImage(response.secure_url);
        preview.push(response.secure_url);   // update the array instead of replacing the entire value of preview

        setPreview([...new Set(preview)]); // spread into a new array to trigger rerender
        setProgress(0)

        let res = [...defaultImg]
        res.shift()
        setDefaultimg(res)
      }
    };

    fd.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UNSIGNED_UPLOAD_PRESET
    );
    fd.append("tags", "browser_upload");
    fd.append("file", file);
    xhr.send(fd);

  }

  const [credentials, setCredentials] = useState({})
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)

  const handleSubmit = async (val) =>{
      if(typeId === null){
        setTypeError(true)
      }
      else if(CategoryId === null){
        setCategoryError(true)
      }
      else if(sizeName === null){
        cogoToast.warn('Please select a measurement for this product!')
      }
      else if(selectedStates.length < 1){
        cogoToast.warn("Please add at least an active state for this product!") 
      }
      // else if(preview.length < 1){
      //   cogoToast.warn("Please add at least an image!")
      // }
      else{
        setShowSave(true)
        const creds = {
          ...val,
          typeId,
          CategoryId,
          selectedStates,
          preview,
          checked,
          sizeName
        }
        setCredentials(creds)
      } 
  } 

  const handleSave = async () =>{
    setShowSave(false)
    setIsSaveDisabled(true)
    await updateProd(values.type,values.id, credentials)

    setTimeout(()=>{
      setShow(true)
      setIsSaveDisabled(false)
    },2000)
  }


  const handleContinue = () =>{
    navigate('/products')
  }



    return ( 
        <>
        <Sidebar>
            </Sidebar>
            <Modal show={show} showModal={showModal}>
                 <div className='text-center'>
                      <button type="button" onClick={()=> handleContinue()} className={`btn continue_btn`}>Continue</button>
                  </div>
            </Modal>


        <PopComp show={showSave} showModal={showSaveModal}>
           <div style={{display: 'flex', justifyContent: 'center'}}>
              <div className='save_icon_div'>
                  <img src={save_icon} alt="save"/>
              </div>
            </div>

          <div className='text-center'>
              <h6 className='pop_title'>Save Changes</h6>
              <p className='pop_subtitle'>Are you sure you want to save your changes</p>
          </div>

          <div className='logout-cta'>
            <button className='btn cancel-btn'  onClick={() => handleSaveClose()}>No</button>
            <button className='btn btn_yes' onClick={handleSave}>Yes</button>
          </div>
        </PopComp>

            <div className="usermain">
        <div className="contain">

          <div className="back_arrow_div">
                    <Link to="/products" className="back_arrow_icon">
                          <img src={back_arrow} alt="back" />
                    </Link>
                    <div>
                      <span>Back</span>
                    </div>
                </div>
          <div className='mt-4'>
                  <p className="titlehead">Edit Product</p>
          </div>

      {
        productLoader ? 
        <>
        <div className={`row ${styles.skeleton_loader_div}`}>
            <div className='col-lg-6'>
                <div >
                <Skeleton count={3} />
               </div>
               <div style={{marginTop: '20px'}}>
                <Skeleton count={3} />
               </div>
               <div style={{marginTop: '30px'}}>
                <Skeleton count={3} />
               </div>
               <div style={{marginTop: '30px'}}>
                <Skeleton count={3} />
               </div>
            </div>
            <div className='col-lg-6'>
              <div>
                <Skeleton count={3} />
               </div>
               <div className='row mt-4'>
                 <div className='col-lg-6'>
                 <Skeleton
                      circle
                      height="200px"
                      style={{borderRadius: "50%", width: "200px"}}
                     
                  />
                 </div>
                 <div className='col-lg-6'>
                 <Skeleton
                      circle
                      height="200px"
                      style={{borderRadius: "50%", width: "200px"}}
                     
                  />
                   </div>
               </div>

                 
            </div>
        </div>
        
        </>
        :
        <Formik
             enableReinitialize={true} 
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={addProductValidator}
              initialValues={{ name: product && product.length ? product[0].name : "", 
              description: product && product.length ? product[0].description : "",
              costPrice: product && product.length ? product[0].cost_price : "", 
              discountPrice: product && product.length ? product[0].discount_price : "",
              units: product && product.length ? product[0].units : "",
              displayPrice: product && product.length ? product[0].display_price : "",
              productSize: product && product.length ? (values.type === 'mechanization' ? product[0].land_size : product[0].product_size) : "",
              minHec: product.length && product[0].hasOwnProperty("minimum_hectares") ? product[0].minimum_hectares  : "1"}}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
          <Form onSubmit={handleSubmit}>
          <div className={styles.add_product_title_div}>
              <div>
                  <h6 className={styles.add_product_title}>Product Information</h6>
                  <p className={styles.add_product_subtitle}>Please fill the fields with the correct product information</p>
              </div>
              <div>
                <button 
                type='submit'
                disabled={isSaveDisabled}
                 className={`${styles.add_product_btn} btn`}>Save</button>
              </div>
          </div>

          {/* add products section */}
          <div className='row mt-4'>
            <div className='col-lg-6'>

              <div className={`mb-3 ${styles.input_container}`}>
                <label htmlFor="name">Product Name<sup className={styles.label_star}>*</sup></label>
                <input
                  className={`form-control ${styles.input_style}`}
                  type="text"
                  placeholder="Product name"
                  id="name"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                 <small style={{ color: "#dc3545" }}>
                    {touched.name && errors.name }
                  </small>
              </div>

              <div className={styles.input_container}>
                <label htmlFor="description">Product Description<sup className={styles.label_star}>*</sup></label>
                <textarea
                  className={`form-control ${styles.textarea_style}`}
                  type="text"
                  rows="5"
                  placeholder="The product description should give the customer useful information about the product to ensure a purchase."
                  id="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                />
                 <small style={{ color: "#dc3545" }}>
                    {touched.description && errors.description }
                  </small>
              </div>

              <div className='row mt-3'>
                <div className='col-lg-6'>
                    <div className={`mb-3 ${styles.input_container}`}>
                    <label htmlFor="name">Product Type<sup className={styles.label_star}>*</sup></label>
                    <div className={styles.gather_select_wrapper}>
                      <div className={styles.gather_select_div}  onClick={() => toggleTypeList()}>  
                         <div className={styles.gather_select_input}>
                           <span
                           className={
                            typeTitle !== "" ? `${styles.gather_select_input_active}` : ''
                           }
                           >{typeTitle === "" ? "Select Type" : typeTitle}</span>
                         </div>
                         {isTypeOpen ? (
                              <img src={arrow_up} alt="arrow" />
                            ) : (
                              <img src={arrow_down} alt="arrow" />
                            )}
                        
                      </div>
                      <ul 
                       className={
                        isTypeOpen
                          ? `${styles.gather_dropdown_section} ${styles.gather_dropdown_section_active}`
                          : styles.gather_dropdown_section
                      }
                          >
                          {vendorTypes.map((val) => (
                            <li
                              key={val.id}
                              onClick={() => handleType(val)}
                            >
                              <p className={styles.gather_dropdown_list}>{val.title}</p>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <small style={{ color: "#dc3545" }}>
                       {typeError ? "Type is required" : ""} 
                    </small>
                  </div>
                </div>
              
                 <div className='col-lg-6'>
                 <div className={`mb-3 ${styles.input_container}`}>
                 <label htmlFor="name">{typeTag !== "mechanization" ? "Product Category" : "Mechanization Service"}<sup className={styles.label_star}>*</sup></label>
                 <div className={styles.gather_select_wrapper}>
                   <div className={styles.gather_select_div}  onClick={() => toggleCatList()}>  
                      <div className={styles.gather_select_input}>
                        <span
                        className={
                         categoryTitle !== "" ? `${styles.gather_select_input_active}` : ''
                        }
                        >{categoryTitle === "" ? "Select Category" : categoryTitle}</span>
                      </div>
                      {isCategoryOpen ? (
                           <img src={arrow_up} alt="arrow" />
                         ) : (
                           <img src={arrow_down} alt="arrow" />
                         )}
                     
                   </div>
                   <ul 
                    className={
                     isCategoryOpen
                       ? `${styles.gather_dropdown_section} ${styles.gather_dropdown_section_active}`
                       : styles.gather_dropdown_section
                   }
                       >
                       {category.map((val) => (
                         <li
                           key={val.id}
                           onClick={() => handleCategory(val)}
                         >
                           <p className={styles.gather_dropdown_list}>{val.name}</p>
                         </li>
                       ))}
                   </ul>
                 </div>
                 <small style={{ color: "#dc3545" }}>
                    {CategoryError ? " Category is required" : ""}
                 </small>
               </div>
             </div>
              

              </div>

              <div className='row mt-3'>
                <div className='col-lg-6'>
                    <div className={`mb-3 ${styles.input_container}`}>
                    <label htmlFor="costPrice">Cost Price<sup className={styles.label_star}>*</sup></label>
                    <input
                      className={`form-control ${styles.input_style}`}
                      type="text"
                      placeholder="₦000,000"
                      id="costPrice"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.costPrice}
                    />
                      <small style={{ color: "#dc3545" }}>
                      {touched.costPrice && errors.costPrice }
                    </small>
                  </div>
                </div>
                <div className='col-lg-6'>
                    <div className={`mb-3 ${styles.input_container}`}>
                    <label htmlFor="discountPrice">Discount Price(optional)</label>
                    <input
                      className={`form-control ${styles.input_style}`}
                      type="text"
                      placeholder="₦000,000"
                      id="discountPrice"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.discountPrice}
                    />
                     <small style={{ color: "#dc3545" }}>
                      {touched.discountPrice && errors.discountPrice }
                    </small>
                  </div>
                </div>

              </div>

              <div className='row mt-3'>
                <div className='col-lg-6'>
                    <div className={`mb-3 ${styles.input_container}`}>
                    <label htmlFor="productSize">{typeTag !== "mechanization" ? "Product Size" : "Land Size"}<sup className={styles.label_star}>*</sup></label>
                    <div style={{position: "relative"}}>
                      <input
                        className={`form-control ${styles.input_style}`}
                        type="text"
                        placeholder="0000"
                        id="productSize"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.productSize}
                      />
                      
                      <div className={styles.size_dropdown_div}>
                            <div>
                              <span className={styles.size_dropdown_text}>
                                 {sizeName === null ? "Select Unit" : sizeName}
                                </span>
                            </div>
                            <div
                            onClick={()=> toggleSizeList()}
                             className={styles.size_dropdown_select}>
                               {
                                 isSizeOpen ? 
                                 <img src={arrow_up} alt="arrow" />
                                 :
                                 <img src={arrow_down} alt="arrow" />
                               }
                             
                            </div>
                      </div>

                      <ul 
                      className={
                        isSizeOpen ?
                        `${styles.size_dropdown_section} ${styles.size_dropdown_section_active}`
                        :
                        `${styles.size_dropdown_section}`
                      }
                        >
                        {measurement.map((val, index) => (
                            <li
                              key={index}
                              onClick={() => handleSize(val)}
                            >
                              <p className={styles.size_dropdown_list}>{val}</p>
                            </li>
                          ))}
                      </ul>
                    </div>
                     <small style={{ color: "#dc3545" }}>
                      {touched.productSize && errors.productSize }
                    </small>
                  </div>
                </div>

                {
                  typeTag !== "mechanization" &&
                <div className='col-lg-6'>
                    <div className={`mb-3 ${styles.input_container}`}>
                    <label htmlFor="units">Units in stock<sup className={styles.label_star}>*</sup></label>
                    <input
                      className={`form-control ${styles.input_style}`}
                      type="text"
                      placeholder="0000"
                      id="units"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.units}
                    />
                      <small style={{ color: "#dc3545" }}>
                      {touched.units && errors.units }
                    </small>
                  </div>
                </div>
                }

            {
                 typeTag === "mechanization" &&
                 <div className='col-lg-6'>
                 <div className={`mb-3 ${styles.input_container}`}>
                 <label htmlFor="minHec">Minimum Hectares<sup className={styles.label_star}>*</sup></label>
                 <input
                   className={`${styles.input_style}`}
                   type="text"
                   placeholder="1"
                   id="minHec"
                   onBlur={handleBlur}
                   onChange={handleChange}
                   value={values.minHec}
                 />
                  <small style={{ color: "#dc3545" }}>
                   {touched.minHec && errors.minHec }
                 </small>
               </div>
             </div>
               }

              </div>

              <div className='row mt-3'>
                <div className='col-lg-12'>
                  {/* <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="name">Active State(s)</label>
                      <Select 
                      isMulti
                      onChange={handleStates}
                      classNamePrefix="select_control"
                      className="select_style"
                      defaultValue={defaultStates}
                      options={states}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                       />
                  </div> */}


                  <div className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="states">Active State(s)</label>
                      <DropDown
                        value={defaultStates}
                        options={states}
                        handleDropdown={handleStates}
                        multi={true}
                      />                    
                    </div>

                </div>
              </div>

            </div>
            <div className='col-lg-6 mt-md-0 mt-3'>

              <div className={`mb-3 ${styles.input_container}`}>
                <label htmlFor="name">Product Images<sup className={styles.label_star}>*</sup></label>
                <p>
                You need to add at least 2 images, pay attention to the quality and clarity of the picture you add. Images need to be at least 200 x 200 pixel with a maximum of 3000 x 3000 pixel.
                </p>
              </div>


              
            
              <div className={`row mt-4 ${styles.productImageSection}`}>          
              {
                    (preview || []).map((url, index) => (
                        <div className='col-lg-6 mb-4' key={url}>    
                          {/* image preview when selected */}
                          <div className={styles.image_preview}>
                                <img src={url} className='img-fluid' alt="preview" />
                                <div className={styles.image_preview_remove}>
                                  <img src={delete_icon}
                                    onClick={() => removeImage(index)}
                                    alt="delete_icon" className='img-fluid'/>
                                </div>
                              </div>
                          </div>
                          ))
                    }

                    {
                      defaultImg.length >= 1 && 
                        <div className='col-lg-6 mb-4'>
                          <div 
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={fileDrop}
                            onClick={() => fileInputClicked()}
                          className={styles.drop_container}>
                             {progress === 0 ? (
                              <p>
                              Drop your images here or <span>Choose File</span>
                              </p>
                             )
                             : (
                              <span className="text-gray-700">{progress}%</span>
                            )}
                              <input
                                ref={fileInputRef}
                                type="file"
                                accept="image/*"
                                onChange={() => changedHandler()}
                            />
                          </div>
                      </div>
                    
                    }
               
              </div>

              
              <div className={`mt-3 ${styles.input_container}`}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                 <label htmlFor="name" className='mr-3'>Product Visibility</label>
                 <label>
                  <Switch 
                   uncheckedIcon={false}
                   checkedIcon={false}
                   onColor="#2B8C34"
                   height={25}
                  width={50}
                  disabled={!isVerified}
                  onChange={toggleVisible}
                   checked={checked} />
                </label>
                </div>
         
                <p>
                  {
                    !isVerified ? 
                    "Verify your account to make this product visible":
                    "Turn on to let your customer see this product"
                  }
                </p>
              </div>

              
             
            
            </div>

          </div>
          </Form>
              )}
        </Formik>
        }

        </div>
      </div>
        </>
     );
}

const mapStateToProps = (state) =>{
  return{
    category: state.product.category,
    product: state.product.product,
    states: state.product.states,
    subcategory: state.product.subcategory,
    productLoader: state.product.productloader,
    measurement: state.product.measurement,
    isVerified: state.auth.isVerified,
    vendorTypes: state.auth.vendorTypes,
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
    fetchProduct : (category, id) => dispatch(getProductById(category, id)),
    fetchCategories: (category) => dispatch(getCategories(category)),
    fetchSubCategories: (category, id) => dispatch(getSubCategories(category, id)),
    updateProd: (type, id, creds) => dispatch(UpdateProduct(type, id, creds)),
    fetchMeasurement: (category) => dispatch(getProductMeasurement(category)),
  }
}


 
export default connect(mapStateToProps, mapDispatchToProps)(EditProductPage);